import {
  LOCALES,
  MESSAGE_KEYS,
  VALIDATION_RULES,
} from "assets/staticData/enums";

const {
  MENU_APPOINTMENTS,
  MENU_ACCOUNT,
  MENU_LOGOUT,
  MENU_USERS,
  MENU_CUSTOMERS,
  MENU_ADDRESSES,
  MENU_BILLS,
  MENU_COMPANY_MANAGEMENT,
  MENU_BILL_MANAGEMENT,
  MENU_APPOINTMENT_MANAGEMENT,
  MENU_PAYMENTS,
  MENU_VACATION,
  MENU_SHIFTS,
  MENU_ON_CALL,
  MENU_SETTINGS,
  MENU_DASHBOARD,
  MENU_PRODUCTS,
  MENU_VEHICLES,
  MENU_EMPTY,
  MENU_REGULARS,
  MENU_HOLIDAYS,
  MENU_DRIVES,
  MENU_DRIVER_STATUS,
  MENU_APPOINTMENTS_SEARCH,
  MENU_SHOUTBOX,
  MENU_MONTH_REPORT,

  LOGIN_BUTTON_LABEL,
  LOGIN_PASSWORD_LABEL,
  LOGIN_TITLE_LABEL,
  LOGIN_USERNAME_LABEL,

  LOGIN_WARNING_FAILED,

  DASHBOARD_SALES_TITLE,
  DASHBOARD_BIRTHDAY_TITLE,
  DASHBOARD_OVERVIEW_TABLE_YEAR_HEADER,
  DASHBOARD_OVERVIEW_TABLE_MONTH_HEADER,
  DASHBOARD_SALES_FILTER_TITLE,
  DASHBOARD_SALES_FILTER_FROM_LABEL,
  DASHBOARD_SALES_FILTER_TO_LABEL,
  DASHBOARD_SALES_FILTER_INCLUDE_LABEL,

  EMPTY_PAGE_TITLE,
  EMPTY_PAGE_SUMMARY,

  ACCOUNT_EMAIL_LABEL,
  ACCOUNT_ERROR_EMAIL_INVALID,
  ACCOUNT_ERROR_EMAIL_REQUIRED,
  ACCOUNT_ERROR_NAME_LENGTH,
  ACCOUNT_ERROR_NAME_REQUIRED,
  ACCOUNT_ERROR_PASSWORD_LENGTH,
  ACCOUNT_ERROR_PASSWORD_MATCH,
  ACCOUNT_ERROR_COULD_NOT_LOAD,
  ACCOUNT_LANGUAGE_LABEL,
  ACCOUNT_NAME_LABEL,
  ACCOUNT_PASSWORD_LABEL,
  ACCOUNT_PASSWORD_REPEAT_LABEL,
  ACCOUNT_ROLES_LABEL,
  ACCOUNT_TITLE_LABEL,
  ACCOUNT_SAVE_BUTTON_LABEL,
  ACCOUNT_CREATE_BUTTON_LABEL,
  ACCOUNT_SAVE_SUCCESS_MESSAGE,

  ACCOUNT_FIRST_LOGIN_WARNING,
  ACCOUNT_SAVE_PASSWORD_LABEL,
  ACCOUNT_FIRST_LOGIN_HEADER,

  ERROR_MISSING_VALUES,
  ERROR_DUPLICATE,
  ERROR_DATA_FETCH,
  ERROR_NO_DATA,
  ERROR_RENDER,
  ERROR_SERVER_NOT_AVAILABLE,
  ERROR,
  ERROR_DATA_SAVE,
  ERROR_TOKEN_EXPIRED,

  WARNING_CONFIRM_REMOVE,
  WARNING_UNSAVED_CHANGES,
  WARNING_VALIDATION_FAILED,
  WARNING_OFFLINE_MODE,

  ROLE_OFFLINE,
  ROLE_ADMIN,
  ROLE_INVOICE,
  ROLE_PLANNING,
  ROLE_USER,
  ROLE_DRIVER,
  ROLE_FIDU,
  ROLE_AUTHOR,

  GENDER_NONE,
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_UNDEFINED,
  RESET_VALUES,

  TITLES_CHILD,
  TITLES_DOCTOR,
  TITLES_GENTLEMEN,
  TITLES_MASTER,
  TITLES_MISS,
  TITLES_MISSES,
  TITLES_MISTER,
  TITLES_MYLORD,
  TITLES_NONE,
  TITLES_NO,
  TITLES_PROFESSOR,
  TITLES_SENIOR,

  USERS_TITLE_LABEL,
  USERS_SELECT_USER_LABEL,
  USERS_FIRST_NAME_LABEL,
  USERS_LAST_NAME_LABEL,
  USERS_NICK_NAME_LABEL,
  USERS_HOME_PHONE_LABEL,
  USERS_WORK_PHONE_LABEL,
  USERS_MOBILE_PHONE_LABEL,
  USERS_HIRE_DATE_LABEL,
  USERS_ALLERGIES_LABEL,
  USERS_DISEASES_LABEL,
  USERS_BLOODTYPE_LABEL,
  USERS_TIMERECORDING_LABEL,
  USERS_WEEKHOURS_LABEL,

  USERS_VACATION_BUDGET_START_LABEL,
  USERS_VACATION_BUDGET_YEAR_LABEL,
  USERS_VACATION_BUDGET_LABEL,
  USERS_VACATION_BUDGET_COMMENT_LABEL,
  USERS_VACATION_BUDGET_SAVE_SUCCESS,
  USERS_VACATION_OVER_BUDGET_WARNING,
  USERS_VACATION_OVER_BUDGET_ADMIN_WARNING,
  USERS_BUDGET_DELETE_TITLE,
  USERS_BUDGET_DELETE_MESSAGE,
  USERS_BUDGET_DELETE_FAIL,
  USERS_BUDGET_DELETE_SUCCESS,

  USERS_VACATION_BUDGET_UPDATE_BUTTON_LABEL,
  USERS_VACATION_BUDGET_CREATE_BUTTON_LABEL,

  USERS_ADD_EMERGENCY_PERSON_LABEL,
  USERS_DELETE_EMERGENCY_PERSON_MESSAGE,
  USERS_DELETE_EMERGENCY_PERSON_LABEL,
  USERS_EDIT_EMERGENCY_PERSON_LABEL,
  USERS_EMERGENCY_PERSON_ADD_SUCCESS,
  USERS_EMERGENCY_PERSON_ADD_FAIL,
  USERS_EMERGENCY_PERSON_EDIT_FAIL,
  USERS_EMERGENCY_PERSON_EDIT_SUCCESS,
  USERS_EMERGENCY_PERSON_DELETE_FAIL,
  USERS_EMERGENCY_PERSON_DELETE_SUCCESS,

  USERS_INFO_REPORT_BUTTON_LABEL,

  DIALOG_WARNING_TITLE,

  DIALOG_CONTINUE_OFFLINE_BUTTON_LABEL,
  DIALOG_SAVE_BUTTON_LABEL,
  DIALOG_CANCEL_BUTTON_LABEL,
  DIALOG_CANCEL_SHORT_BUTTON_LABEL,
  DIALOG_CONFIRM_BUTTON_LABEL,
  DIALOG_DENY_BUTTON_LABEL,
  DIALOG_SEARCH_BUTTON_LABEL,
  DIALOG_CLEAR_FILTER_LABEL,
  DIALOG_CONTINUE_BUTTON_LABEL,
  DIALOG_BACK_BUTTON_LABEL,
  DIALOG_DOWNLOAD_BUTTON_LABEL,
  DIALOG_CLOSE_BUTTON_LABEL,

  VIEWMODE_LIGHT,
  VIEWMODE_DARK,

  CUSTOMERS_FILTER_TITLE_LABEL,
  CUSTOMERS_FILTER_NAME,
  CUSTOMERS_FILTER_ADDRESS,
  CUSTOMERS_FILTER_PHONE,
  CUSTOMERS_FILTER_EMAIL,
  CUSTOMERS_FILTER_INSURANCE_NUMBER,
  CUSTOMERS_FILTER_ONLY_ACTIVE,

  CUSTOMER_FILTER_FUZZY_TOOLTIP,

  CUSTOMERS_FIRST_NAME_LABEL,
  CUSTOMERS_MIDDLE_NAME_LABEL,
  CUSTOMERS_LAST_NAME_LABEL,
  CUSTOMERS_EMAIL_LABEL,
  CUSTOMER_SELECTED_CUSTOMER_LABEL,
  CUSTOMER_BILL_TITLE_LABEL,
  CUSTOMER_DATA_TITLE_LABEL,
  CUSTOMER_PAYMENT_TITLE_LABEL,
  CUSTOMER_PAYMENT_TITLE_SELECTION_LABEL,
  CUSTOMERS_PRINT_BUTTON_LABEL,
  CUSTOMERS_CREATE_BUTTON_LABEL,
  CUSTOMERS_SAVE_BUTTON_LABEL,
  CUSTOMERS_COMMENT_LABEL,
  CUSTOMERS_FAX_LABEL,
  CUSTOMERS_MATRICULATION_LABEL,
  CUSTOMERS_MOBILE_LABEL,
  CUSTOMERS_PHONE_LABEL,
  CUSTOMERS_ERROR_MATRICULATION_LENGTH,
  CUSTOMERS_ERROR_MATRICULATION_REQUIRED,
  CUSTOMERS_VALIDATION_ERROR_INSURANCE_NUMBER,
  CUSTOMERS_SAVE_SUCCESS_MESSAGE,
  CUSTOMERS_GENDER_LABEL,
  CUSTOMERS_TITLE_LABEL,
  CUSTOMERS_BIRTH_DATE_LABEL,

  CUSTOMERS_ADDRESS_TITLE_LABEL,
  CUSTOMERS_ADDRESS_TYPE_LABEL,
  CUSTOMERS_ADD_ADDRESS_TITLE_LABEL,
  CUSTOMERS_ADD_PAYMENT_TITLE_LABEL,
  CUSTOMERS_ADDRESS_CITY_LABEL,
  CUSTOMERS_ADDRESS_LINE_1_LABEL,
  CUSTOMERS_ADDRESS_LINE_2_LABEL,
  CUSTOMERS_ADDRESS_ZIP_LABEL,
  CUSTOMERS_ADDRESS_RETRO_LABEL,
  CUSTOMERS_ADDRESS_CREATE_BUTTON_LABEL,

  CUSTOMERS_ADDRESS_DUPLICATE_WARNING,

  CUSTOMERS_DEAD_LABEL,

  ADDRESSES_FILTER_TITLE_LABEL,
  ADDRESSES_FILTER_CUSTOMER_LABEL,
  ADDRESSES_FILTER_ADDRESS_LABEL,
  ADDRESSES_FILTER_HIN_LABEL,
  ADDRESSES_FILTER_SEARCHKEY_LABEL,

  ADDRESSES_TITLE_LABEL,
  ADDRESSES_SELECT_ADDRESS_LABEL,
  ADDRESSES_SAVE_BUTTON_LABEL,

  ADDRESSES_HOSPITAL_LABEL,
  ADDRESSES_NURSING_LABEL,
  ADDRESSES_AMBULANCE_LABEL,

  ADDRESSES_PHONE_1_LABEL,
  ADDRESSES_PHONE_2_LABEL,

  ADDRESSES_ERROR_CITY_REQUIRED,
  ADDRESSES_ERROR_LINE1_REQUIRED,
  ADDRESSES_ERROR_ZIP_REQUIRED,

  APPOINTMENTS_DELETE_BUTTON_LABEL,
  APPOINTMENTS_DELETE_SUCCESS,
  APPOINTMENTS_DELETE_FAIL,
  APPOINTMENTS_DELETE_NOTICE,

  APPOINTMENTS_DRIVER_MESSAGE,
  APPOINTMENTS_DRIVER_MESSAGE_SENT,
  APPOINTMENTS_DRIVER_MESSAGE_RECEIVED,
  APPOINTMENTS_DRIVER_MESSAGE_READ,

  APPOINTMENTS_DRIVER_REPORT_SAVE,
  APPOINTMENTS_DRIVER_REPORT_CANCEL,
  APPOINTMENTS_DRIVER_REPORT_NEW,
  APPOINTMENTS_DRIVER_REPORT_EDIT,
  APPOINTMENTS_DRIVER_REPORT_EDIT_BUTTON,

  APPOINTMENTS_DRIVER_SAVE_ERROR_TITLE_LABEL,
  APPOINTMENTS_DRIVER_SAVE_ERROR_DETAILS,

  APPOINTMENTS_TITLE_LABEL,
  APPOINTMENTS_CUSTOMER_ADDRESS_WARNING,
  APPOINTMENTS_NO_CUSTOMER_ASSIGNED_NOTICE,
  APPOINTMENTS_NO_CUSTOMER_NOTICE,
  APPOINTMENTS_NO_FROM_ADDRESS_NOTICE,
  APPOINTMENTS_NO_TO_ADDRESS_NOTICE,
  APPOINTMENTS_UPDATE_SUCCESS_MESSAGE,
  APPOINTMENTS_APPOINTMENT_LABEL,
  APPOINTMENTS_SECOND_APPOINTMENT_LABEL,
  APPOINTMENTS_CLIENT_LABEL,
  APPOINTMENTS_END_ADDRESS_LABEL,
  APPOINTMENTS_FIRST_DRIVER_LABEL,
  APPOINTMENTS_SECOND_DRIVER_LABEL,
  APPOINTMENTS_START_ADDRESS_LABEL,
  APPOINTMENTS_CREATE_DIALOG_TITLE_LABEL,
  APPOINTMENTS_EDIT_DIALOG_TITLE_LABEL,
  APPOINTMENTS_STATUS_LABEL,
  APPOINTMENTS_VEHICLE_LABEL,
  APPOINTMENTS_LOCATION_LABEL,
  APPOINTMENTS_NEW_EVENT_LABEL,
  APPOINTMENTS_VEHICLE_TYPE_LABEL,
  APPOINTMENTS_LAST_PAYMENT_DATE_LABEL,
  APPOINTMENTS_LAST_BILL_NUMBER_LABEL,
  APPOINTMENTS_START_DATE_LABEL,
  APPOINTMENTS_CREATE_CUSTOMER_LABEL,
  APPOINTMENTS_CREATE_BUTTON_LABEL,
  APPOINTMENTS_SAVE_BUTTON_LABEL,

  APPOINTMENTS_DESINFECTION_LABEL,
  APPOINTMENTS_CORONA_LABEL,
  APPOINTMENTS_PERFUSOR_LABEL,

  APPOINTMENTS_DEPARTURE_TIME_LABEL,
  APPOINTMENTS_DESTINATION_TIME_LABEL,
  APPOINTMENTS_RETURN_TIME_LABEL,

  APPOINTMENTS_PASSENGER_STEP_LABEL,
  APPOINTMENTS_DRIVE_STEP_LABEL,
  APPOINTMENTS_CONFIRM_STEP_LABEL,
  APPOINTMENTS_MULTI_STEP_LABEL,

  APPOINTMENTS_DRIVE_APPOINTMENT,
  APPOINTMENTS_DEPARTURE_TIME,
  APPOINTMENTS_RETURN_TIME,
  APPOINTMENTS_KILOMETERS,
  APPOINTMENTS_KILOMETERS_BACK,
  APPOINTMENTS_OUTBOUND_DRIVER_LABEL,
  APPOINTMENTS_OUTBOUND_SECOND_DRIVER_LABEL,
  APPOINTMENTS_RETURN_DRIVER_LABEL,
  APPOINTMENTS_RETURN_SECOND_DRIVER_LABEL,
  APPOINTMENTS_OUTBOUND_CAR_LABEL,
  APPOINTMENTS_RETURN_CAR_LABEL,

  APPOINTMENTS_DEPART_ROOM_LABEL,
  APPOINTMENTS_DEPART_DEPARTMENT_LABEL,
  APPOINTMENTS_DEPART_ADDRESS_COMMENT_LABEL,
  APPOINTMENTS_DEPART_ADDRESS_1_LABEL,
  APPOINTMENTS_DEPART_ADDRESS_2_LABEL,
  APPOINTMENTS_DEPART_ZIP_LABEL,
  APPOINTMENTS_FROM_LOCATION,
  APPOINTMENTS_CUSTOMER_GSM,
  APPOINTMENTS_CUSTOMER_FULL_NAME,

  APPOINTMENTS_ARRIVAL_ROOM_LABEL,
  APPOINTMENTS_ARRIVAL_DEPARTMENT_LABEL,
  APPOINTMENTS_ARRIVAL_ADDRESS_COMMENT_LABEL,
  APPOINTMENTS_ARRIVAL_ADDRESS_1_LABEL,
  APPOINTMENTS_ARRIVAL_ADDRESS_2_LABEL,
  APPOINTMENTS_ARRIVAL_ZIP_LABEL,
  APPOINTMENTS_OUTWARD_PAPER_LABEL,
  APPOINTMENTS_OUTWARD_DEPARTURE_LABEL,
  APPOINTMENTS_OUTWARD_ARRIVAL_LABEL,
  APPOINTMENTS_OUTWARD_DISCHARGE_LABEL,

  APPOINTMENTS_RETURN_PAPER_LABEL,
  APPOINTMENTS_RETURN_DEPARTURE_LABEL,
  APPOINTMENTS_RETURN_ARRIVAL_LABEL,
  APPOINTMENTS_RETURN_DISCHARGE_LABEL,

  APPOINTMENTS_WEIGHT_NUMBER,
  APPOINTMENTS_STAIRS_COUNT,
  APPOINTMENTS_VACCINATED,
  APPOINTMENTS_TRANSPORT_TYPE_INVOICE,
  APPOINTMENTS_RETURN_PHONE,

  APPOINTMENTS_CUSTOMER_HEADER,
  APPOINTMENTS_DEPARTURE_HEADER,
  APPOINTMENTS_DESTINATION_HEADER,
  APPOINTMENTS_RETURN_HEADER,
  APPOINTMENTS_NEXT_HEADER,
  APPOINTMENTS_STATE_LABEL,
  APPOINTMENTS_VACCINATED_LABEL,
  APPOINTMENTS_BUS_LABEL,
  APPOINTMENTS_PATIENT_STAY_LABEL,

  APPOINTMENTS_CLEAR_ADDRESS,
  APPOINTMENTS_USE_CUSTOMER_ADDRESS,
  APPOINTMENTS_ROOM_LABEL,
  APPOINTMENTS_DEPARTMENT_LABEL,
  APPOINTMENTS_FREE_TEXT_LABEL,
  APPOINTMENTS_KEY_WORD_LABEL,
  APPOINTMENTS_AMOUNT_LABEL,

  APPOINTMENTS_MULTIPLE_DRIVES_LABEL,
  APPOINTMENTS_OXYGEN_LABEL,
  APPOINTMENTS_DSA_LABEL,
  APPOINTMENTS_POSITION_LYING_LABEL,
  APPOINTMENTS_POSITION_SITTING_LABEL,
  APPOINTMENTS_RETURN_LABEL,
  APPOINTMENTS_ROUND_TRIP_LABEL,
  APPOINTMENTS_ISOLATION_LABEL,
  APPOINTMENTS_COMMENT_LABEL,
  APPOINTMENTS_NEXT_APPOINTMENT_LABEL,
  APPOINTMENTS_SHOW_COMMENT_LABEL,
  APPOINTMENTS_MULTI_TRIPS_LABEL,
  APPOINTMENTS_DEPARTURE_LABEL,
  APPOINTMENTS_DESTINATION_LABEL,
  APPOINTMENTS_SELECT_ADDRESS_LABEL,
  APPOINTMENTS_PHONE_LABEL,
  APPOINTMENTS_INFUSION_LABEL,

  APPOINTMENTS_DRIVE_VEHICLE,
  APPOINTMENTS_RETURN_VEHICLE,

  BILLS_FILTER_TITLE_LABEL,
  BILLS_FILTER_CUSTOMER_LABEL,
  BILLS_FILTER_ADDRESS_LABEL,
  BILLS_FILTER_KEYWORDS,
  BILLS_FILTER_BILL_NUMBER,
  BILLS_FILTER_STATUS,
  BILLS_FILTER_EXCLUDE,
  BILLS_FILTER_INCLUDE,
  BILLS_FILTER_AMOUNT_MIN,
  BILLS_FILTER_AMOUNT_MAX,
  BILLS_FILTER_INCLUDE_INACTIVE,
  BILLS_FILTER_EXCLUDE_INACTIVE,
  BILLS_FILTER_FROM_DATE,
  BILLS_FILTER_TO_DATE,
  BILLS_FILTER_INCLUDE_UNPAID,
  BILLS_FILTER_EXCLUDE_UNPAID,

  BILLS_TOTAL_AMOUNT_LABEL,
  BILLS_TOTAL_PAID_LABEL,

  BILLS_NUMBER_LABEL,
  BILLS_CUSTOMER_LABEL,
  BILLS_AMOUNT_DUE_LABEL,
  BILLS_AMOUNT_PAID_LABEL,
  BILLS_AMOUNT_REMAINING_LABEL,
  BILLS_HOSPITAL_WARNING_LABEL,

  /* BILLS NEW INPUT LABELS*/
  BILLS_HOSPITAL_ORDER_NUMBER_LABEL,

  BILLS_HOSPITAL_NB_ROUTES_LABEL,
  BILLS_HOSPITAL_NB_ROUTES_SINGLE_LABEL,
  BILLS_HOSPITAL_NB_ROUTES_TWO_WAY_LABEL,
  BILLS_HOSPITAL_NB_ROUTES_MULTIPLE_LABEL,

  BILLS_HOSPITAL_POSITION_LABEL,
  BILLS_HOSPITAL_POSITION_AMBULANCE_LABEL,
  BILLS_HOSPITAL_POSITION_CADDY_LABEL,
  BILLS_HOSPITAL_POSITION_TAXI_LABEL,

  BILLS_TITLE_LABEL,
  BILLS_SELECT_BILL_LABEL,
  BILLS_BILL_NUMBER_LABEL,
  BILLS_PASSENGER_LABEL,
  BILLS_DEPARTURE_LABEL,
  BILLS_DESTINATION_LABEL,
  BILLS_DEPARTURE_TEXT_LABEL,
  BILLS_DESTINATION_TEXT_LABEL,
  BILLS_COMMENT_INTERNAL_LABEL,
  BILLS_COMMENT_EXTERNAL_LABEL,
  BILLS_TRANSPORT_TICKET_LABEL,
  BILLS_TRANSPORT_DATE_LABEL,
  BILLS_ORDER_DATE_LABEL,
  BILLS_FIRST_REMINDER_DATE_LABEL,
  BILLS_SECOND_REMINDER_DATE_LABEL,
  BILLS_DAS_DATE_LABEL,
  BILLS_FULL_CHARGE_LABEL,
  BILLS_PARTIAL_CHARGE_LABEL,
  BILLS_CREATE_BUTTON_LABEL,
  BILLS_SAVE_BUTTON_LABEL,

  BILLS_POSITIONS_TITLE,
  BILLS_POSITIONS_DESCRIPTION_LABEL,
  BILLS_POSITIONS_QUANTITY_LABEL,
  BILLS_POSITIONS_AMOUNT_LABEL,
  BILLS_POSITIONS_TOTAL_AMOUNT_LABEL,
  BILLS_POSITIONS_PRESET_LABEL,
  BILLS_ADD_POSITION_BUTTON_LABEL,
  BILLS_ADD_POSITION_DIALOG_TITLE,
  BILLS_EDIT_POSITION_BUTTON_LABEL,
  BILLS_EDIT_POSITION_DIALOG_TITLE,
  BILLS_REMOVE_POSITION_DIALOG_TITLE,
  BILLS_DAS_LABEL,
  BILLS_IS_OVERWEIGHT_LABEL,
  BILLS_WEIGHT_LABEL,
  BILLS_HAS_STAIRS_LABEL,
  BILLS_NUMBER_STAIRS_LABEL,
  BILLS_WHEELCHAIR_LABEL,
  BILLS_ELEVATOR_LABEL,
  BILLS_REMINDER_UPDATE_LABEL,

  BILLS_GENERATING_MESSAGE,

  BILLS_ALL_REMINDER_LABEL,
  BILLS_ALL_EXPORT_LABEL,
  BILLS_EXPORT_TO_PEPPOL,
  BILLS_MULTI_REPORT_LABEL,
  BILLS_MULTI_STATUS_LABEL,
  BILLS_MULTI_REMINDER_LABEL,
  BILLS_MULTI_BILLING_LABEL,
  BILLS_MULTI_BILLING_CREATE_LABEL,
  BILLS_MULTI_BILLING_UPDATE_STATE_LABEL,
  BILLS_MULTI_BILLING_FILL_LAST,
  BILLS_MULTI_FOOTER_LABEL,
  BILLS_MULTI_EXPORT_LABEL,

  BILLS_PEPPOL_EXPORT_LABEL,
  BILLS_PEPPOL_RESEND_LABEL,
  BILLS_PEPPOL_ADDRESSES_LABEL,
  BILLS_PEPPOL_SELECT_ADDRESSES_LABEL,
  BILLS_PEPPOL_EXPORT_SUCCESS,
  BILLS_PEPPOL_EXPORT_FAIL,
  BILLS_PEPPOL_NO_INVOICES_FOUND,

  BILLS_MULTI_EXPORT_SORT_TITLE,
  BILLS_MULTI_EXPORT_SORT_SUMMARY,

  BILLS_MULTI_STATUS_SUCCESS,

  BILLS_MULTI_EXPORT_SORT_ORDER_BILL_NUMBER_ASC,
  BILLS_MULTI_EXPORT_SORT_ORDER_BILL_NUMBER_DESC,
  BILLS_MULTI_EXPORT_SORT_ORDER_CUSTOMER_ASC,

  BILLS_FILES_TITLE,
  BILLS_FILES_CHOOSE_LABEL,
  BILLS_FILES_UPLOAD_LABEL,
  BILLS_FILES_REPORT_LABEL,
  BILLS_FILES_WARNING_LABEL,
  BILLS_FILES_MERGE_LABEL,
  BILLS_FILES_SEND_LABEL,
  BILLS_FILES_EMPTY_LABEL,
  BILLS_FILES_BILL_PDF_TITLE,
  BILLS_FILES_ADDITIONAL_FILES_TITLE,

  BILLS_FILES_UPLOAD_SUCCESS,
  BILLS_FILES_REMOVE_SUCCESS,

  BILLS_FILES_ERROR_UPLOAD,
  BILLS_FILES_ERROR_SIZE_SUMMARY,
  BILLS_FILES_ERROR_SIZE_DETAIL,

  BILLS_VALIDATION_ERROR_BILL_NUMBER,
  BILLS_VALIDATION_ERROR_STATUS,
  BILLS_VALIDATION_ERROR_TRANSPORT_TYPE,
  BILLS_VALIDATION_ERROR_BILLING_DATE,
  BILLS_VALIDATION_ERROR_CUSTOMER,
  BILLS_VALIDATION_ERROR_ADDRESS,
  BILLS_VALIDATION_ERROR_FROM_TEXT,
  BILLS_VALIDATION_ERROR_TO_TEXT,
  BILLS_VALIDATION_WARNING_NO_FILE,

  BILLS_WARNING_UPLOAD_DISABLED,

  PAYMENTS_FILTER_TITLE_LABEL,
  PAYMENTS_SELECT_PAYMENT_LABEL,
  PAYMENTS_TITLE_LABEL,
  PAYMENTS_FILTER_MIN_AMOUNT_LABEL,
  PAYMENTS_FILTER_MAX_AMOUNT_LABEL,
  PAYMENTS_FILTER_FROM_DATE_LABEL,
  PAYMENTS_FILTER_TO_DATE_LABEL,
  PAYMENTS_FILTER_CUSTOMER_LABEL,
  PAYMENTS_FILTER_BILL_LABEL,
  PAYMENTS_FILTER_ADDRESS_KEY_LABEL,
  PAYMENTS_FILTER_PAYMENT_KEY_LABEL,
  PAYMENTS_FILTER_CUSTOMER_KEY_LABEL,
  PAYMENTS_FILTER_BILL_KEY_LABEL,
  PAYMENTS_FILTER_PAYMENT_FROM_DATE_LABEL,
  PAYMENTS_FILTER_PAYMENT_TO_DATE_LABEL,

  PAYMENTS_CUSTOMER_LABEL,
  PAYMENTS_BILLING_ADDRESS_LABEL,
  PAYMENTS_BILL_NUMBER_LABEL,
  PAYMENTS_DATE_LABEL,
  PAYMENTS_AMOUNT_LABEL,

  PAYMENTS_SAVE_BUTTON_LABEL,
  PAYMENTS_CREATE_BUTTON_LABEL,

  PAYMENTS_BANK_LABEL,
  PAYMENTS_BILL_TITLE_LABEL,
  PAYMENTS_BILL_NOT_SELECTED,

  PAYMENT_SAVE_SUCCESS_MESSAGE,
  PAYMENT_DELETE_SUCCESS_MESSAGE,
  PAYMENT_DELETE_FAILURE_MESSAGE,

  PAYMENTS_VALIDATION_ERROR_BANK,
  PAYMENTS_VALIDATION_ERROR_CUSTOMER,
  PAYMENTS_VALIDATION_ERROR_BILL,
  PAYMENTS_VALIDATION_ERROR_INVALID_AMOUNT,
  PAYMENTS_VALIDATION_ERROR_BILLING_DATE,

  PEPPOL_SET_RESEND_SUCCESS,
  PEPPOL_SEND_BILL_SUCCESS,
  PEPPOL_SEND_BILL_FAILURE,

  PRODUCTS_NAME_LABEL,
  PRODUCTS_PRICE_LABEL,
  PRODUCTS_QUANTITY_LABEL,
  PRODUCTS_NO_SELECTION_TITLE,
  PRODUCTS_NEW_ENTRY_TITLE,
  PRODUCTS_EDIT_ENTRY_TITLE,
  PRODUCTS_SAVE_BUTTON_LABEL,
  PRODUCTS_CREATE_BUTTON_LABEL,

  PRODUCTS_VALIDATION_NAME_REQUIRED,

  VEHICLES_NEW_ENTRY_TITLE,
  VEHICLES_EDIT_ENTRY_TITLE,
  VEHICLES_NO_SELECTION_LABEL,
  VEHICLES_TYPE_LABEL,
  VEHICLES_AVAILABLE_LABEL,
  VEHICLES_YEAR_LABEL,
  VEHICLES_KM_LABEL,
  VEHICLES_LICENSE_PLATE_LABEL,
  VEHICLES_PHONE_LABEL,
  VEHICLES_COMMENT_LABEL,
  VEHICLES_WINFLEET_NAME_LABEL,

  VEHICLES_SAVE_BUTTON_LABEL,
  VEHICLES_CREATE_BUTTON_LABEL,

  VEHICLES_VALIDATION_PLATE_REQUIRED,
  VEHICLES_VALIDATION_PLATE_INVALID,
  VEHICLES_VALIDATION_TYPE_REQUIRED,

  TRANSPORT_TYPE_NONE,
  TRANSPORT_TYPE_AMBULANCE,
  TRANSPORT_TYPE_CADDY,
  TRANSPORT_TYPE_TAXI,
  TRANSPORT_TYPE_EMPTY,
  TRANSPORT_TYPE_BUS,
  TRANSPORT_TYPE_VSL,

  ADDRESS_TYPE_NONE,
  ADDRESS_TYPE_INVOICE,
  ADDRESS_TYPE_BASE,
  ADDRESS_TYPE_ADDITIONAL,

  BILL_STATUS_NEW,
  BILL_STATUS_SENT,
  BILL_STATUS_PARTIALLY_PAID,
  BILL_STATUS_REMINDER_1,
  BILL_STATUS_REMINDER_2,
  BILL_STATUS_DAS,
  BILL_STATUS_CLOSED,
  BILL_STATUS_OVERPAID,
  BILL_STATUS_DELIVERY_NOTE,

  APPOINTMENT_STATUS_NONE,
  APPOINTMENT_STATUS_RETURN,
  APPOINTMENT_STATUS_OUTWARD,
  APPOINTMENT_STATUS_EXECUTED,
  APPOINTMENT_STATUS_WAITING,
  APPOINTMENT_STATUS_INVOICED,
  APPOINTMENT_STATUS_CANCELLED,

  APPOINTMENT_STATUS_PENDING,
  APPOINTMENT_STATUS_BILLED,

  APPOINTMENT_UNRELEASED_ONLY,
  APPOINTMENT_ALL_BILLS,

  APPOINTMENT_CANCEL_SELECTED_LABEL,
  APPOINTMENT_CANCEL_ERROR,
  APPOINTMENT_CANCEL_SUCCESS,
  APPOINTMENT_CANCEL_NO_SELECTED,

  SETTINGS_ACCOUNT_NUMBER_LABEL,
  SETTINGS_ACCOUNT_BILL_TEXT_LABEL,
  SETTINGS_SAVE_BUTTON_LABEL,
  SETTINGS_VALIDATION_ACCOUNT_NUMBER_REQUIRED,
  SETTINGS_UPDATE_SUCCESS_MESSAGE,

  SHIFTS_CREATE_BUTTON_LABEL,
  SHIFTS_EDIT_BUTTON_LABEL,
  SHIFTS_DELETE_BUTTON_LABEL,
  SHIFTS_EVENT_SUMMARY_LABEL,
  SHIFTS_ERROR_NO_SHIFT,
  SHIFTS_NO_SHIFT_LABEL,
  SHIFTS_NO_MEMBERS_LABEL,
  SHIFTS_DUTY_LABEL,
  SHIFTS_DELETE_SUCCESS,
  SHIFTS_UPDATE_SUCCESS,
  SHIFTS_UPDATE_FAIL,
  SHIFTS_MEMBERS_PLACEHOLDER,

  ON_CALLS_REGISTER_BUTTON_LABEL,
  ON_CALLS_UNREGISTER_BUTTON_LABEL,
  ON_CALLS_EDIT_BUTTON_LABEL,
  ON_CALLS_NUMBER_SLOTS_LABEL,
  ON_CALLS_SLOTS_OCCUPIED_LABEL,
  ON_CALLS_SHIFT_FULL_LABEL,
  ON_CALLS_SHIFT_EMPTY_LABEL,
  ON_CALLS_DRIVERS_TITLE,
  ON_CALLS_NEW_SHIFT_TITLE,
  ON_CALLS_LOW_SLOTS_WARNING,
  ON_CALLS_TYPES_WARNING,
  ON_CALLS_CONFIRMED,

  VACATIONS_REPORT_CURRENT_LABEL,
  VACATIONS_MY_VACATIONS_LABEL,
  VACATIONS_START_LABEL,
  VACATIONS_END_LABEL,
  VACATIONS_COMMENT_USER_LABEL,
  VACATIONS_COMMENT_ADMIN_LABEL,
  VACATIONS_STATUS_LABEL,
  VACATIONS_CREATE_BUTTON_LABEL,
  VACATIONS_DELETE_BUTTON_LABEL,
  VACATIONS_UPDATE_BUTTON_LABEL,
  VACATIONS_CREATE_ADMIN_BUTTON_LABEL,
  VACATIONS_CREATE_DIALOG_TITLE,
  VACATIONS_EDIT_DIALOG_TITLE,
  VACATIONS_NO_VACATIONS_LABEL,
  VACATIONS_AVAILABLE_LABEL,
  VACATIONS_HALF_DAY_LABEL,
  VACATIONS_SICK_DAY_LABEL,
  VACATIONS_SPECIAL_LABEL,
  VACATIONS_SHOW_ALL_LABEL,

  VACATIONS_VACATION_LABEL,

  VACATIONS_HOLIDAY_TITLE_LABEL,
  VACATIONS_HOLIDAY_LABEL,

  VACATIONS_SET_OLD,
  VACATIONS_SET_OLD_CONFIRM,
  VACATIONS_SET_OLD_FAIL,

  VACATIONS_EVENT_HOURS,
  VACATIONS_SHOW_HOURS,
  VACATIONS_SHOW_REGULAR,
  VACATIONS_AMOUNT_HOURS,

  VACATION_DATE_VALIDATION_FAIL,
  VACATION_USER_VALIDATION_FAIL,
  VACATION_HOURS_VALIDATION_FAIL,

  VACATION_MINUTES_VALIDATION_FAIL,
  VACATIONS_AMOUNT_MINUTES,

  VACATIONS_HOLIDAY_UPDATE_SUCCESS_MESSAGE,
  VACATIONS_HOLIDAY_UPDATE_FAILURE_MESSAGE,
  VACATIONS_HOLIDAY_DELETE_SUCCESS_MESSAGE,
  VACATIONS_HOLIDAY_DELETE_FAILURE_MESSAGE,
  VACATIONS_HOLIDAY_DELETE_WARNING,
  VACATION_SAVE_HOLIDAY_LABEL,

  VACATION_UPDATE_SUCCESS_MESSAGE,
  VACATION_UPDATE_FAILURE_MESSAGE,
  VACATION_DELETE_SUCCESS_MESSAGE,
  REGULARS_START,
  REGULARS_END,
  REGULARS_DATES,
  REGULARS_ID,
  REGULARS_DELETE_CONFIRM,
  REGULARS_DELETE_SUCCESS,
  REGULARS_DELETE_FAIL,

  DRIVES_HEADER_DAY,
  DRIVES_HEADER_GARAGE_START,
  DRIVES_HEADER_GARAGE_END,
  DRIVES_HEADER_DATE,
  DRIVES_HEADER_NAME,
  DRIVES_HEADER_PAUSE,

  DRIVERS_HEADER_CHECKED,
  DRIVERS_HEADER_NEXT,
  DRIVERS_HEADER_BLOCKED,
  DRIVERS_HEADER_CONTROLLED,

  DRIVES_TABLE_RETURN,
  DRIVES_TABLE_START_TIME,
  DRIVES_TABLE_PATIENT,
  DRIVES_TABLE_START_ADDRESS,
  DRIVES_TABLE_DESTINATION_ADDRESS,
  DRIVES_TABLE_MEETING_TIME,
  DRIVES_TABLE_VEHICLE,
  DRIVES_TABLE_BUTTON_START,

  DRIVES_REFRESH,
  DRIVES_SAVE_TIMES,

  DRIVES_SAVE_DAILY_REPORT,
  DRIVES_PRINT_DAILY_REPORT,
  DRIVES_PRINT_TRANSPORT_REPORT,
  DRIVES_PRINT_MONTHLY_REPORT,
  DRIVES_REUPLOAD,

  DRIVES_TIMES_RESPONSIVE,
  DRIVES_ADDRESS_RESPONSIVE,
  DRIVES_TIME_START_RESPONSIVE,
  DRIVES_TIME_MEETING_RESPONSIVE,
  DRIVES_DEPARTURE_RESPONSIVE,
  DRIVES_ARRIVAL_RESPONSIVE,

  DRIVES_NO_APPOINTMENT,
  DRIVES_CITY,
  DRIVES_PAPER_WAIT,
  DRIVES_MINUTES,
  DRIVES_HOURS_DEPARTURE,
  DRIVES_HOURS_ARRIVAL,
  DRIVES_ADMISSION,
  DRIVES_KILOMETERS,

  DRIVES_DETAILS_TITLE,
  DRIVES_TRANSPORT_TITLE,
  DRIVES_INFO_TITLE,

  DRIVES_SIGNATURE_HEADER,
  DRIVES_SIGNATURE_CLEAR,
  DRIVES_SIGNATURE_SAVE,

  DRIVES_WS_TITLE,
  DRIVES_WS_DELETE,
  DRIVES_WS_UPDATE,

  DRIVES_DAILY_SAVE_SUCCESS,
  DRIVES_DAILY_SAVE_FAIL,
  DRIVES_DAILY_ADDITIONAL_SAVE_SUCCESS,
  DRIVES_DAILY_ADDITIONAL_SAVE_FAIL,
  DRIVER_GENERATE_MONTHLY_REPORT_FAIL,
  DRIVER_GENERATE_TRANSPORT_REPORT_FAIL,

  DRIVES_REPORT_VIEW,
  DRIVES_WORK_VIEW,
  MONTH_REPORT_SEARCH_NAME,

  SHOUTBOX_NO_CONTENT,
  SHOUTBOX_NO_MESSAGE,
  SHOUTBOX_SEARCH_PLACEHOLDER,
  SHOUTBOX_NOTIFICATION_EDIT_TITLE,
  SHOUTBOX_NOTIFICATION_DELETE_TITLE,
  SHOUTBOX_NOTIFICATION_EDIT_BODY,
  SHOUTBOX_NOTIFICATION_DELETE_BODY,
  SHOUTBOX_DELETE_CONFIRM,
  SHOUTBOX_DIALOG_TITLE_ADD,
  SHOUTBOX_DIALOG_TITLE_EDIT,
  SHOUTBOX_DIALOG_TITLE_DELETE,
  SHOUTBOX_DIALOG_TITLE_CHAT,
  SHOUTBOX_NOTIFICATION_EDIT_REACTION_TITLE,
  SHOUTBOX_NOTIFICATION_EDIT_DEFAULT_BODY,
  SHOUTBOX_NOTIFICATION_EDIT_REACTION_BODY,
  SHOUTBOX_IMAGE_SELECT_BUTTON_LABEL,
  SHOUTBOX_SORT_SEVERITY_LABEL,
  SHOUTBOX_SORT_TIME_LABEL,
  SHOUTBOX_GROUP_TT_MORE,
  SHOUTBOX_GROUP_EMPTY_MESSAGE,
  SHOUTBOX_EDIT_FAIL_MESSAGE,
  SHOUTBOX_DELETE_FAIL_MESSAGE,

  MONTH_REPORT_NAME,
  MONTH_REPORT_TOTAL,

  APRIL_LONG,
  APRIL_SHORT,
  AUGUST_LONG,
  AUGUST_SHORT,
  DECEMBER_LONG,
  DECEMBER_SHORT,
  FEBRUARY_LONG,
  FEBRUARY_SHORT,
  FRIDAY_LONG,
  FRIDAY_SHORT,
  JANUARY_LONG,
  JANUARY_SHORT,
  JULY_LONG,
  JULY_SHORT,
  JUNE_LONG,
  JUNE_SHORT,
  MARCH_LONG,
  MARCH_SHORT,
  MAY_LONG,
  MAY_SHORT,
  MONDAY_LONG,
  MONDAY_SHORT,
  NOVEMBER_LONG,
  NOVEMBER_SHORT,
  OCTOBER_LONG,
  OCTOBER_SHORT,
  SATURDAY_LONG,
  SATURDAY_SHORT,
  SEPTEMBER_LONG,
  SEPTEMBER_SHORT,
  SUNDAY_LONG,
  SUNDAY_SHORT,
  THURSDAY_LONG,
  THURSDAY_SHORT,
  TODAY,
  TUESDAY_LONG,
  TUESDAY_SHORT,
  WEDNESDAY_LONG,
  WEDNESDAY_SHORT,

  PURGE_BUTTON_LABEL,

  FUZZY_ON,
  FUZZY_OFF,

  ACTIVE,
  INACTIVE,

  CREATED_BY,
  CREATED_ON,

  EDITED_BY,
  EDITED_ON,

  STARTS_WITH,
  CONTAINS,
  NOT_CONTAINS,
  ENDS_WITH,
  EQUALS,
  NOT_EQUALS,
  NO_FILTER,
  DRIVER_STATUS_SAVE_FAILED,
  DRIVER_STATUS_SAVE_SUCCESS,
  DRIVER_STATUS_AVAILABLE_LABEL,
  DRIVER_STATUS_IN_GARAGE_AND_AVAILABLE_LABEL,
  DRIVER_STATUS_ON_THE_ROAD_TO_PATIENT_LABEL,
  DRIVER_STATUS_WITH_PATIENT_LABEL,
  DRIVER_STATUS_ON_THE_ROAD_WITH_PATIENT_LABEL,
  DRIVER_STATUS_CLOSING_TIME_LABEL,
  DRIVER_STATUS_UNLOADING_LABEL,
  DRIVER_STATUS_SORT_LABEL,
  DRIVER_STATUS_SORT_DRIVER_NAME_LABEL,
  DRIVER_STATUS_SORT_LICENCE_PLATE_LABEL,
  DRIVER_STATUS_SORT_NEWEST_LABEL,
  DRIVER_STATUS_SORT_NO_RESULT,
  DRIVER_STATUS_BREAK_LABEL,
  DRIVER_STATUS_SORT_NO_HISTORICAL_VALUES,
  HISTORICAL_APPOINTMENTS_NO_RESULT,
  HISTORICAL_APPOINTMENTS_LABEL,
  HISTORICAL_APPOINTMENTS_INFO,
} = MESSAGE_KEYS;

const { USERNAME_MIN_LENGTH, PASSWORD_MIN_LENGTH, MATRICULATION_MIN_LENGTH } =
  VALIDATION_RULES;

const { GERMAN, FRENCH } = LOCALES;

const messages = {
  [GERMAN.key]: {
    [MENU_APPOINTMENTS]: "Termine",
    [MENU_ACCOUNT]: "Benutzerkonto",
    [MENU_USERS]: "Benutzerverwaltung",
    [MENU_DRIVER_STATUS]: "Fahrerstatus",
    [MENU_APPOINTMENTS_SEARCH]: "Termine suchen",
    [MENU_CUSTOMERS]: "Patientenverwaltung",
    [MENU_LOGOUT]: "Ausloggen",
    [MENU_ADDRESSES]: "Adressen",
    [MENU_BILLS]: "Rechnungen",
    [MENU_PAYMENTS]: "Bezahlungen",
    [MENU_BILL_MANAGEMENT]: "Rechnungsverwaltung",
    [MENU_COMPANY_MANAGEMENT]: "Betriebsverwaltung",
    [MENU_APPOINTMENT_MANAGEMENT]: "Terminverwaltung",
    [MENU_VACATION]: "Urlaubsanfragen",
    [MENU_SHIFTS]: "Schichtdienst",
    [MENU_ON_CALL]: "Bereitschaftsdienst",
    [MENU_SETTINGS]: "Konfiguration",
    [MENU_DASHBOARD]: "Übersicht",
    [MENU_PRODUCTS]: "Produkte",
    [MENU_VEHICLES]: "Fahrzeuge",
    [MENU_EMPTY]: "Leere Seite",
    [MENU_REGULARS]: "Stammpatienten",
    [MENU_HOLIDAYS]: "Feiertage",
    [MENU_DRIVES]: "Fahrten",
    [MENU_SHOUTBOX]: "Shoutbox",
    [MENU_MONTH_REPORT]: "Monatsbericht",

    [LOGIN_BUTTON_LABEL]: "Einloggen",
    [LOGIN_PASSWORD_LABEL]: "Passwort",
    [LOGIN_USERNAME_LABEL]: "Benutzername",
    [LOGIN_TITLE_LABEL]: "Login",

    [LOGIN_WARNING_FAILED]: "Ihr Login oder Passwort ist falsch.",

    [DASHBOARD_SALES_TITLE]: "Umsätze pro Monat",
    /*     [DASHBOARD_BIRTHDAY_TITLE]: "Anstehende Geburtstage", */
    [DASHBOARD_BIRTHDAY_TITLE]: "Geburtstage",
    [DASHBOARD_OVERVIEW_TABLE_YEAR_HEADER]: "Jahr",
    [DASHBOARD_OVERVIEW_TABLE_MONTH_HEADER]: "Monat",
    [DASHBOARD_SALES_FILTER_TITLE]: "Umsätze filtern",
    [DASHBOARD_SALES_FILTER_FROM_LABEL]: "Von",
    [DASHBOARD_SALES_FILTER_TO_LABEL]: "Bis",
    [DASHBOARD_SALES_FILTER_INCLUDE_LABEL]: "Inkl. Kliniken & Altersheime",

    [EMPTY_PAGE_TITLE]: "Seite nicht verfügbar",
    [EMPTY_PAGE_SUMMARY]:
      "Die Seite kann nicht angezeigt werden weil die URL falsch ist oder weil Sie nicht über die entsprechenden Zugriffsrechte verfügen.",

    [ACCOUNT_EMAIL_LABEL]: "Email-Adresse",
    [ACCOUNT_LANGUAGE_LABEL]: "Sprache",
    [ACCOUNT_NAME_LABEL]: "Login",
    [ACCOUNT_ROLES_LABEL]: "Rollen",
    [ACCOUNT_PASSWORD_LABEL]: "Passwort",
    [ACCOUNT_PASSWORD_REPEAT_LABEL]: "Passwort wiederholen",
    [ACCOUNT_TITLE_LABEL]: "Benutzerkonto bearbeiten",
    [ACCOUNT_SAVE_BUTTON_LABEL]: "Speichern",
    [ACCOUNT_CREATE_BUTTON_LABEL]: "Erstellen",
    [ACCOUNT_SAVE_SUCCESS_MESSAGE]:
      "Die Benutzerdaten wurden erfolgreich gespeichert.",

    [ACCOUNT_FIRST_LOGIN_WARNING]:
      "Bitte geben Sie ein neues Passwort für Ihren Account ein.",
    [ACCOUNT_SAVE_PASSWORD_LABEL]: "Passwort speichern",
    [ACCOUNT_FIRST_LOGIN_HEADER]: "Passwort anlegen",

    [ACCOUNT_ERROR_EMAIL_INVALID]: "Ungültige Email-Adresse.",
    [ACCOUNT_ERROR_EMAIL_REQUIRED]: "Keine Email-Adresse angegeben.",
    [ACCOUNT_ERROR_NAME_LENGTH]: `Ihr Name muss mindestens ${USERNAME_MIN_LENGTH} Zeichen haben.`,
    [ACCOUNT_ERROR_NAME_REQUIRED]: "Kein Benutzername angegeben.",
    [ACCOUNT_ERROR_PASSWORD_LENGTH]: `Ihr Passwort muss mindestens ${PASSWORD_MIN_LENGTH} Zeichen haben.`,
    [ACCOUNT_ERROR_PASSWORD_MATCH]:
      "Die eingegebenen Passwörter müssen gleich sein.",
    [ACCOUNT_ERROR_COULD_NOT_LOAD]:
      "Benutzerkonto-Daten konnten nicht geladen werden.",

    [CUSTOMERS_FILTER_TITLE_LABEL]: "Patienten suchen",
    [CUSTOMERS_FILTER_NAME]: "Name",
    [CUSTOMERS_FILTER_ADDRESS]: "Adresse",
    [CUSTOMERS_FILTER_PHONE]: "Telefon",
    [CUSTOMERS_FILTER_EMAIL]: "Email",
    [CUSTOMERS_FILTER_INSURANCE_NUMBER]: "Matrikel",
    [CUSTOMERS_FILTER_ONLY_ACTIVE]: "Nur aktive Patienten",

    [CUSTOMER_FILTER_FUZZY_TOOLTIP]:
      "Wenn diese Option ausgewählt ist, werden Resultate angezeigt die den eingegeben Suchkriterien ähnlich sind.",

    [CUSTOMER_BILL_TITLE_LABEL]: "Patientenrechnungen",
    [CUSTOMER_DATA_TITLE_LABEL]: "Patientendaten",
    [CUSTOMER_PAYMENT_TITLE_LABEL]: "Patientenzahlungen",
    [CUSTOMER_PAYMENT_TITLE_SELECTION_LABEL]: "Zahlungen für {billNumber}",
    [CUSTOMER_SELECTED_CUSTOMER_LABEL]: "Wählen Sie einen Patienten aus",
    [CUSTOMERS_FIRST_NAME_LABEL]: "Vorname",
    [CUSTOMERS_MIDDLE_NAME_LABEL]: "Mädchenname",
    [CUSTOMERS_LAST_NAME_LABEL]: "Nachname",
    [CUSTOMERS_EMAIL_LABEL]: "Email-Adresse",
    [CUSTOMERS_COMMENT_LABEL]: "Bemerkung",
    [CUSTOMERS_FAX_LABEL]: "Fax-Nr.",
    [CUSTOMERS_PHONE_LABEL]: "Telefon-Nr.",
    [CUSTOMERS_MOBILE_LABEL]: "Handy-Nr.",
    [CUSTOMERS_MATRICULATION_LABEL]: "Matrikel",
    [CUSTOMERS_PRINT_BUTTON_LABEL]: "Drucken",
    [CUSTOMERS_CREATE_BUTTON_LABEL]: "Neuen Patienten anlegen",
    [CUSTOMERS_SAVE_BUTTON_LABEL]: "Patientendaten aktualisieren",
    [CUSTOMERS_GENDER_LABEL]: "Geschlecht",
    [CUSTOMERS_TITLE_LABEL]: "Titel",
    [CUSTOMERS_BIRTH_DATE_LABEL]: "Geburtsdatum",

    [CUSTOMERS_ADDRESS_TITLE_LABEL]: "Adressen",
    [CUSTOMERS_ADDRESS_TYPE_LABEL]: "Adress-Typ",
    [CUSTOMERS_ADD_ADDRESS_TITLE_LABEL]: "Adresse hinzufügen",
    [CUSTOMERS_ADD_PAYMENT_TITLE_LABEL]: "Bezahlung hinzufügen",
    [CUSTOMERS_ADDRESS_CITY_LABEL]: "Ortschaft",
    [CUSTOMERS_ADDRESS_LINE_1_LABEL]: "Zeile 1",
    [CUSTOMERS_ADDRESS_LINE_2_LABEL]: "Zeile 2",
    [CUSTOMERS_ADDRESS_ZIP_LABEL]: "PLZ",
    [CUSTOMERS_ADDRESS_RETRO_LABEL]:
      "Adresse für bestehende Rechnungen verwenden",
    [CUSTOMERS_ADDRESS_CREATE_BUTTON_LABEL]: "Neue Adresse hinzufügen",

    [CUSTOMERS_ADDRESS_DUPLICATE_WARNING]:
      "Eine {type} existiert bereit.\nWenn Sie fortfahren wird alte Adresse eine Zusatzadresse.",

    [CUSTOMERS_DEAD_LABEL]: "Verstorben",

    [CUSTOMERS_SAVE_SUCCESS_MESSAGE]:
      "Die Patientendaten wurden erfolgreich gespeichert.",

    [CUSTOMERS_ERROR_MATRICULATION_LENGTH]: `Die Matrikel muss ${MATRICULATION_MIN_LENGTH} Zeichen haben.`,
    [CUSTOMERS_ERROR_MATRICULATION_REQUIRED]: "Keine Matrikel angegeben.",
    [CUSTOMERS_VALIDATION_ERROR_INSURANCE_NUMBER]:
      "Der zweite Teil der Matrikel muss 5 Stellen haben.",

    [ADDRESSES_FILTER_TITLE_LABEL]: "Adressen suchen",
    [ADDRESSES_FILTER_CUSTOMER_LABEL]: "Patientenname- oder Vorname",
    [ADDRESSES_FILTER_ADDRESS_LABEL]: "Adresse, PLZ oder Ortschaft",
    [ADDRESSES_FILTER_HIN_LABEL]: "Patienten-Matrikel",

    [ADDRESSES_TITLE_LABEL]: "Adresse bearbeiten",
    [ADDRESSES_SELECT_ADDRESS_LABEL]: "Bitte wählen Sie eine Adresse aus.",
    [ADDRESSES_SAVE_BUTTON_LABEL]: "Adresse aktualisieren",
    [ADDRESSES_FILTER_SEARCHKEY_LABEL]: "Suchschlüssel",

    [ADDRESSES_HOSPITAL_LABEL]: "Krankenhaus",
    [ADDRESSES_NURSING_LABEL]: "Altersheim",
    [ADDRESSES_AMBULANCE_LABEL]: "Transport Ambulanz",

    [ADDRESSES_PHONE_1_LABEL]: "Telefon-Nr. 1",
    [ADDRESSES_PHONE_2_LABEL]: "Telefon-Nr. 2",

    [ADDRESSES_ERROR_LINE1_REQUIRED]: "Keine Zeile 1 angegeben",
    [ADDRESSES_ERROR_ZIP_REQUIRED]: "Keine PLZ angegeben",
    [ADDRESSES_ERROR_CITY_REQUIRED]: "Keine Ortschaft angegeben",

    [APPOINTMENTS_TITLE_LABEL]: "Termine - {date}",
    [APPOINTMENTS_EDIT_DIALOG_TITLE_LABEL]: "Fahrt vom",
    [APPOINTMENTS_CREATE_DIALOG_TITLE_LABEL]: "Termin anlegen",
    [APPOINTMENTS_APPOINTMENT_LABEL]: "Termin",
    [APPOINTMENTS_SECOND_APPOINTMENT_LABEL]: "2. Termin",
    [APPOINTMENTS_CLIENT_LABEL]: "Patient",
    [APPOINTMENTS_START_ADDRESS_LABEL]: "Abfahrt-Adresse",
    [APPOINTMENTS_END_ADDRESS_LABEL]: "Ankunfts-Adresse",
    [APPOINTMENTS_FIRST_DRIVER_LABEL]: "Fahrer",
    [APPOINTMENTS_SECOND_DRIVER_LABEL]: "2. Fahrer",
    [APPOINTMENTS_STATUS_LABEL]: "Termin-Status:",
    [APPOINTMENTS_VEHICLE_LABEL]: "Fahrzeug",
    [APPOINTMENTS_LOCATION_LABEL]: "Region",
    [APPOINTMENTS_NEW_EVENT_LABEL]: "Neuer Termin",
    [APPOINTMENTS_START_DATE_LABEL]: "Hinfahrt",
    [APPOINTMENTS_VEHICLE_TYPE_LABEL]: "Fahrzeugtyp",
    [APPOINTMENTS_LAST_PAYMENT_DATE_LABEL]: "Rechnungsdatum:",
    [APPOINTMENTS_LAST_BILL_NUMBER_LABEL]: "Letzte Rechnung:",
    [APPOINTMENTS_CREATE_CUSTOMER_LABEL]: "Patient anlegen",
    [APPOINTMENTS_CREATE_BUTTON_LABEL]: "Neuen Termin anlegen",
    [APPOINTMENTS_SAVE_BUTTON_LABEL]: "Termin aktualisieren",

    [APPOINTMENTS_CUSTOMER_ADDRESS_WARNING]:
      "Die eingegebenen Werte werden überschrieben. Wollen Sie fortfahren?",
    [APPOINTMENTS_DELETE_BUTTON_LABEL]: "Termin löschen",
    [APPOINTMENTS_DELETE_SUCCESS]: "Der Termin wurde erfolgreich gelöscht.",
    [APPOINTMENTS_DELETE_FAIL]: "Der Termin konnte nicht gelöscht werden.",
    [APPOINTMENTS_DELETE_NOTICE]: "Soll der Eintrag gelöscht werden?",

    [APPOINTMENTS_DRIVER_MESSAGE]: "Nachricht Fahrer",
    [APPOINTMENTS_DRIVER_MESSAGE_SENT]: "Gesendet",
    [APPOINTMENTS_DRIVER_MESSAGE_RECEIVED]: "Empfangen",
    [APPOINTMENTS_DRIVER_MESSAGE_READ]: "Gelesen",

    [APPOINTMENTS_DRIVER_REPORT_SAVE]: "Speichern",
    [APPOINTMENTS_DRIVER_REPORT_CANCEL]: "Abbrechen",
    [APPOINTMENTS_DRIVER_REPORT_NEW]: "Neuer Bericht",
    [APPOINTMENTS_DRIVER_REPORT_EDIT]: "Bericht bearbeiten",
    [APPOINTMENTS_DRIVER_REPORT_EDIT_BUTTON]: "Edit.",
    [APPOINTMENTS_DRIVER_SAVE_ERROR_TITLE_LABEL]: "Ungültige Eingabe(en)",
    [APPOINTMENTS_DRIVER_SAVE_ERROR_DETAILS]:
      "Die rot markierten Felder müssen ausgefüllt sein!",

    [APPOINTMENTS_NO_CUSTOMER_ASSIGNED_NOTICE]:
      "Keine Patientendaten zugewiesen",
    [APPOINTMENTS_NO_CUSTOMER_NOTICE]: "Kein Patient",
    [APPOINTMENTS_NO_FROM_ADDRESS_NOTICE]: "Keine Startadresse",
    [APPOINTMENTS_NO_TO_ADDRESS_NOTICE]: "Keine Zieladresse",

    [APPOINTMENTS_UPDATE_SUCCESS_MESSAGE]:
      "Die Termindaten wurden erfolgreich aktualisiert",

    [APPOINTMENTS_PASSENGER_STEP_LABEL]: "Patient",
    [APPOINTMENTS_DRIVE_STEP_LABEL]: "Details Fahrt",
    [APPOINTMENTS_CONFIRM_STEP_LABEL]: "Bestätigen",
    [APPOINTMENTS_MULTI_STEP_LABEL]: "Mehrere Fahrten",

    [APPOINTMENTS_DRIVE_APPOINTMENT]: "Termin der Fahrt",
    [APPOINTMENTS_DEPARTURE_TIME]: "Zeit Termin",
    [APPOINTMENTS_RETURN_TIME]: "Zeit Rückfahrt",
    [APPOINTMENTS_KILOMETERS]: "Kilometer",
    [APPOINTMENTS_KILOMETERS_BACK]: "Kilometer zurück",
    [APPOINTMENTS_OUTBOUND_DRIVER_LABEL]: "Hinfahrer",
    [APPOINTMENTS_OUTBOUND_SECOND_DRIVER_LABEL]: "2. Hinfahrer",
    [APPOINTMENTS_RETURN_DRIVER_LABEL]: "Rückfahrer",
    [APPOINTMENTS_RETURN_SECOND_DRIVER_LABEL]: "2. Rückfahrer",
    [APPOINTMENTS_OUTBOUND_CAR_LABEL]: "Fahrzeug (Hinfahrt)",
    [APPOINTMENTS_RETURN_CAR_LABEL]: "Fahrzeug (Rückfahrt)",

    [APPOINTMENTS_DEPART_ROOM_LABEL]: "Abfahrt Raum",
    [APPOINTMENTS_DEPART_DEPARTMENT_LABEL]: "Abfahrt Abteilung",
    [APPOINTMENTS_DEPART_ADDRESS_COMMENT_LABEL]: "Abfahrt Addresse Freitext",
    [APPOINTMENTS_DEPART_ADDRESS_1_LABEL]: "Abfahrt Addresse Zeile 1",
    [APPOINTMENTS_DEPART_ADDRESS_2_LABEL]: "Abfahrt Addresse Zeile 2",
    [APPOINTMENTS_DEPART_ZIP_LABEL]: "Abfahrt Postleitzahl",
    [APPOINTMENTS_FROM_LOCATION]: "Abfahrt Ort",
    [APPOINTMENTS_CUSTOMER_GSM]: "Handynummer",
    [APPOINTMENTS_CUSTOMER_FULL_NAME]: "Ganzer Name",

    [APPOINTMENTS_ARRIVAL_ROOM_LABEL]: "Ankunft Raum",
    [APPOINTMENTS_ARRIVAL_DEPARTMENT_LABEL]: "Ankunft Abteilung",
    [APPOINTMENTS_ARRIVAL_ADDRESS_COMMENT_LABEL]: "Ankunft Addresse Freitext",
    [APPOINTMENTS_ARRIVAL_ADDRESS_1_LABEL]: "Ankunft Addresse Zeile 1",
    [APPOINTMENTS_ARRIVAL_ADDRESS_2_LABEL]: "Ankunft Addresse Zeile 2",
    [APPOINTMENTS_ARRIVAL_ZIP_LABEL]: "Ankunft Postleitzahl",
    [APPOINTMENTS_OUTWARD_PAPER_LABEL]: "Hinfahrt Papiere",
    [APPOINTMENTS_OUTWARD_DEPARTURE_LABEL]: "Hinfahrt Abfahrt",
    [APPOINTMENTS_OUTWARD_ARRIVAL_LABEL]: "Hinfahrt Ankunft",
    [APPOINTMENTS_OUTWARD_DISCHARGE_LABEL]: "Hinfahrt Anm./Abgabe",
    [APPOINTMENTS_RETURN_PAPER_LABEL]: "Rückfahrt Papiere",
    [APPOINTMENTS_RETURN_DEPARTURE_LABEL]: "Rückfahrt Abfahrt",
    [APPOINTMENTS_RETURN_ARRIVAL_LABEL]: "Rückfahrt Ankunft",
    [APPOINTMENTS_RETURN_DISCHARGE_LABEL]: "Rückfahrt Anm./Abgabe",

    [APPOINTMENTS_WEIGHT_NUMBER]: "Übergewichtig Kg",
    [APPOINTMENTS_STAIRS_COUNT]: "Anzahl Treppen",
    [APPOINTMENTS_VACCINATED]: "Geimpft",
    [APPOINTMENTS_TRANSPORT_TYPE_INVOICE]: "Transport",
    [APPOINTMENTS_RETURN_PHONE]: "Rückfahrt Telefonnummer",

    [APPOINTMENTS_CUSTOMER_HEADER]: "Patient",
    [APPOINTMENTS_DEPARTURE_HEADER]: "Abfahrt",
    [APPOINTMENTS_DESTINATION_HEADER]: "Ankunft",
    [APPOINTMENTS_RETURN_HEADER]: "Rückfahrt",
    [APPOINTMENTS_NEXT_HEADER]: "Nächster Termin",
    [APPOINTMENTS_STATE_LABEL]: "Status",
    [APPOINTMENTS_VACCINATED_LABEL]: "Geimpft",
    [APPOINTMENTS_BUS_LABEL]: "Bus",
    [APPOINTMENTS_PATIENT_STAY_LABEL]: "Bei Patient bleiben",
    [APPOINTMENTS_KEY_WORD_LABEL]: "Schlüsselwort/Ort",

    [APPOINTMENTS_CLEAR_ADDRESS]: "Adressdaten löschen",
    [APPOINTMENTS_USE_CUSTOMER_ADDRESS]: "Patientenadresse kopieren",
    [APPOINTMENTS_ROOM_LABEL]: "Zimmer",
    [APPOINTMENTS_DEPARTMENT_LABEL]: "Abteilung",
    [APPOINTMENTS_FREE_TEXT_LABEL]: "Freitext",
    [APPOINTMENTS_AMOUNT_LABEL]: "Anzahl",

    [APPOINTMENTS_MULTIPLE_DRIVES_LABEL]: "Mehrere Fahrten anlegen",
    [APPOINTMENTS_OXYGEN_LABEL]: "Sauerstoff",
    [APPOINTMENTS_DSA_LABEL]: "DSA",
    [APPOINTMENTS_POSITION_LYING_LABEL]: "Liegend",
    [APPOINTMENTS_POSITION_SITTING_LABEL]: "Sitzend",
    [APPOINTMENTS_RETURN_LABEL]: "Rückfahrt",
    [APPOINTMENTS_ROUND_TRIP_LABEL]: "Hin- & Rückfahrt",
    [APPOINTMENTS_ISOLATION_LABEL]: "Isolierung",
    [APPOINTMENTS_COMMENT_LABEL]: "Bemerkung",
    [APPOINTMENTS_NEXT_APPOINTMENT_LABEL]: "Nächster Termin",
    [APPOINTMENTS_SHOW_COMMENT_LABEL]: "Bemerkung anzeigen",
    [APPOINTMENTS_MULTI_TRIPS_LABEL]: "Mehrere Fahrten",
    [APPOINTMENTS_DEPARTURE_LABEL]: "Abfahrtsadresse",
    [APPOINTMENTS_DESTINATION_LABEL]: "Ankunftsadresse",
    [APPOINTMENTS_SELECT_ADDRESS_LABEL]: "Vorhandene Adresse auswählen",
    [APPOINTMENTS_PHONE_LABEL]: "Zus. Telefon-Nr.",

    [APPOINTMENTS_DESINFECTION_LABEL]: "Desinfektion",
    [APPOINTMENTS_PERFUSOR_LABEL]: "Spritzenpumpe",
    [APPOINTMENTS_CORONA_LABEL]: "Corona",
    [APPOINTMENTS_INFUSION_LABEL]: "Infusion",

    [APPOINTMENTS_DEPARTURE_TIME_LABEL]: "Abfahrtstermin",
    [APPOINTMENTS_DESTINATION_TIME_LABEL]: "Ankunftstermin",
    [APPOINTMENTS_RETURN_TIME_LABEL]: "Rückfahrtstermin",

    [APPOINTMENTS_DRIVE_VEHICLE]: "Fahrzeug Hinfahrt",
    [APPOINTMENTS_RETURN_VEHICLE]: "Fahrzeug Rückfahrt",

    [BILLS_FILTER_TITLE_LABEL]: "Rechnungen suchen",
    [BILLS_FILTER_CUSTOMER_LABEL]: "Rechnungspatient",
    [BILLS_FILTER_ADDRESS_LABEL]: "Rechnungsadresse",
    [BILLS_FILTER_KEYWORDS]: "Schlüsselwörter",
    [BILLS_FILTER_BILL_NUMBER]: "Rechnungsnummer",
    [BILLS_FILTER_STATUS]: "Status",
    [BILLS_FILTER_EXCLUDE]: "Ohne Kliniken",
    [BILLS_FILTER_INCLUDE]: "Inkl. Kliniken",
    [BILLS_FILTER_AMOUNT_MIN]: "Preis min.",
    [BILLS_FILTER_AMOUNT_MAX]: "Preis max.",
    [BILLS_FILTER_INCLUDE_INACTIVE]: "Mit gelöschten Rechnungen",
    [BILLS_FILTER_EXCLUDE_INACTIVE]: "Ohne gelöschte Rechnungen",
    [BILLS_FILTER_FROM_DATE]: "Von",
    [BILLS_FILTER_TO_DATE]: "Bis",
    [BILLS_FILTER_INCLUDE_UNPAID]: "Alle Rechnungen",
    [BILLS_FILTER_EXCLUDE_UNPAID]: "Offene Rechnungen",

    [BILLS_TOTAL_AMOUNT_LABEL]: "Gesamtbetrag: {value} €",
    [BILLS_TOTAL_PAID_LABEL]: "Gesamt offen: {value} €",

    [BILLS_NUMBER_LABEL]: "Nummer",
    [BILLS_CUSTOMER_LABEL]: "Patient",
    [BILLS_AMOUNT_DUE_LABEL]: "Betrag",
    [BILLS_AMOUNT_PAID_LABEL]: "Offen",
    [BILLS_AMOUNT_REMAINING_LABEL]: "Offen",
    [BILLS_HOSPITAL_WARNING_LABEL]: "Krankenhaus-Rechnung",

    /* BILLS NEW INPUT LABELS*/
    [BILLS_HOSPITAL_ORDER_NUMBER_LABEL]: "Krankenhaus Bestell Nr.",

    [BILLS_HOSPITAL_NB_ROUTES_LABEL]: "Anzahl Fahrten",
    [BILLS_HOSPITAL_NB_ROUTES_SINGLE_LABEL]: "einzelne Fahrt",
    [BILLS_HOSPITAL_NB_ROUTES_TWO_WAY_LABEL]: "hin- und Rückfahrt",
    [BILLS_HOSPITAL_NB_ROUTES_MULTIPLE_LABEL]: "mehrere Fahrten",

    [BILLS_HOSPITAL_POSITION_LABEL]: "Position",
    [BILLS_HOSPITAL_POSITION_AMBULANCE_LABEL]: "Ambulanz",
    [BILLS_HOSPITAL_POSITION_CADDY_LABEL]: "Caddy",
    [BILLS_HOSPITAL_POSITION_TAXI_LABEL]: "Taxi",

    [BILLS_TITLE_LABEL]: "Rechnung bearbeiten",
    [BILLS_SELECT_BILL_LABEL]: "Bitte wählen Sie eine Rechnung aus",
    [BILLS_BILL_NUMBER_LABEL]: "Rechnungsnummer",
    [BILLS_PASSENGER_LABEL]: "Passagier",
    [BILLS_DEPARTURE_LABEL]: "Abfahrtsadresse",
    [BILLS_DESTINATION_LABEL]: "Ankunftsadresse",
    [BILLS_DEPARTURE_TEXT_LABEL]: "Abfahrtsadresse (Freitext)",
    [BILLS_DESTINATION_TEXT_LABEL]: "Ankunftsadresse (Freitext)",
    [BILLS_COMMENT_INTERNAL_LABEL]: "Bemerkung Intern",
    [BILLS_COMMENT_EXTERNAL_LABEL]: "Bemerkung Rechnung",
    [BILLS_TRANSPORT_TICKET_LABEL]: "Transportschein",
    [BILLS_TRANSPORT_DATE_LABEL]: "Transportdatum",
    [BILLS_ORDER_DATE_LABEL]: "Rechnungsdatum",
    [BILLS_FIRST_REMINDER_DATE_LABEL]: "1. Mahnung",
    [BILLS_SECOND_REMINDER_DATE_LABEL]: "2. Mahnung",
    [BILLS_DAS_DATE_LABEL]: "Datum Sylvain",
    [BILLS_FULL_CHARGE_LABEL]: "100% vom Patienten zu zahlen",
    [BILLS_PARTIAL_CHARGE_LABEL]: "30% Patient | 70% Krankenkasse",
    [BILLS_CREATE_BUTTON_LABEL]: "Neue Rechnung anlegen",
    [BILLS_SAVE_BUTTON_LABEL]: "Änderungen speichern",

    [BILLS_POSITIONS_TITLE]: "Produkt",
    [BILLS_POSITIONS_DESCRIPTION_LABEL]: "Beschreibung",
    [BILLS_POSITIONS_QUANTITY_LABEL]: "Anzahl",
    [BILLS_POSITIONS_AMOUNT_LABEL]: "Betrag",
    [BILLS_POSITIONS_TOTAL_AMOUNT_LABEL]: "Gesamt",
    [BILLS_POSITIONS_PRESET_LABEL]: "Vorlage wählen",
    [BILLS_ADD_POSITION_BUTTON_LABEL]: "Neue Position anlegen",
    [BILLS_ADD_POSITION_DIALOG_TITLE]: "Position hinzufügen",
    [BILLS_EDIT_POSITION_BUTTON_LABEL]: "Änderungen übernehmen",
    [BILLS_EDIT_POSITION_DIALOG_TITLE]: "Position bearbeiten",
    [BILLS_REMOVE_POSITION_DIALOG_TITLE]: "Position entfernen",
    [BILLS_DAS_LABEL]: "SYLVAIN!!",
    [BILLS_IS_OVERWEIGHT_LABEL]: "Übergewichtig",
    [BILLS_WEIGHT_LABEL]: "Gewicht",
    [BILLS_HAS_STAIRS_LABEL]: "Treppen",
    [BILLS_NUMBER_STAIRS_LABEL]: "Anz. Treppen",
    [BILLS_WHEELCHAIR_LABEL]: "Eigener Rollstuhl",
    [BILLS_ELEVATOR_LABEL]: "Fahrstuhl",

    [BILLS_REMINDER_UPDATE_LABEL]: "Aktualisierung der Mahnung",

    [BILLS_GENERATING_MESSAGE]: "Ihre Dokumente werden erstellt, bitte warten.",

    [BILLS_ALL_REMINDER_LABEL]: "Mahnung für gefilterte Rechnungen erstellen",
    [BILLS_ALL_EXPORT_LABEL]: "Export für gefilterte Rechnungen erstellen",
    [BILLS_EXPORT_TO_PEPPOL]: "Export zu Peppol",
    [BILLS_MULTI_REPORT_LABEL]: "Rechnungen für ausg. Einträge runterladen",
    [BILLS_MULTI_STATUS_LABEL]: "Status für ausg. Einträge ändern",
    [BILLS_MULTI_REMINDER_LABEL]: "Mahnungen für ausg. Einträge runterladen",
    [BILLS_MULTI_BILLING_LABEL]: "Bezahlungen für ausg. Einträge erstellen",
    [BILLS_MULTI_EXPORT_LABEL]: "Ausg. Einträge exportieren",
    [BILLS_MULTI_BILLING_CREATE_LABEL]: "Anlegen",
    [BILLS_MULTI_BILLING_UPDATE_STATE_LABEL]: "Status aktualisieren",
    [BILLS_MULTI_BILLING_FILL_LAST]: "Teilzahlung zulassen",
    [BILLS_MULTI_FOOTER_LABEL]: "Offener Betrag:",
    [BILLS_MULTI_EXPORT_SORT_TITLE]: "Sortierung",
    [BILLS_MULTI_EXPORT_SORT_SUMMARY]:
      "Bitte wählen Sie aus wie die Exportliste sortiert werden soll.",

    [BILLS_PEPPOL_EXPORT_LABEL]: "Exportieren",
    [BILLS_PEPPOL_RESEND_LABEL]: "Neu versenden",
    [BILLS_PEPPOL_ADDRESSES_LABEL]: "Peppol Adressen",
    [BILLS_PEPPOL_SELECT_ADDRESSES_LABEL]: "Peppol Adressen auswählen",
    [BILLS_PEPPOL_EXPORT_SUCCESS]:
      "Der Peppol-Export wurde erfolgreich abgeschlossen.",
    [BILLS_PEPPOL_EXPORT_FAIL]: "Der Peppol-Export ist fehlgeschlagen.",
    [BILLS_PEPPOL_NO_INVOICES_FOUND]:
      "Es wurden keine Rechnungen gefunden, die über Peppol gesendet werden können.",

    [BILLS_MULTI_STATUS_SUCCESS]:
      "Der Status der Rechnungen wurde erfolgreich aktualisiert.",

    [BILLS_MULTI_EXPORT_SORT_ORDER_BILL_NUMBER_ASC]:
      "Nach Rechnungsnr. aufsteigend",
    [BILLS_MULTI_EXPORT_SORT_ORDER_BILL_NUMBER_DESC]:
      "Nach Rechnungsnr. absteigend",
    [BILLS_MULTI_EXPORT_SORT_ORDER_CUSTOMER_ASC]: "Nach Patientennamen (A-Z)",

    [BILLS_FILES_TITLE]: "Dateien",
    [BILLS_FILES_CHOOSE_LABEL]: "Ausw.",
    [BILLS_FILES_UPLOAD_LABEL]: "Hochl.",
    [BILLS_FILES_REPORT_LABEL]: "Rechnung",
    [BILLS_FILES_WARNING_LABEL]: "Mahnung",
    [BILLS_FILES_MERGE_LABEL]: "Zusammengefasste Dokumente herunterladen",
    [BILLS_FILES_SEND_LABEL]: "Email",
    [BILLS_FILES_EMPTY_LABEL]: "Datei zum Hochladen hereinziehen",
    [BILLS_FILES_BILL_PDF_TITLE]: "Rechnungs-PDF",
    [BILLS_FILES_ADDITIONAL_FILES_TITLE]: "Zusätzliche Dateien",

    [BILLS_FILES_UPLOAD_SUCCESS]: "Dateien wurden erfolgreich hochgeladen.",
    [BILLS_FILES_REMOVE_SUCCESS]: "Die Datei wurde erfolgreich gelöscht.",

    [BILLS_FILES_ERROR_UPLOAD]: "Fehler beim Hochladen der Dateien.",
    [BILLS_FILES_ERROR_SIZE_SUMMARY]: "Ungültige Dateigröße",
    [BILLS_FILES_ERROR_SIZE_DETAIL]: "Maximale Dateigröße: {fileSize}",

    [BILLS_VALIDATION_ERROR_BILL_NUMBER]: "Ungültige Rechnungsnummer.",
    [BILLS_VALIDATION_ERROR_STATUS]: "Ungültiger Rechnungs-Status.",
    [BILLS_VALIDATION_ERROR_TRANSPORT_TYPE]: "Kein Transport-Typ ausgewählt.",
    [BILLS_VALIDATION_ERROR_BILLING_DATE]: "Ungültiges Rechnungsdatum.",
    [BILLS_VALIDATION_ERROR_CUSTOMER]: "Kein Patient ausgewählt.",
    [BILLS_VALIDATION_ERROR_ADDRESS]: "Keine Rechnungsadresse ausgewählt.",
    [BILLS_VALIDATION_ERROR_FROM_TEXT]: "Freitext für Abfahrtsadresse fehlt",
    [BILLS_VALIDATION_ERROR_TO_TEXT]: "Freitext für Ankunftsadresse fehlt",
    [BILLS_VALIDATION_WARNING_NO_FILE]:
      "An dieser Rechnung wurde kein PDF angehangen.",

    [BILLS_WARNING_UPLOAD_DISABLED]:
      "Datei-Uploads für neue Einträge nicht verfügbar. Speichern Sie den neuen Eintrag zuerst bevor Sie Dateien hochzuladen",

    [PAYMENTS_FILTER_TITLE_LABEL]: "Bezahlungen suchen",
    [PAYMENTS_SELECT_PAYMENT_LABEL]: "Bitte wählen Sie eine Bezahlung aus",
    [PAYMENTS_TITLE_LABEL]: "Bezahlung bearbeiten",
    [PAYMENTS_FILTER_MIN_AMOUNT_LABEL]: "Mindestbetrag",
    [PAYMENTS_FILTER_MAX_AMOUNT_LABEL]: "Maximalbetrag",
    [PAYMENTS_FILTER_FROM_DATE_LABEL]: "Von (Rechnungen)",
    [PAYMENTS_FILTER_TO_DATE_LABEL]: "Bis (Rechnungen)",
    [PAYMENTS_FILTER_CUSTOMER_LABEL]: "Patient",
    [PAYMENTS_FILTER_BILL_LABEL]: "Rechnung",
    [PAYMENTS_FILTER_ADDRESS_KEY_LABEL]: "Adressen-Schlüsselw.",
    [PAYMENTS_FILTER_PAYMENT_KEY_LABEL]: "Bezahlungs-Schlüsselw.",
    [PAYMENTS_FILTER_CUSTOMER_KEY_LABEL]: "Patienten-Schlüsselw.",
    [PAYMENTS_FILTER_BILL_KEY_LABEL]: "Rechnungs-Schlüsselw.",
    [PAYMENTS_FILTER_PAYMENT_FROM_DATE_LABEL]: "Von (Bezahlungen)",
    [PAYMENTS_FILTER_PAYMENT_TO_DATE_LABEL]: "Bis (Bezahlungen)",

    [PAYMENTS_CUSTOMER_LABEL]: "Patient",
    [PAYMENTS_BILLING_ADDRESS_LABEL]: "Rechnungsadresse",
    [PAYMENTS_BILL_NUMBER_LABEL]: "Rechnungs-Nr.",
    [PAYMENTS_DATE_LABEL]: "Datum",
    [PAYMENTS_AMOUNT_LABEL]: "Betrag",
    [PAYMENTS_BANK_LABEL]: "Bank",

    [PAYMENT_SAVE_SUCCESS_MESSAGE]:
      "Die Bezahlung wurde erfolgreich gespeichert.",
    [PAYMENT_DELETE_SUCCESS_MESSAGE]:
      "Die Bezahlung wurde erfolgreich gelöscht.",
    [PAYMENT_DELETE_FAILURE_MESSAGE]:
      "Die Bezahlung konnte nicht gelöscht werden.",

    [PAYMENTS_SAVE_BUTTON_LABEL]: "Bezahlung aktualisieren",
    [PAYMENTS_CREATE_BUTTON_LABEL]: "Neue Bezahlung anlegen",

    [PAYMENTS_VALIDATION_ERROR_BANK]: "Keine Bank angegeben.",
    [PAYMENTS_VALIDATION_ERROR_CUSTOMER]: "Kein Patient angegeben.",
    [PAYMENTS_VALIDATION_ERROR_BILL]: "Keine Rechnung angegeben.",
    [PAYMENTS_VALIDATION_ERROR_INVALID_AMOUNT]: "Ungültiger Betrag.",
    [PAYMENTS_VALIDATION_ERROR_BILLING_DATE]: "Ungültiges Rechnungsdatum.",

    [PAYMENTS_BILL_TITLE_LABEL]: "Rechnungsdaten",
    [PAYMENTS_BILL_NOT_SELECTED]: "Keine Rechnung ausgewählt",

    [PEPPOL_SEND_BILL_FAILURE]:
      "Die Übertragung der Rechnung an Peppol schlug fehl.",
    [PEPPOL_SEND_BILL_SUCCESS]:
      "Rechnung wurde erfolgreich an Peppol übertragen.",
    [PEPPOL_SET_RESEND_SUCCESS]: "Rechnung zur erneuten Sendung freigegeben.",

    [PRODUCTS_NAME_LABEL]: "Bezeichnung",
    [PRODUCTS_PRICE_LABEL]: "Preis",
    [PRODUCTS_QUANTITY_LABEL]: "Basis-Anzahl",
    [PRODUCTS_NO_SELECTION_TITLE]: "Bitte wählen Sie ein Produkt aus.",
    [PRODUCTS_NEW_ENTRY_TITLE]: "Neues Produkt",
    [PRODUCTS_EDIT_ENTRY_TITLE]: "Produkt {name} bearbeiten",
    [PRODUCTS_SAVE_BUTTON_LABEL]: "Produkt aktualisieren",
    [PRODUCTS_CREATE_BUTTON_LABEL]: "Produkt anlegen",

    [PRODUCTS_VALIDATION_NAME_REQUIRED]: "Keine Bezeichnung angegeben.",

    [VEHICLES_NEW_ENTRY_TITLE]: "Neues Fahrzeug",
    [VEHICLES_EDIT_ENTRY_TITLE]: "Fahrzeug {plate} bearbeiten",
    [VEHICLES_NO_SELECTION_LABEL]: "Bitte wählen Sie ein Fahrzeug aus.",
    [VEHICLES_TYPE_LABEL]: "Typ",
    [VEHICLES_AVAILABLE_LABEL]: "Verfügbar",
    [VEHICLES_YEAR_LABEL]: "Jahr",
    [VEHICLES_KM_LABEL]: "Kilometerstand",
    [VEHICLES_LICENSE_PLATE_LABEL]: "Nummernschild",
    [VEHICLES_PHONE_LABEL]: "Telefon",
    [VEHICLES_COMMENT_LABEL]: "Bemerkung",
    [VEHICLES_WINFLEET_NAME_LABEL]: "Winfleet Name",

    [VEHICLES_SAVE_BUTTON_LABEL]: "Fahrzeugdaten speichern",
    [VEHICLES_CREATE_BUTTON_LABEL]: "Neues Fahrzeug anlegen",

    [VEHICLES_VALIDATION_PLATE_REQUIRED]: "Kein Nummernschild angegeben.",
    [VEHICLES_VALIDATION_PLATE_INVALID]: `Das Nummerschild muss mindestens ${VALIDATION_RULES.LICENSE_PLATE_MIN_LENGTH} Zeichen haben`,
    [VEHICLES_VALIDATION_TYPE_REQUIRED]: "Kein Fahrzeugtyp angegeben.",

    [USERS_TITLE_LABEL]: "Daten von {name} bearbeiten",
    [USERS_SELECT_USER_LABEL]: "Wählen Sie einen Benutzer aus.",
    [USERS_FIRST_NAME_LABEL]: "Vorname",
    [USERS_LAST_NAME_LABEL]: "Nachname",
    [USERS_NICK_NAME_LABEL]: "Spitzname",
    [USERS_HOME_PHONE_LABEL]: "Telefon (Privat)",
    [USERS_WORK_PHONE_LABEL]: "Telefon (Arbeit)",
    [USERS_MOBILE_PHONE_LABEL]: "Telefon (Mobil)",
    [USERS_HIRE_DATE_LABEL]: "Einstellungsdatum",
    [USERS_ALLERGIES_LABEL]: "Allergien",
    [USERS_DISEASES_LABEL]: "Krankheiten",
    [USERS_BLOODTYPE_LABEL]: "Blutgruppe",
    [USERS_TIMERECORDING_LABEL]: "Zeiterfassung",
    [USERS_WEEKHOURS_LABEL]: "Stunden pro Woche",

    [USERS_VACATION_BUDGET_START_LABEL]: "Budget Start",
    [USERS_VACATION_BUDGET_YEAR_LABEL]: "Jahr",
    [USERS_VACATION_BUDGET_LABEL]: "Budget",
    [USERS_VACATION_BUDGET_COMMENT_LABEL]: "Bemerkung",
    [USERS_VACATION_BUDGET_SAVE_SUCCESS]:
      "Urlaubsbudget wurde erfolgreich gespeichert.",
    [USERS_BUDGET_DELETE_TITLE]: "Budget löschen",
    [USERS_BUDGET_DELETE_MESSAGE]:
      "Sind Sie sicher dass Sie das Budget vom Jahr {year} löschen wollen?",
    [USERS_BUDGET_DELETE_FAIL]: "Das Budget konnte nicht gelöscht werden",
    [USERS_BUDGET_DELETE_SUCCESS]: "Das Budget wurde erfolgreich gelöscht.",

    [USERS_VACATION_BUDGET_UPDATE_BUTTON_LABEL]: "Budget aktualisieren",
    [USERS_VACATION_BUDGET_CREATE_BUTTON_LABEL]: "Budget hinzufügen",
    [USERS_VACATION_OVER_BUDGET_WARNING]:
      "Mit dieser Anfrage wird Ihr Budget um {days} Tag(e) überschritten. Wollen Sie die Anfrage dennoch abschicken?",
    [USERS_VACATION_OVER_BUDGET_ADMIN_WARNING]:
      "Durch die Genehmigung wird das Budget des Benutzers um {days} Tag(e) überschritten. Wollen Sie die Anfrage dennoch abschicken?",

    [USERS_ADD_EMERGENCY_PERSON_LABEL]: "Notfall Person hinzufügen",
    [USERS_DELETE_EMERGENCY_PERSON_LABEL]: "Notfall Person löschen",
    [USERS_EDIT_EMERGENCY_PERSON_LABEL]: "Notfall Person bearbeiten",
    [USERS_DELETE_EMERGENCY_PERSON_MESSAGE]:
      "Sind Sie sicher dass Sie die Notfall Person {fullname} löschen wollen?",

    [USERS_EMERGENCY_PERSON_ADD_SUCCESS]:
      "Notfall Person erfolgreich hinzugefügt",
    [USERS_EMERGENCY_PERSON_ADD_FAIL]:
      "Notfall Person konnte nicht hinzugefügt werden",
    [USERS_EMERGENCY_PERSON_EDIT_FAIL]:
      "Notfall Person konnte nicht bearbeitet werden",
    [USERS_EMERGENCY_PERSON_EDIT_SUCCESS]:
      "Notfall Person erfolgreich hinzugefügt",
    [USERS_EMERGENCY_PERSON_DELETE_FAIL]:
      "Notfall Person konnte nicht gelöscht werden",
    [USERS_EMERGENCY_PERSON_DELETE_SUCCESS]:
      "Notfall Person erfolgreich gelöscht",

    [USERS_INFO_REPORT_BUTTON_LABEL]: "Info",

    [DIALOG_WARNING_TITLE]: "Achtung",
    [DIALOG_CONTINUE_OFFLINE_BUTTON_LABEL]: "Offline fortfahren",
    [DIALOG_SAVE_BUTTON_LABEL]: "Speichern",
    [DIALOG_CANCEL_BUTTON_LABEL]: "Abbrechen",
    [DIALOG_CANCEL_SHORT_BUTTON_LABEL]: "Abbr",
    [DIALOG_CONFIRM_BUTTON_LABEL]: "Ja",
    [DIALOG_DENY_BUTTON_LABEL]: "Nein",
    [DIALOG_SEARCH_BUTTON_LABEL]: "Suchen",
    [DIALOG_CLEAR_FILTER_LABEL]: "Filter löschen",
    [DIALOG_CONTINUE_BUTTON_LABEL]: "Weiter",
    [DIALOG_BACK_BUTTON_LABEL]: "Zurück",
    [DIALOG_DOWNLOAD_BUTTON_LABEL]: "Herunterladen",
    [DIALOG_CLOSE_BUTTON_LABEL]: "Schließen",

    [GERMAN.key]: "Deutsch",
    [FRENCH.key]: "Französisch",

    [ERROR_MISSING_VALUES]: "Alle markierten Felder müssen ausgefüllt sein!",
    [ERROR_DUPLICATE]: "Der Eintrag {value} existiert bereits.",
    [ERROR_DATA_FETCH]: "Beim Empfangen der Daten ist ein Fehler aufgetreten.",
    [ERROR_NO_DATA]: "Keine Daten vorhanden.",
    [ERROR_RENDER]: "Komponente konnte nicht geladen werden.",
    [ERROR_SERVER_NOT_AVAILABLE]:
      "Der Server ist im Moment nicht verfügbar. Möchten Sie offline fortfahren?",
    [ERROR]: "Fehler",
    [ERROR_DATA_SAVE]: "Beim Speichern der Daten ist ein Fehler aufgetreten.",
    [ERROR_TOKEN_EXPIRED]:
      "Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich erneut ein.",

    [WARNING_CONFIRM_REMOVE]:
      "Soll der Eintrag {value} wirkich entfernt werden?",
    [WARNING_UNSAVED_CHANGES]:
      "Sämtliche ungespeicherten Änderungen verloren.\nMöchten Sie trotzdem fortfahren?",
    [WARNING_VALIDATION_FAILED]:
      "Die folgenden Validierungen sind fehlgeschlagen:",
    [WARNING_OFFLINE_MODE]: "Die Anwendung ist Offline-Modus!",

    [ROLE_OFFLINE]: "Offline",
    [ROLE_ADMIN]: "Administrator",
    [ROLE_INVOICE]: "Rechnungen",
    [ROLE_PLANNING]: "Terminplanung",
    [ROLE_USER]: "Benutzer",
    [ROLE_DRIVER]: "Fahrer",
    [ROLE_FIDU]: "Treuhänder",
    [ROLE_AUTHOR]: "Shoutbox-Autor",

    [GENDER_NONE]: "Kein Geschlecht ausgewählt",
    [GENDER_FEMALE]: "Weiblich",
    [GENDER_MALE]: "Männlich",
    [GENDER_UNDEFINED]: "Nicht festgelegt",

    [TITLES_CHILD]: "Kind",
    [TITLES_DOCTOR]: "Doktor",
    [TITLES_GENTLEMEN]: "Herren",
    [TITLES_MASTER]: "Herr & Frau",
    [TITLES_MISS]: "Frau",
    [TITLES_MISSES]: "Fräulein",
    [TITLES_MISTER]: "Herr",
    [TITLES_MYLORD]: "Mein Herr",
    [TITLES_NO]: "Kein Titel",
    [TITLES_NONE]: "Kein Titel ausgewählt",
    [TITLES_PROFESSOR]: "Professor",
    [TITLES_SENIOR]: "Schwester",

    [TRANSPORT_TYPE_NONE]: "Fahrzeug-Typ ausw.",
    [TRANSPORT_TYPE_AMBULANCE]: "Ambulanz",
    [TRANSPORT_TYPE_CADDY]: "Caddy",
    [TRANSPORT_TYPE_TAXI]: "Taxi",
    [TRANSPORT_TYPE_EMPTY]: "Leer",
    [TRANSPORT_TYPE_BUS]: "Bus",
    [TRANSPORT_TYPE_VSL]: "VSL",

    [ADDRESS_TYPE_NONE]: "Kein Addresstyp ausgewählt",
    [ADDRESS_TYPE_INVOICE]: "Rechnungsadresse",
    [ADDRESS_TYPE_BASE]: "Basisadresse",
    [ADDRESS_TYPE_ADDITIONAL]: "Zusatzadresse",

    [BILL_STATUS_NEW]: "Neue Rechnung",
    [BILL_STATUS_SENT]: "Rechnung geschickt",
    [BILL_STATUS_PARTIALLY_PAID]: "Teilzahlung",
    [BILL_STATUS_REMINDER_1]: "1. Mahnung",
    [BILL_STATUS_REMINDER_2]: "2. Mahnung",
    [BILL_STATUS_DAS]: "SYLVAIN!!",
    [BILL_STATUS_OVERPAID]: "Zu viel gezahlt",
    [BILL_STATUS_CLOSED]: "Geschlossen",
    [BILL_STATUS_DELIVERY_NOTE]: "Lieferschein",

    [APPOINTMENT_STATUS_NONE]: "Kein Termin-Status",
    [APPOINTMENT_STATUS_RETURN]: "Rückfahrt",
    [APPOINTMENT_STATUS_OUTWARD]: "Hinfahrt",
    [APPOINTMENT_STATUS_EXECUTED]: "Abgeschlossen",
    [APPOINTMENT_STATUS_WAITING]: "Wartet",
    [APPOINTMENT_STATUS_INVOICED]: "Verrechnet",
    [APPOINTMENT_STATUS_CANCELLED]: "Storniert",

    [APPOINTMENT_STATUS_PENDING]: "Ausstehend",
    [APPOINTMENT_STATUS_BILLED]: "Verrechnet",

    [APPOINTMENT_UNRELEASED_ONLY]: "Nicht freigegebene Rechnungen",
    [APPOINTMENT_ALL_BILLS]: "Alle Rechnungen",

    [APPOINTMENT_CANCEL_SELECTED_LABEL]: "Ausge. Termine stornieren",
    [APPOINTMENT_CANCEL_ERROR]: "Termine konnte nicht storniert werden",
    [APPOINTMENT_CANCEL_SUCCESS]: "Termine erfolgreich storniert",
    [APPOINTMENT_CANCEL_NO_SELECTED]: "Keine Termine ausgewählt",

    [SETTINGS_ACCOUNT_NUMBER_LABEL]: "Kontonummer",
    [SETTINGS_ACCOUNT_BILL_TEXT_LABEL]: "Zahlungsziel",
    [SETTINGS_SAVE_BUTTON_LABEL]: "Betriebseinstellungen speichern",
    [SETTINGS_VALIDATION_ACCOUNT_NUMBER_REQUIRED]:
      "Keine Kontonummer angegeben.",
    [SETTINGS_UPDATE_SUCCESS_MESSAGE]:
      "Die Betriebseinstellungen wurden erfolgreich gespeichert.",

    [SHIFTS_CREATE_BUTTON_LABEL]: "Neue Schicht erstellen",
    [SHIFTS_EDIT_BUTTON_LABEL]: "Schicht bearbeiten",
    [SHIFTS_DELETE_BUTTON_LABEL]: "Schicht löschen",
    [SHIFTS_EVENT_SUMMARY_LABEL]: "{members} Mitarbeiter",
    [SHIFTS_ERROR_NO_SHIFT]: "Schichten konnten nicht geladen werden",
    [SHIFTS_NO_SHIFT_LABEL]: "Keine Schicht",
    [SHIFTS_NO_MEMBERS_LABEL]: "Kein Mitarbeiter zugewiesen",
    [SHIFTS_DUTY_LABEL]: "Bereitschaftsdienst",
    [SHIFTS_DELETE_SUCCESS]: "Die Schicht wurde erfolgreich gelöscht.",
    [SHIFTS_UPDATE_SUCCESS]: "Die Schicht wurde erfolgreich gespeichert.",
    [SHIFTS_UPDATE_FAIL]: "Die Schicht konnte nicht aktualisiert werden.",
    [SHIFTS_MEMBERS_PLACEHOLDER]: "Mitarbeiter",

    [ON_CALLS_REGISTER_BUTTON_LABEL]: "Angemeldet",
    [ON_CALLS_UNREGISTER_BUTTON_LABEL]: "Nicht gemeldet",
    [ON_CALLS_EDIT_BUTTON_LABEL]: "Speichern",
    [ON_CALLS_NUMBER_SLOTS_LABEL]: "Anzahl Plätze",
    [ON_CALLS_SLOTS_OCCUPIED_LABEL]: "{members} von {limit} bestätigt",
    [ON_CALLS_SHIFT_FULL_LABEL]: "Bereitschaftsdienst vollständig besetzt",
    [ON_CALLS_SHIFT_EMPTY_LABEL]:
      "Kein Mitarbeiter in diesem Bereitschaftsdienst",
    [ON_CALLS_DRIVERS_TITLE]: "Angemeldete Mitarbeiter",
    [ON_CALLS_NEW_SHIFT_TITLE]: "Neuen Bereitschaftsdienst anlegen",
    [ON_CALLS_LOW_SLOTS_WARNING]:
      "Für diesen Bereitschaftsdienst hat es weniger Plätze als angemeldete Mitarbeiter.",
    [ON_CALLS_TYPES_WARNING]: "Titel nicht geladen",
    [ON_CALLS_CONFIRMED]: "Bestätigt",

    [VACATIONS_REPORT_CURRENT_LABEL]: "Bericht {year}",
    [VACATIONS_MY_VACATIONS_LABEL]: "Mein Urlaub für {year}",
    [VACATIONS_START_LABEL]: "Urlaubsanfang",
    [VACATIONS_END_LABEL]: "Urlaubsende",
    [VACATIONS_COMMENT_USER_LABEL]: "Bemerkung Benutzer",
    [VACATIONS_COMMENT_ADMIN_LABEL]: "Antwort Verwaltung",
    [VACATIONS_STATUS_LABEL]: "Status",
    [VACATIONS_CREATE_BUTTON_LABEL]: "Urlaub eintragen",
    [VACATIONS_CREATE_ADMIN_BUTTON_LABEL]: "Urlaub/Krankheit eintragen",
    [VACATIONS_DELETE_BUTTON_LABEL]: "Urlaubsanfrage löschen",
    [VACATIONS_UPDATE_BUTTON_LABEL]: "Urlaubsanfrage aktualisieren",
    [VACATIONS_CREATE_DIALOG_TITLE]: "Neue Urlaubsanfrage",
    [VACATIONS_EDIT_DIALOG_TITLE]: "Urlaubsanfrage bearbeiten",
    [VACATIONS_NO_VACATIONS_LABEL]: "Keine Urlaubsanfragen für {year}",
    [VACATIONS_AVAILABLE_LABEL]: "Restbudget",
    [VACATIONS_HALF_DAY_LABEL]: "Halbtag",
    [VACATIONS_SICK_DAY_LABEL]: "Krank",
    [VACATIONS_SPECIAL_LABEL]: "Sonderurlaub",
    [VACATIONS_SHOW_ALL_LABEL]: "Alle Urlaube anzeigen",

    [VACATIONS_VACATION_LABEL]: "Urlaub",

    [VACATIONS_EVENT_HOURS]: "Fl. Freistellung",
    [VACATIONS_SHOW_HOURS]: "Flexible Freistellung",
    [VACATIONS_SHOW_REGULAR]: "Regulärer Urlaub",
    [VACATIONS_AMOUNT_HOURS]: "Anz. Stunden",

    [VACATION_DATE_VALIDATION_FAIL]: "Ungültiges Urlaubs-Datum.",
    [VACATION_USER_VALIDATION_FAIL]: "Ungültiger Benutzer.",
    [VACATION_HOURS_VALIDATION_FAIL]: "Anz. Stunden nicht zwischen 0 und 8.",
    [VACATION_MINUTES_VALIDATION_FAIL]: "Anz. Minuten nicht zwischen 0 und 60.",
    [VACATIONS_AMOUNT_MINUTES]: "Anz. Minuten",

    [VACATIONS_HOLIDAY_TITLE_LABEL]: "Feiertag anlegen",
    [VACATIONS_HOLIDAY_UPDATE_SUCCESS_MESSAGE]:
      "Der Feiertag wurde erfolgreich gespeichert.",
    [VACATIONS_HOLIDAY_UPDATE_FAILURE_MESSAGE]:
      "Der Feiertag konnte nicht gespeichert werden.",
    [VACATIONS_HOLIDAY_DELETE_SUCCESS_MESSAGE]:
      "Der Feiertag wurde erfolgreich gelöscht",
    [VACATIONS_HOLIDAY_DELETE_FAILURE_MESSAGE]:
      "Der Feiertag konnte nicht gelöscht werden.",
    [VACATIONS_HOLIDAY_LABEL]: "Feiertag",
    [VACATION_SAVE_HOLIDAY_LABEL]: "Feiertag speichern",
    [VACATIONS_HOLIDAY_DELETE_WARNING]:
      "Soll der Feiertag {holiday} wirklich gelöscht werden?",

    [VACATIONS_SET_OLD]: "Genehmigte Urlaube aktualisieren",
    [VACATIONS_SET_OLD_CONFIRM]:
      "Sollen alle genehmigten Urlaube vor dem ausgewählten Tag als genommen markiert werden?",
    [VACATIONS_SET_OLD_FAIL]: "Keine Urlaube wurden bearbeitet.",

    [VACATION_UPDATE_SUCCESS_MESSAGE]:
      "Die Urlaubsanfrage wurde erfolgreich aktualisiert.",
    [VACATION_UPDATE_FAILURE_MESSAGE]:
      "Die Urlaubsanfrage konnte nicht aktualisiert werden",
    [VACATION_DELETE_SUCCESS_MESSAGE]:
      "Die Urlaubsanfrage wurde erfolgreich gelöscht.",

    [REGULARS_START]: "Start",
    [REGULARS_END]: "Zentrum",
    [REGULARS_DATES]: "Datum",
    [REGULARS_ID]: "ID",
    [REGULARS_DELETE_CONFIRM]:
      "Sollen die Stammpatientendaten {regularId} wirklich entfernt werden?",
    [REGULARS_DELETE_SUCCESS]:
      "Die Stammpatientendaten wurden erfolgreich gelöscht.",
    [REGULARS_DELETE_FAIL]:
      "Die Stammpatientendaten konnten nicht gelöscht werden.",

    [DRIVES_HEADER_DAY]: "Tag",
    [DRIVES_HEADER_GARAGE_START]: "Garage Start",
    [DRIVES_HEADER_GARAGE_END]: "Garage Ende",
    [DRIVES_HEADER_DATE]: "Datum",
    [DRIVES_HEADER_NAME]: "Name",
    [DRIVES_HEADER_PAUSE]: "Pause",

    [DRIVERS_HEADER_CHECKED]: "{controlled} von {total} kontrolliert",
    [DRIVERS_HEADER_NEXT]: "Nächster Fahrer",
    [DRIVERS_HEADER_BLOCKED]: "Zeiten blockieren",
    [DRIVERS_HEADER_CONTROLLED]: "Kontrolliert",

    [DRIVES_TABLE_RETURN]: "Rückfahrt",
    [DRIVES_TABLE_START_TIME]: "Uhrzeit Abfahrt",
    [DRIVES_TABLE_PATIENT]: "Patient",
    [DRIVES_TABLE_START_ADDRESS]: "Abfahrtadresse",
    [DRIVES_TABLE_DESTINATION_ADDRESS]: "Ankunftadresse",
    [DRIVES_TABLE_MEETING_TIME]: "Termin",
    [DRIVES_TABLE_VEHICLE]: "Fahrzeug",
    [DRIVES_TABLE_BUTTON_START]: "Start",

    [DRIVES_TIMES_RESPONSIVE]: "Zeiten",
    [DRIVES_ADDRESS_RESPONSIVE]: "Adressen",
    [DRIVES_TIME_START_RESPONSIVE]: "Start",
    [DRIVES_TIME_MEETING_RESPONSIVE]: "Treffen",
    [DRIVES_DEPARTURE_RESPONSIVE]: "Abfahrt",
    [DRIVES_ARRIVAL_RESPONSIVE]: "Ankunft",

    [DRIVES_REFRESH]: "Termine aktualisieren",
    [DRIVES_SAVE_TIMES]: "Zeiten speichern",

    [DRIVES_SAVE_DAILY_REPORT]: "Tag abschliessen",
    [DRIVES_PRINT_DAILY_REPORT]: "Tagesbericht drucken",
    [DRIVES_PRINT_TRANSPORT_REPORT]: "Transportbericht drucken",
    [DRIVES_PRINT_MONTHLY_REPORT]: "Monatsbericht drucken",
    [DRIVES_REUPLOAD]: "Nochmal hochladen",

    [DRIVES_NO_APPOINTMENT]: "Keine Fahrt vorhanden.",
    [DRIVES_CITY]: "Ortschaft",
    [DRIVES_PAPER_WAIT]: "Papiere",
    [DRIVES_MINUTES]: "min.",
    [DRIVES_HOURS_DEPARTURE]: "Abfahrt",
    [DRIVES_HOURS_ARRIVAL]: "Ankunft",
    [DRIVES_ADMISSION]: "Anm./Abgabe",
    [DRIVES_KILOMETERS]: "km",

    [DRIVES_DETAILS_TITLE]: "Fahrtdetails",
    [DRIVES_TRANSPORT_TITLE]: "Transport",
    [DRIVES_INFO_TITLE]: "Informationen",

    [DRIVES_SIGNATURE_HEADER]: "Unterschrift Patient",
    [DRIVES_SIGNATURE_CLEAR]: "Löschen",
    [DRIVES_SIGNATURE_SAVE]: "Speichern & Hochladen",

    [DRIVES_WS_TITLE]: "Termindaten wurden aktualisiert",
    [DRIVES_WS_DELETE]: "Terminstatus geändert oder gelöscht.",
    [DRIVES_WS_UPDATE]: "Der folgende Termin wurde aktualisiert:",

    [DRIVES_DAILY_SAVE_SUCCESS]:
      "Das Fahrtenbuch von {name} für den {date} wurde aktualisiert.",
    [DRIVES_DAILY_SAVE_FAIL]:
      "Das Fahrtenbuch konnte nicht aktualisiert werden.",
    [DRIVES_DAILY_ADDITIONAL_SAVE_SUCCESS]:
      "Ein neuer Eintrag wurde für morgen erstellt!",
    [DRIVES_DAILY_ADDITIONAL_SAVE_FAIL]:
      "Ein neuer Eintrag für morgen konnte nicht erstellt werden!",
    [DRIVER_GENERATE_MONTHLY_REPORT_FAIL]:
      "Der Monatsbericht konnte nicht erstellt werden!",

    [DRIVER_GENERATE_TRANSPORT_REPORT_FAIL]:
      "Der Transportbericht konnte nicht erstellt werden!",

    [DRIVES_REPORT_VIEW]: "Tagesbericht",
    [DRIVES_WORK_VIEW]: "Fahrtenbuch",

    [MONTH_REPORT_SEARCH_NAME]: "Nach Namen suchen",

    [SHOUTBOX_NO_CONTENT]: "Kein Inhalt",
    [SHOUTBOX_NO_MESSAGE]: "Keine Nachrichten",
    [SHOUTBOX_SEARCH_PLACEHOLDER]: "Unfall A1...",
    [SHOUTBOX_NOTIFICATION_EDIT_TITLE]: "Eine Shoutbox wurde bearbeitet.",
    [SHOUTBOX_NOTIFICATION_DELETE_TITLE]: "Eine Shoutbox wurde gelöscht.",
    [SHOUTBOX_NOTIFICATION_EDIT_BODY]:
      "{sender} hat die Shoutbox mit dem Titel {title} bearbeitet.",
    [SHOUTBOX_NOTIFICATION_DELETE_BODY]:
      "{sender} hat die Shoutbox mit dem Titel {title} gelöscht.",
    [SHOUTBOX_DELETE_CONFIRM]:
      "Sind Sie sich sicher dass Sie diese Shoutbox löschen wollen?",
    [SHOUTBOX_DIALOG_TITLE_ADD]: "Neue Shoutbox anlegen",
    [SHOUTBOX_DIALOG_TITLE_EDIT]: "Shoutbox bearbeiten - {title} ",
    [SHOUTBOX_DIALOG_TITLE_DELETE]: "Shoutbox löschen - {title}",
    [SHOUTBOX_DIALOG_TITLE_CHAT]: "Chat - {title}",
    [SHOUTBOX_NOTIFICATION_EDIT_REACTION_TITLE]:
      "Du hast eine Reaktion erhalten!",
    [SHOUTBOX_NOTIFICATION_EDIT_DEFAULT_BODY]: "Eine Shoutbox wurde bearbeitet",
    [SHOUTBOX_NOTIFICATION_EDIT_REACTION_BODY]:
      "{sender} hat auf eine Shoutbox reagiert.",
    [SHOUTBOX_IMAGE_SELECT_BUTTON_LABEL]: "Bild auswählen",
    [SHOUTBOX_SORT_SEVERITY_LABEL]: "Wichtigkeit",
    [SHOUTBOX_SORT_TIME_LABEL]: "Zeit",
    [SHOUTBOX_GROUP_TT_MORE]: "und {count} weitere.",
    [SHOUTBOX_GROUP_EMPTY_MESSAGE]: "Diese Gruppe hat keine Mitglieder.",
    [SHOUTBOX_EDIT_FAIL_MESSAGE]: "Die {type} konnte nicht gespeichert werden.",
    [SHOUTBOX_DELETE_FAIL_MESSAGE]: "Die {type} konnte nicht gelöscht werden.",

    [MONTH_REPORT_NAME]: "Name",
    [MONTH_REPORT_TOTAL]: "Gesamt",

    [MONDAY_LONG]: "Montag",
    [MONDAY_SHORT]: "Mo",
    [TUESDAY_LONG]: "Dienstag",
    [TUESDAY_SHORT]: "Di",
    [WEDNESDAY_LONG]: "Mittwoch",
    [WEDNESDAY_SHORT]: "Mi",
    [THURSDAY_LONG]: "Donnerstag",
    [THURSDAY_SHORT]: "Do",
    [FRIDAY_LONG]: "Freitag",
    [FRIDAY_SHORT]: "Fr",
    [SATURDAY_LONG]: "Samstag",
    [SATURDAY_SHORT]: "Sa",
    [SUNDAY_LONG]: "Sonntag",
    [SUNDAY_SHORT]: "So",

    [JANUARY_LONG]: "Januar",
    [JANUARY_SHORT]: "Jan",
    [FEBRUARY_LONG]: "Februar",
    [FEBRUARY_SHORT]: "Feb.",
    [MARCH_LONG]: "März",
    [MARCH_SHORT]: "März",
    [APRIL_LONG]: "April",
    [APRIL_SHORT]: "Apr.",
    [MAY_LONG]: "Mai",
    [MAY_SHORT]: "Mai",
    [JUNE_LONG]: "Juni",
    [JUNE_SHORT]: "Jun.",
    [JULY_LONG]: "Juli",
    [JULY_SHORT]: "Jul.",
    [AUGUST_LONG]: "August",
    [AUGUST_SHORT]: "Aug.",
    [SEPTEMBER_LONG]: "September",
    [SEPTEMBER_SHORT]: "Sept.",
    [OCTOBER_LONG]: "Oktober",
    [OCTOBER_SHORT]: "Okt.",
    [NOVEMBER_LONG]: "November",
    [NOVEMBER_SHORT]: "Nov.",
    [DECEMBER_LONG]: "Dezember",
    [DECEMBER_SHORT]: "Dez.",

    [TODAY]: "Heute",

    [VIEWMODE_LIGHT]: "Hell",
    [VIEWMODE_DARK]: "Dunkel",

    [PURGE_BUTTON_LABEL]: "Neu laden",

    [FUZZY_ON]: "Präzise",
    [FUZZY_OFF]: "Ungefähr",

    [RESET_VALUES]: "Werte zurücksetzen",

    [ACTIVE]: "Aktiv",
    [INACTIVE]: "Inaktiv",

    [CREATED_BY]: "Erstellt von:",
    [CREATED_ON]: "Erstellt am:",

    [EDITED_BY]: "Bearbeitet von: ",
    [EDITED_ON]: "Bearbeitet am: ",

    [STARTS_WITH]: "Beginnt mit",
    [CONTAINS]: "Enthält",
    [NOT_CONTAINS]: "Enthält nicht",
    [ENDS_WITH]: "Endet mit",
    [EQUALS]: "Gleich",
    [NOT_EQUALS]: "Ungleich",
    [NO_FILTER]: "Kein Filter",
    [DRIVER_STATUS_SAVE_FAILED]:
      "Status konnte nicht gespeichert werden, bitte ein Fahrzeug und einen Status auswählen.",
    [DRIVER_STATUS_SAVE_SUCCESS]: "Status wurde erfolgreich gespeichert.",
    [DRIVER_STATUS_AVAILABLE_LABEL]: "Frei",
    [DRIVER_STATUS_IN_GARAGE_AND_AVAILABLE_LABEL]: "In der Garage und frei",
    [DRIVER_STATUS_ON_THE_ROAD_TO_PATIENT_LABEL]: "Unterwegs zum Patienten",
    [DRIVER_STATUS_WITH_PATIENT_LABEL]: "Beim Patienten",
    [DRIVER_STATUS_BREAK_LABEL]: "Pause",
    [DRIVER_STATUS_ON_THE_ROAD_WITH_PATIENT_LABEL]: "Unterwegs mit Patienten",
    [DRIVER_STATUS_CLOSING_TIME_LABEL]: "Feierabend",
    [DRIVER_STATUS_UNLOADING_LABEL]: "Im Abladen",
    [DRIVER_STATUS_SORT_LABEL]: "Sortiert nach",
    [DRIVER_STATUS_SORT_DRIVER_NAME_LABEL]: "Name",
    [DRIVER_STATUS_SORT_LICENCE_PLATE_LABEL]: "Kennzeichen",
    [DRIVER_STATUS_SORT_NEWEST_LABEL]: "Neustem",
    [DRIVER_STATUS_SORT_NO_RESULT]: "Kein Ergebnis gefunden.",
    [DRIVER_STATUS_SORT_NO_HISTORICAL_VALUES]: "Kein historischer Status",
    [HISTORICAL_APPOINTMENTS_LABEL]: "Historische Einträge",
    [HISTORICAL_APPOINTMENTS_NO_RESULT]: "Keine historische Einträge gefunden",
    [HISTORICAL_APPOINTMENTS_INFO]: "Historische Einträge für Termin N°",
  },
  [FRENCH.key]: {
    [MENU_APPOINTMENTS]: "Gestion Trajets",
    [MENU_ACCOUNT]: "Compte utilisateur",
    [MENU_USERS]: "Gestion des utilisateurs",
    [MENU_CUSTOMERS]: "Gestion des patients",
    [MENU_LOGOUT]: "Déconnecter",
    [MENU_ADDRESSES]: "Adresses",
    [MENU_BILLS]: "Factures",
    [MENU_PAYMENTS]: "Paiements",
    [MENU_BILL_MANAGEMENT]: "Facturation",
    [MENU_COMPANY_MANAGEMENT]: "Gestion",
    [MENU_APPOINTMENT_MANAGEMENT]: "Gestion des rendez-vous",
    [MENU_VACATION]: "Congés",
    [MENU_SHIFTS]: "Travail posé",
    [MENU_ON_CALL]: "Permanences",
    [MENU_SETTINGS]: "Configuration",
    [MENU_DASHBOARD]: "Tableau de bord",
    [MENU_PRODUCTS]: "Options",
    [MENU_VEHICLES]: "Véhicules",
    [MENU_EMPTY]: "Page non valide",
    [MENU_REGULARS]: "Patients réguliers",
    [MENU_HOLIDAYS]: "Jours fériés",
    [MENU_DRIVES]: "Trajets",
    [MENU_DRIVER_STATUS]: "Statuts des conducteurs",
    [MENU_APPOINTMENTS_SEARCH]: "Recherche de rendez-vous",
    [MENU_SHOUTBOX]: "Shoutbox",
    [MENU_MONTH_REPORT]: "Rapport mensuel",

    [LOGIN_BUTTON_LABEL]: "Connecter",
    [LOGIN_PASSWORD_LABEL]: "Mot de passe",
    [LOGIN_USERNAME_LABEL]: "Nom d'utilisateur",
    [LOGIN_TITLE_LABEL]: "Connection",

    [LOGIN_WARNING_FAILED]:
      "Votre nom d'utilisateur ou mot de passe est incorrect.",

    [DASHBOARD_SALES_TITLE]: "Chiffre d'affaires par mois",
    /* [DASHBOARD_BIRTHDAY_TITLE]: "Prochains anniversaires", */
    [DASHBOARD_BIRTHDAY_TITLE]: "Anniversaires",
    [DASHBOARD_OVERVIEW_TABLE_YEAR_HEADER]: "Année",
    [DASHBOARD_OVERVIEW_TABLE_MONTH_HEADER]: "Mois",
    [DASHBOARD_SALES_FILTER_TITLE]: "Filterer chifferes d'affaire",
    [DASHBOARD_SALES_FILTER_FROM_LABEL]: "De",
    [DASHBOARD_SALES_FILTER_TO_LABEL]: "Jusqu'à",
    [DASHBOARD_SALES_FILTER_INCLUDE_LABEL]:
      "Incl. cliniques & Maisons de repos",

    [EMPTY_PAGE_TITLE]: "La page n'a pas pu être chargée",
    [EMPTY_PAGE_SUMMARY]:
      "La page ne peut pas être affichée car l'URL est incorrecte ou parce que vous ne disposez pas des droits d'accès appropriés.",

    [ACCOUNT_EMAIL_LABEL]: "Adresse email",
    [ACCOUNT_LANGUAGE_LABEL]: "Langue",
    [ACCOUNT_NAME_LABEL]: "Login",
    [ACCOUNT_ROLES_LABEL]: "Rôles",
    [ACCOUNT_PASSWORD_LABEL]: "Mot de passe",
    [ACCOUNT_PASSWORD_REPEAT_LABEL]: "Répéter mot de passe",
    [ACCOUNT_TITLE_LABEL]: "Éditer compte d'utilisateur",
    [ACCOUNT_SAVE_BUTTON_LABEL]: "Sauvegarder",
    [ACCOUNT_CREATE_BUTTON_LABEL]: "Créer",
    [ACCOUNT_SAVE_SUCCESS_MESSAGE]:
      "Les données de l'utilisateur ont été mises à jour avec succès.",

    [ACCOUNT_FIRST_LOGIN_WARNING]:
      "Veuillez entrer un nouveau mot de passe pour votre compte d'utilisateur.",
    [ACCOUNT_SAVE_PASSWORD_LABEL]: "Sauver mot de passe.",
    [ACCOUNT_FIRST_LOGIN_HEADER]: "Créer mot de passe",

    [ACCOUNT_ERROR_EMAIL_INVALID]: "Adresse email non valide.",
    [ACCOUNT_ERROR_EMAIL_REQUIRED]: "Adresse email requise",
    [ACCOUNT_ERROR_NAME_LENGTH]: `Votre nom doit contenir au moins ${USERNAME_MIN_LENGTH} charactères.`,
    [ACCOUNT_ERROR_NAME_REQUIRED]: "Nom d'utilisateur requis.",
    [ACCOUNT_ERROR_PASSWORD_LENGTH]: `Votre mot de passe doit contenir au moins ${PASSWORD_MIN_LENGTH} charactères.`,
    [ACCOUNT_ERROR_PASSWORD_MATCH]:
      "Les mots de passe saisis ne doivent pas différer.",
    [ACCOUNT_ERROR_COULD_NOT_LOAD]:
      "Une erreur s'est produite lors du chargement des données du compte d'utilisateur.",

    [CUSTOMERS_FILTER_TITLE_LABEL]: "Rechercher patients",
    [CUSTOMERS_FILTER_NAME]: "Nom",
    [CUSTOMERS_FILTER_ADDRESS]: "Adresse",
    [CUSTOMERS_FILTER_PHONE]: "Téléphone",
    [CUSTOMERS_FILTER_EMAIL]: "Email",
    [CUSTOMERS_FILTER_INSURANCE_NUMBER]: "Matricule",
    [CUSTOMERS_FILTER_ONLY_ACTIVE]: "Patients actifs seulement",

    [CUSTOMER_FILTER_FUZZY_TOOLTIP]:
      "Sélectionner cette option inclura des résultats similaires aux critères de recherche.",

    [CUSTOMER_BILL_TITLE_LABEL]: "Factures du patient",
    [CUSTOMER_DATA_TITLE_LABEL]: "Données du patient",
    [CUSTOMER_PAYMENT_TITLE_LABEL]: "Payments du patient",
    [CUSTOMER_PAYMENT_TITLE_SELECTION_LABEL]: "Payments pour {billNumber}",
    [CUSTOMER_SELECTED_CUSTOMER_LABEL]: "Veuillez choisir un patient.",
    [CUSTOMERS_FIRST_NAME_LABEL]: "Prénom",
    [CUSTOMERS_MIDDLE_NAME_LABEL]: "Nom de fille",
    [CUSTOMERS_LAST_NAME_LABEL]: "Nom",
    [CUSTOMERS_EMAIL_LABEL]: "Adresse email",
    [CUSTOMERS_COMMENT_LABEL]: "Commentaire",
    [CUSTOMERS_FAX_LABEL]: "No. fax.",
    [CUSTOMERS_PHONE_LABEL]: "No. téléphone",
    [CUSTOMERS_MOBILE_LABEL]: "No. portable",
    [CUSTOMERS_MATRICULATION_LABEL]: "Matricule",
    [CUSTOMERS_PRINT_BUTTON_LABEL]: "Imprimer",
    [CUSTOMERS_CREATE_BUTTON_LABEL]: "Créer nouveau patient",
    [CUSTOMERS_SAVE_BUTTON_LABEL]: "Actualiser données du patient",
    [CUSTOMERS_GENDER_LABEL]: "Sexe",
    [CUSTOMERS_TITLE_LABEL]: "Titre",
    [CUSTOMERS_BIRTH_DATE_LABEL]: "Date de naissance",

    [CUSTOMERS_ADDRESS_TITLE_LABEL]: "Adresses",
    [CUSTOMERS_ADDRESS_TYPE_LABEL]: "Type d'adresse",
    [CUSTOMERS_ADD_ADDRESS_TITLE_LABEL]: "Ajouter adresse",
    [CUSTOMERS_ADD_PAYMENT_TITLE_LABEL]: "Ajouter paiement",
    [CUSTOMERS_ADDRESS_CITY_LABEL]: "Localité",
    [CUSTOMERS_ADDRESS_LINE_1_LABEL]: "Ligne 1",
    [CUSTOMERS_ADDRESS_LINE_2_LABEL]: "Ligne 2",
    [CUSTOMERS_ADDRESS_ZIP_LABEL]: "Code postal",
    [CUSTOMERS_ADDRESS_RETRO_LABEL]:
      "Utiliser adresse pour factures existantes",
    [CUSTOMERS_ADDRESS_CREATE_BUTTON_LABEL]: "Ajouter nouvelle adresse",

    [CUSTOMERS_ADDRESS_DUPLICATE_WARNING]:
      "Une {type} existe déjà.\nSi vous continuez, l'adresse eistante est changée en adresse additionelle.",

    [CUSTOMERS_DEAD_LABEL]: "Décédé",

    [CUSTOMERS_SAVE_SUCCESS_MESSAGE]:
      "Les données du patient ont été mises à jours avec succès.",

    [CUSTOMERS_ERROR_MATRICULATION_LENGTH]: `La matricule doit contenir ${MATRICULATION_MIN_LENGTH} charactères.`,
    [CUSTOMERS_ERROR_MATRICULATION_REQUIRED]: "Aucun no. de matricule",
    [CUSTOMERS_VALIDATION_ERROR_INSURANCE_NUMBER]:
      "La seconde partie de la matricule doit comprendre 5 numéros.",

    [ADDRESSES_FILTER_TITLE_LABEL]: "Rechercher adresses",
    [ADDRESSES_FILTER_CUSTOMER_LABEL]: "Nom ou prénom du patient",
    [ADDRESSES_FILTER_ADDRESS_LABEL]: "Adresse, code postal ou localité",
    [ADDRESSES_FILTER_HIN_LABEL]: "Matricule du patient",
    [ADDRESSES_FILTER_SEARCHKEY_LABEL]: "Clé de recherche",

    [ADDRESSES_TITLE_LABEL]: "Éditer adresse",
    [ADDRESSES_SELECT_ADDRESS_LABEL]: "Veuillez sélectionner une adresse.",
    [ADDRESSES_SAVE_BUTTON_LABEL]: "Actualiser adresse",

    [ADDRESSES_PHONE_1_LABEL]: "No. téléphone 1",
    [ADDRESSES_PHONE_2_LABEL]: "No. téléphone 2",

    [ADDRESSES_HOSPITAL_LABEL]: "Hôpital",
    [ADDRESSES_NURSING_LABEL]: "Maison de soins",
    [ADDRESSES_AMBULANCE_LABEL]: "Transport ambulance",

    [ADDRESSES_ERROR_LINE1_REQUIRED]: "Ligne 1 requise",
    [ADDRESSES_ERROR_ZIP_REQUIRED]: "Code postal requis",
    [ADDRESSES_ERROR_CITY_REQUIRED]: "Localité requise",

    [APPOINTMENTS_TITLE_LABEL]: "Trajets - {date}",
    [APPOINTMENTS_EDIT_DIALOG_TITLE_LABEL]: "Trajet du",
    [APPOINTMENTS_CREATE_DIALOG_TITLE_LABEL]: "Créer trajet",
    [APPOINTMENTS_APPOINTMENT_LABEL]: "Trajet",
    [APPOINTMENTS_SECOND_APPOINTMENT_LABEL]: "2ième trajet",
    [APPOINTMENTS_CLIENT_LABEL]: "Patient",
    [APPOINTMENTS_START_ADDRESS_LABEL]: "Adresse de départ",
    [APPOINTMENTS_END_ADDRESS_LABEL]: "Adresse d'arrivée",
    [APPOINTMENTS_FIRST_DRIVER_LABEL]: "Chauffeur",
    [APPOINTMENTS_SECOND_DRIVER_LABEL]: "2. chauffeur",
    [APPOINTMENTS_STATUS_LABEL]: "Statut du trajet",
    [APPOINTMENTS_VEHICLE_LABEL]: "Véhicule",
    [APPOINTMENTS_LOCATION_LABEL]: "Région",
    [APPOINTMENTS_NEW_EVENT_LABEL]: "Nouveau Rdv.",
    [APPOINTMENTS_START_DATE_LABEL]: "Date de départ",
    [APPOINTMENTS_VEHICLE_TYPE_LABEL]: "Type de véhicule",
    [APPOINTMENTS_LAST_PAYMENT_DATE_LABEL]: "Date facture:",
    [APPOINTMENTS_LAST_BILL_NUMBER_LABEL]: "Dernière facture:",
    [APPOINTMENTS_CREATE_CUSTOMER_LABEL]: "Créer patient",
    [APPOINTMENTS_CREATE_BUTTON_LABEL]: "Ajouter nouveau trajet",
    [APPOINTMENTS_SAVE_BUTTON_LABEL]: "Actualisier données du trajet",

    [APPOINTMENTS_CUSTOMER_ADDRESS_WARNING]:
      "Les valeurs entrées saisies seront supprimées. Voulez-vous continuer?",
    [APPOINTMENTS_DELETE_BUTTON_LABEL]: "Effacer rdv.",
    [APPOINTMENTS_DELETE_SUCCESS]: "Le rendez-vous a été supprimé.",
    [APPOINTMENTS_DELETE_FAIL]: "Le rendez-vous n'a pas pu être spprimé.",
    [APPOINTMENTS_DELETE_NOTICE]: "Souhaitez-vous effacer ces données ?",

    [APPOINTMENTS_DRIVER_MESSAGE]: "Message chauffeur",
    [APPOINTMENTS_DRIVER_MESSAGE_SENT]: "Envoyé",
    [APPOINTMENTS_DRIVER_MESSAGE_RECEIVED]: "Reçu",
    [APPOINTMENTS_DRIVER_MESSAGE_READ]: "Lu",

    [APPOINTMENTS_DRIVER_REPORT_SAVE]: "Enregistrer",
    [APPOINTMENTS_DRIVER_REPORT_CANCEL]: "Annuler",
    [APPOINTMENTS_DRIVER_REPORT_NEW]: "Nouveau rapport",
    [APPOINTMENTS_DRIVER_REPORT_EDIT]: "Modifier le rapport",
    [APPOINTMENTS_DRIVER_REPORT_EDIT_BUTTON]: "Édit.",
    [APPOINTMENTS_DRIVER_SAVE_ERROR_TITLE_LABEL]: "Entrée(s) invalide(s)",
    [APPOINTMENTS_DRIVER_SAVE_ERROR_DETAILS]:
      "Les champs marqués en rouge sont obligatoires!",

    [APPOINTMENTS_NO_CUSTOMER_ASSIGNED_NOTICE]:
      "Données patient non attribuées",
    [APPOINTMENTS_NO_CUSTOMER_NOTICE]: "Pas de patient",
    [APPOINTMENTS_NO_FROM_ADDRESS_NOTICE]: "Pas d'adresse de départ",
    [APPOINTMENTS_NO_TO_ADDRESS_NOTICE]: "Pas d'adresse d'arrivée",

    [APPOINTMENTS_PASSENGER_STEP_LABEL]: "Patient",
    [APPOINTMENTS_DRIVE_STEP_LABEL]: "Détails Trajet",
    [APPOINTMENTS_CONFIRM_STEP_LABEL]: "Confirmer",
    [APPOINTMENTS_MULTI_STEP_LABEL]: "Plusieurs trajets",

    [APPOINTMENTS_DRIVE_APPOINTMENT]: "Rdv. du trajet",
    [APPOINTMENTS_DEPARTURE_TIME]: "Heure d'arrivée",
    [APPOINTMENTS_RETURN_TIME]: "Heure de retour",
    [APPOINTMENTS_KILOMETERS]: "Kilomètres",
    [APPOINTMENTS_KILOMETERS_BACK]: "Kilomètres de retour",
    [APPOINTMENTS_OUTBOUND_DRIVER_LABEL]: "Conducteur aller",
    [APPOINTMENTS_OUTBOUND_SECOND_DRIVER_LABEL]: "2ème conducteur aller",
    [APPOINTMENTS_RETURN_DRIVER_LABEL]: "Conducteur de retour",
    [APPOINTMENTS_RETURN_SECOND_DRIVER_LABEL]: "2ème conducteur de retour",
    [APPOINTMENTS_OUTBOUND_CAR_LABEL]: "Véhicule (aller)",
    [APPOINTMENTS_RETURN_CAR_LABEL]: "Véhicule (retour)",

    [APPOINTMENTS_DEPART_ROOM_LABEL]: "Salle de départ",
    [APPOINTMENTS_DEPART_DEPARTMENT_LABEL]: "Départ département",
    [APPOINTMENTS_DEPART_ADDRESS_COMMENT_LABEL]:
      "Adresse de départ texte libre",
    [APPOINTMENTS_DEPART_ADDRESS_1_LABEL]: "Adresse de départ ligne 1",
    [APPOINTMENTS_DEPART_ADDRESS_2_LABEL]: "Adresse de départ ligne 2",
    [APPOINTMENTS_DEPART_ZIP_LABEL]: "Code postal de départ",
    [APPOINTMENTS_FROM_LOCATION]: "Lieu de départ",
    [APPOINTMENTS_CUSTOMER_GSM]: "Numéro de portable",
    [APPOINTMENTS_CUSTOMER_FULL_NAME]: "Nom complet",

    [APPOINTMENTS_ARRIVAL_ROOM_LABEL]: "Arrivée Salle",
    [APPOINTMENTS_ARRIVAL_DEPARTMENT_LABEL]: "Arrivée département",
    [APPOINTMENTS_ARRIVAL_ADDRESS_COMMENT_LABEL]:
      "Adresse d'arrivée texte libre",
    [APPOINTMENTS_ARRIVAL_ADDRESS_1_LABEL]: "Arrivée adresse ligne 1",
    [APPOINTMENTS_ARRIVAL_ADDRESS_2_LABEL]: "Arrivée adresse ligne 2",
    [APPOINTMENTS_ARRIVAL_ZIP_LABEL]: "Arrivée code postal",
    [APPOINTMENTS_OUTWARD_PAPER_LABEL]: "Aller papiers",
    [APPOINTMENTS_OUTWARD_DEPARTURE_LABEL]: "Aller Départ",
    [APPOINTMENTS_OUTWARD_ARRIVAL_LABEL]: "Aller Arrivée",
    [APPOINTMENTS_OUTWARD_DISCHARGE_LABEL]: "Aller Arrivée/Départ",
    [APPOINTMENTS_RETURN_PAPER_LABEL]: "Retour papiers",
    [APPOINTMENTS_RETURN_DEPARTURE_LABEL]: "Retour Départ",
    [APPOINTMENTS_RETURN_ARRIVAL_LABEL]: "Retour Arrivée",
    [APPOINTMENTS_RETURN_DISCHARGE_LABEL]: "Retour Arrivée/départ",
    [APPOINTMENTS_CUSTOMER_HEADER]: "Patient",

    [APPOINTMENTS_WEIGHT_NUMBER]: "En surpoids Kg",
    [APPOINTMENTS_STAIRS_COUNT]: "Nombre d'escaliers",
    [APPOINTMENTS_VACCINATED]: "Vacciné",
    [APPOINTMENTS_TRANSPORT_TYPE_INVOICE]: "Transport",
    [APPOINTMENTS_RETURN_PHONE]: "Numéro de téléphone de retour",

    [APPOINTMENTS_CUSTOMER_HEADER]: "Patient",
    [APPOINTMENTS_DEPARTURE_HEADER]: "Départ",
    [APPOINTMENTS_DESTINATION_HEADER]: "Arrivé",
    [APPOINTMENTS_RETURN_HEADER]: "Retour",
    [APPOINTMENTS_NEXT_HEADER]: "Prochain rendezvous",
    [APPOINTMENTS_STATE_LABEL]: "Statut",
    [APPOINTMENTS_VACCINATED_LABEL]: "Vacciné",
    [APPOINTMENTS_BUS_LABEL]: "Bus",
    [APPOINTMENTS_PATIENT_STAY_LABEL]: "Rester chez patient",

    [APPOINTMENTS_CLEAR_ADDRESS]: "Effacer données d'adresse",
    [APPOINTMENTS_USE_CUSTOMER_ADDRESS]: "Copier adresse patient",
    [APPOINTMENTS_ROOM_LABEL]: "Chambre",
    [APPOINTMENTS_DEPARTMENT_LABEL]: "Département",
    [APPOINTMENTS_FREE_TEXT_LABEL]: "Texte libre",
    [APPOINTMENTS_AMOUNT_LABEL]: "No. trajets",
    [APPOINTMENTS_KEY_WORD_LABEL]: "Mot clé/Ville",

    [APPOINTMENTS_MULTIPLE_DRIVES_LABEL]: "Créer plusieurs trajets",
    [APPOINTMENTS_OXYGEN_LABEL]: "Oxygène",
    [APPOINTMENTS_DSA_LABEL]: "DSA",
    [APPOINTMENTS_POSITION_LYING_LABEL]: "Couché",
    [APPOINTMENTS_POSITION_SITTING_LABEL]: "Assis",
    [APPOINTMENTS_RETURN_LABEL]: "Retour",
    [APPOINTMENTS_ROUND_TRIP_LABEL]: "Aller & retour",
    [APPOINTMENTS_ISOLATION_LABEL]: "Isolation",
    [APPOINTMENTS_COMMENT_LABEL]: "Commentaire",
    [APPOINTMENTS_NEXT_APPOINTMENT_LABEL]: "Prochain rendez-vous",
    [APPOINTMENTS_SHOW_COMMENT_LABEL]: "Afficher commentaire",
    [APPOINTMENTS_MULTI_TRIPS_LABEL]: "Plusieurs trajets",
    [APPOINTMENTS_PHONE_LABEL]: "No. tél. add.",

    [APPOINTMENTS_DESINFECTION_LABEL]: "Désinfection",
    [APPOINTMENTS_PERFUSOR_LABEL]: "Pompe à séringue",
    [APPOINTMENTS_CORONA_LABEL]: "Corona",

    [APPOINTMENTS_DEPARTURE_TIME_LABEL]: "Rdv. Départ",
    [APPOINTMENTS_DESTINATION_TIME_LABEL]: "Rdv. Arrivée",
    [APPOINTMENTS_RETURN_TIME_LABEL]: "Rdv. Retour",

    [APPOINTMENTS_UPDATE_SUCCESS_MESSAGE]:
      "Les données du trajet ont été mises à jour.",
    [APPOINTMENTS_DEPARTURE_LABEL]: "Adresse de départ",
    [APPOINTMENTS_DESTINATION_LABEL]: "Adresse d'arrivée",
    [APPOINTMENTS_SELECT_ADDRESS_LABEL]: "Sélectionner adresse existante",
    [APPOINTMENTS_INFUSION_LABEL]: "Infusion",

    [APPOINTMENTS_DRIVE_VEHICLE]: "Véhicule aller",
    [APPOINTMENTS_RETURN_VEHICLE]: "Véhicule retour",

    [BILLS_FILTER_TITLE_LABEL]: "Recherche facture",
    [BILLS_FILTER_CUSTOMER_LABEL]: "Patient de facturation",
    [BILLS_FILTER_ADDRESS_LABEL]: "Adresse de facturation",
    [BILLS_FILTER_KEYWORDS]: "Mots clé",
    [BILLS_FILTER_BILL_NUMBER]: "Numéro de facture",
    [BILLS_FILTER_STATUS]: "État de facture",
    [BILLS_FILTER_EXCLUDE]: "Exclure c. hospitaliers",
    [BILLS_FILTER_INCLUDE]: "Inclure c. hospitaliers",
    [BILLS_FILTER_AMOUNT_MIN]: "Montant minimum",
    [BILLS_FILTER_AMOUNT_MAX]: "Montant maximum",
    [BILLS_FILTER_INCLUDE_INACTIVE]: "Inclus factures suppr.",
    [BILLS_FILTER_EXCLUDE_INACTIVE]: "Exclus factures suppr.",
    [BILLS_FILTER_FROM_DATE]: "De",
    [BILLS_FILTER_TO_DATE]: "Jusqu'à",
    [BILLS_FILTER_INCLUDE_UNPAID]: "Toutes les factures",
    [BILLS_FILTER_EXCLUDE_UNPAID]: "Factures ouvertes",

    [BILLS_TOTAL_AMOUNT_LABEL]: "Valeur totale: {value} €",
    [BILLS_TOTAL_PAID_LABEL]: "Payé en total: {value} €",

    [BILLS_NUMBER_LABEL]: "Numéro",
    [BILLS_CUSTOMER_LABEL]: "Patient",
    [BILLS_AMOUNT_DUE_LABEL]: "Restant",
    [BILLS_AMOUNT_PAID_LABEL]: "Restant",
    [BILLS_AMOUNT_REMAINING_LABEL]: "En attente",
    [BILLS_HOSPITAL_WARNING_LABEL]: "Facture hôpital",

    /* BILLS NEW INPUT LABELS*/
    [BILLS_HOSPITAL_ORDER_NUMBER_LABEL]: "Numéro d'ordre de l'hôpital",

    [BILLS_HOSPITAL_NB_ROUTES_LABEL]: "Nombre de trajets",
    [BILLS_HOSPITAL_NB_ROUTES_SINGLE_LABEL]: "Trajet simple",
    [BILLS_HOSPITAL_NB_ROUTES_TWO_WAY_LABEL]: "Aller / retour",
    [BILLS_HOSPITAL_NB_ROUTES_MULTIPLE_LABEL]: "Trajets multiples",

    [BILLS_HOSPITAL_POSITION_LABEL]: "Position",
    [BILLS_HOSPITAL_POSITION_AMBULANCE_LABEL]: "Ambulance",
    [BILLS_HOSPITAL_POSITION_CADDY_LABEL]: "Caddy",
    [BILLS_HOSPITAL_POSITION_TAXI_LABEL]: "Taxi",

    [BILLS_TITLE_LABEL]: "Éditer facture",
    [BILLS_SELECT_BILL_LABEL]: "Veuillez sélectionner une facture",
    [BILLS_BILL_NUMBER_LABEL]: "Numéro de facture",
    [BILLS_PASSENGER_LABEL]: "Personne transporté",
    [BILLS_DEPARTURE_LABEL]: "Lieu de départ",
    [BILLS_DESTINATION_LABEL]: "Lieu d'arrivée",
    [BILLS_DEPARTURE_TEXT_LABEL]: "Lieu de départ (Texte libre)",
    [BILLS_DESTINATION_TEXT_LABEL]: "Lieu d'arrivé (Texte libre)",
    [BILLS_COMMENT_INTERNAL_LABEL]: "Commentaire interne",
    [BILLS_COMMENT_EXTERNAL_LABEL]: "Commentaire facture",
    [BILLS_TRANSPORT_TICKET_LABEL]: "Titre de transport",
    [BILLS_TRANSPORT_DATE_LABEL]: "Date de trajet",
    [BILLS_ORDER_DATE_LABEL]: "Date de facture",
    [BILLS_FIRST_REMINDER_DATE_LABEL]: "1. rappel",
    [BILLS_SECOND_REMINDER_DATE_LABEL]: "2. rappel",
    [BILLS_DAS_DATE_LABEL]: "Date Sylvain",
    [BILLS_FULL_CHARGE_LABEL]: "100% à charge du patient",
    [BILLS_PARTIAL_CHARGE_LABEL]:
      "30% patient | 70% Union des Caisses de Maladie",
    [BILLS_CREATE_BUTTON_LABEL]: "Ajouter nouvelle facture",
    [BILLS_SAVE_BUTTON_LABEL]: "Actualiser données de facture",

    [BILLS_POSITIONS_TITLE]: "Options",
    [BILLS_POSITIONS_DESCRIPTION_LABEL]: "Description",
    [BILLS_POSITIONS_QUANTITY_LABEL]: "Qté.",
    [BILLS_POSITIONS_AMOUNT_LABEL]: "À payer",
    [BILLS_POSITIONS_TOTAL_AMOUNT_LABEL]: "Total",
    [BILLS_POSITIONS_PRESET_LABEL]: "Choisir modèle",
    [BILLS_ADD_POSITION_BUTTON_LABEL]: "Ajouter nouvelle option",
    [BILLS_ADD_POSITION_DIALOG_TITLE]: "Ajouter une option",
    [BILLS_EDIT_POSITION_BUTTON_LABEL]: "Appliquer changements",
    [BILLS_EDIT_POSITION_DIALOG_TITLE]: "Éditer position",
    [BILLS_REMOVE_POSITION_DIALOG_TITLE]: "Supprimer position",
    [BILLS_DAS_LABEL]: "SYLVAIN!!",
    [BILLS_IS_OVERWEIGHT_LABEL]: "Surpoids",
    [BILLS_WEIGHT_LABEL]: "Poids",
    [BILLS_HAS_STAIRS_LABEL]: "Escaliers",
    [BILLS_NUMBER_STAIRS_LABEL]: "No. escalier",
    [BILLS_WHEELCHAIR_LABEL]: "Fauteuil roulant dispo.",
    [BILLS_ELEVATOR_LABEL]: "Ascenseur",
    [BILLS_REMINDER_UPDATE_LABEL]: "Mise à jour du rappel",

    [BILLS_GENERATING_MESSAGE]: "Ihre Dokumente werden erstellt, bitte warten.",

    [BILLS_ALL_REMINDER_LABEL]: "Créer rappel pour résultats filtrés",
    [BILLS_ALL_EXPORT_LABEL]: "Créer fichier export pour résultats filtrés",
    [BILLS_EXPORT_TO_PEPPOL]: "Exporter vers Peppol",
    [BILLS_MULTI_REMINDER_LABEL]: "Créer rappels pour entrées sélectionnées",
    [BILLS_MULTI_REPORT_LABEL]:
      "Télécharger factures pour entrées sélectionnées",
    [BILLS_MULTI_STATUS_LABEL]: "Éditer état pour entrées sélectionnées",
    [BILLS_MULTI_BILLING_LABEL]: "Créer payments pour entrées sélectionnées",
    [BILLS_MULTI_EXPORT_LABEL]: "Exporter entrées sélectionnées",
    [BILLS_MULTI_BILLING_CREATE_LABEL]: "Enregistrer",
    [BILLS_MULTI_BILLING_UPDATE_STATE_LABEL]: "Mise à jour état",
    [BILLS_MULTI_BILLING_FILL_LAST]: "Autoriser paiement partiel",
    [BILLS_MULTI_FOOTER_LABEL]: "Solde impayé:",
    [BILLS_MULTI_EXPORT_SORT_TITLE]: "Tri",
    [BILLS_MULTI_EXPORT_SORT_SUMMARY]:
      "Veuillez choisir la manière dont la liste exportée doit être triée.",
    [BILLS_MULTI_STATUS_SUCCESS]:
      "Les états de facture ont étés mis à jour avec succès.",

    [BILLS_PEPPOL_EXPORT_LABEL]: "Exporter",
    [BILLS_PEPPOL_RESEND_LABEL]: "Envoyer à nouveau",
    [BILLS_PEPPOL_ADDRESSES_LABEL]: "Adresses Peppol",
    [BILLS_PEPPOL_SELECT_ADDRESSES_LABEL]: "Choisir des adresses Peppol",
    [BILLS_PEPPOL_EXPORT_SUCCESS]: "L'export Peppol a fini avec succès.",
    [BILLS_PEPPOL_EXPORT_FAIL]: "L'export Peppol a échoué.",
    [BILLS_PEPPOL_NO_INVOICES_FOUND]:
      "Aucune facture n'a été trouvée pouvant être envoyée via Peppol",

    [BILLS_MULTI_EXPORT_SORT_ORDER_BILL_NUMBER_ASC]: "Par date croissant",
    [BILLS_MULTI_EXPORT_SORT_ORDER_BILL_NUMBER_DESC]: "Par date décroissant",
    [BILLS_MULTI_EXPORT_SORT_ORDER_CUSTOMER_ASC]: "Par nom de patient (A-Z)",

    [BILLS_FILES_TITLE]: "Fichiers",
    [BILLS_FILES_CHOOSE_LABEL]: "Sél.",
    [BILLS_FILES_UPLOAD_LABEL]: "Téléch.",
    [BILLS_FILES_REPORT_LABEL]: "Téléch. facture",
    [BILLS_FILES_WARNING_LABEL]: "Téléch. avertissement",
    [BILLS_FILES_MERGE_LABEL]: "Télécharger documents fusionnés",
    [BILLS_FILES_SEND_LABEL]: "Email",
    [BILLS_FILES_EMPTY_LABEL]:
      "Glissez et déposez les fichiers ici pour les télécharger",
    [BILLS_FILES_BILL_PDF_TITLE]: "PDF facture",
    [BILLS_FILES_ADDITIONAL_FILES_TITLE]: "Fichiers additionels",

    [BILLS_FILES_UPLOAD_SUCCESS]:
      "Vos fichiers ont étés téléchargés aves succès.",
    [BILLS_FILES_REMOVE_SUCCESS]: "Le fichier a été supprimé avec succès.",

    [BILLS_FILES_ERROR_UPLOAD]:
      "Une erreur s'est produite lors du téléchargement des fichiers.",
    [BILLS_FILES_ERROR_SIZE_SUMMARY]: "Taille du fichier invalide",
    [BILLS_FILES_ERROR_SIZE_DETAIL]: "Taille de fichier maximale: {fileSize}",

    [BILLS_VALIDATION_ERROR_BILL_NUMBER]: "Numéro de facture invalide.",
    [BILLS_VALIDATION_ERROR_STATUS]: "État de facture non sélectionné.",
    [BILLS_VALIDATION_ERROR_TRANSPORT_TYPE]:
      "Aucun type de transport sélectionné.",
    [BILLS_VALIDATION_ERROR_BILLING_DATE]: "Date de facturation invalide.",
    [BILLS_VALIDATION_ERROR_CUSTOMER]: "Aucun patient sélectionné.",
    [BILLS_VALIDATION_ERROR_ADDRESS]:
      "Aucune adresse de facturation sélectionné.",
    [BILLS_VALIDATION_ERROR_FROM_TEXT]:
      "Texte libre pour adresse de départ requis",
    [BILLS_VALIDATION_ERROR_TO_TEXT]:
      "Texte libre pour adresse d'arrivée requis",
    [BILLS_VALIDATION_WARNING_NO_FILE]:
      "Aucun fichier PDF attaché à cette facture.",

    [BILLS_WARNING_UPLOAD_DISABLED]:
      "Le téléchargement de fichiers n'est pas disponible pour nouvelles entrées. Veuillez sauvegarder vos donner avant de télécharger des fichiers.",

    [PAYMENTS_SELECT_PAYMENT_LABEL]: "Veuillez sélectionner un paiement",
    [PAYMENTS_TITLE_LABEL]: "Éditer paiement",
    [PAYMENTS_CUSTOMER_LABEL]: "Patient",
    [PAYMENTS_BILLING_ADDRESS_LABEL]: "Adresse de facturation",
    [PAYMENTS_BILL_NUMBER_LABEL]: "No. facture",
    [PAYMENTS_DATE_LABEL]: "Date de paiement",
    [PAYMENTS_AMOUNT_LABEL]: "Montant",
    [PAYMENTS_BANK_LABEL]: "Banque",

    [PAYMENTS_BILL_TITLE_LABEL]: "Données de la facture",
    [PAYMENTS_BILL_NOT_SELECTED]: "Aucune facture sélectionné",

    [PAYMENTS_FILTER_TITLE_LABEL]: "Recherce paiements",
    [PAYMENTS_FILTER_MIN_AMOUNT_LABEL]: "Montant minimal",
    [PAYMENTS_FILTER_MAX_AMOUNT_LABEL]: "Montant maximal",
    [PAYMENTS_FILTER_FROM_DATE_LABEL]: "Du (Factures)",
    [PAYMENTS_FILTER_TO_DATE_LABEL]: "à (Factures)",
    [PAYMENTS_FILTER_CUSTOMER_LABEL]: "Patient",
    [PAYMENTS_FILTER_BILL_LABEL]: "Facture",
    [PAYMENTS_FILTER_ADDRESS_KEY_LABEL]: "Mots clés adresse",
    [PAYMENTS_FILTER_PAYMENT_KEY_LABEL]: "Mots clés payements",
    [PAYMENTS_FILTER_CUSTOMER_KEY_LABEL]: "Mots clés patients",
    [PAYMENTS_FILTER_BILL_KEY_LABEL]: "Mots clés factures",
    [PAYMENTS_FILTER_PAYMENT_FROM_DATE_LABEL]: "Du (Payements)",
    [PAYMENTS_FILTER_PAYMENT_TO_DATE_LABEL]: "à (Payements)",

    [PAYMENT_SAVE_SUCCESS_MESSAGE]:
      "Les données du payement ont été mises à jour.",
    [PAYMENT_DELETE_SUCCESS_MESSAGE]: "Le payement a été supprimé avec succès.",
    [PAYMENT_DELETE_FAILURE_MESSAGE]:
      "La payement sélectionné n'a pas pu être summprimé.",

    [PAYMENTS_SAVE_BUTTON_LABEL]: "Actualiser données de paiement",
    [PAYMENTS_CREATE_BUTTON_LABEL]: "Ajouter nouvel paiement",

    [PAYMENTS_VALIDATION_ERROR_BANK]: "Aucune banque sélectionné",
    [PAYMENTS_VALIDATION_ERROR_CUSTOMER]: "Aucun patient sélectionné.",
    [PAYMENTS_VALIDATION_ERROR_BILL]: "Aucune facture sélectionnée.",
    [PAYMENTS_VALIDATION_ERROR_INVALID_AMOUNT]: "Montant invalide.",
    [PAYMENTS_VALIDATION_ERROR_BILLING_DATE]: "Date de facturation invalide.",

    [PEPPOL_SEND_BILL_FAILURE]:
      "DLa transmission de la facture à Peppol a échoué.",
    [PEPPOL_SEND_BILL_SUCCESS]:
      "La facture a été transmise avec succès à Peppol.",
    [PEPPOL_SET_RESEND_SUCCESS]: "Facture libérée pour un nouvel envoi",

    [PRODUCTS_NAME_LABEL]: "Nom",
    [PRODUCTS_PRICE_LABEL]: "Prix",
    [PRODUCTS_QUANTITY_LABEL]: "Nombre de base",
    [PRODUCTS_NO_SELECTION_TITLE]: "Veuillez sélectionner une option",
    [PRODUCTS_NEW_ENTRY_TITLE]: "Nouvelle option",
    [PRODUCTS_EDIT_ENTRY_TITLE]: "Éditer option {name}",
    [PRODUCTS_SAVE_BUTTON_LABEL]: "Sauvgarder option",
    [PRODUCTS_CREATE_BUTTON_LABEL]: "Créer option",

    [PRODUCTS_VALIDATION_NAME_REQUIRED]: "Nom requis.",

    [VEHICLES_NEW_ENTRY_TITLE]: "Nouveau véhicule",
    [VEHICLES_EDIT_ENTRY_TITLE]: "Éditer véhicule {plate}",
    [VEHICLES_NO_SELECTION_LABEL]: "Veuillez sélectionner un véhicule.",
    [VEHICLES_TYPE_LABEL]: "Type",
    [VEHICLES_AVAILABLE_LABEL]: "Disponible",
    [VEHICLES_YEAR_LABEL]: "Année",
    [VEHICLES_KM_LABEL]: "Kilométrage",
    [VEHICLES_LICENSE_PLATE_LABEL]: "Plaque d'immatriculation",
    [VEHICLES_PHONE_LABEL]: "Téléphone",
    [VEHICLES_COMMENT_LABEL]: "Commentaire",
    [VEHICLES_WINFLEET_NAME_LABEL]: "Nom Winfleet",

    [VEHICLES_SAVE_BUTTON_LABEL]: "Sauver données du véhicule",
    [VEHICLES_CREATE_BUTTON_LABEL]: "Créer nouveau véhicule",

    [VEHICLES_VALIDATION_PLATE_REQUIRED]: "Numéro d'immatriculation requis.",
    [VEHICLES_VALIDATION_PLATE_INVALID]: `Le numéro d'immatriculation doit comprendre au moins ${VALIDATION_RULES.LICENSE_PLATE_MIN_LENGTH} charactères.`,
    [VEHICLES_VALIDATION_TYPE_REQUIRED]: "Type de véhicule requis.",

    [USERS_TITLE_LABEL]: "Éditer les données de {name}",
    [USERS_SELECT_USER_LABEL]: "Veuillez sélectionner un utilisateur.",
    [USERS_FIRST_NAME_LABEL]: "Prénom",
    [USERS_LAST_NAME_LABEL]: "Nom",
    [USERS_NICK_NAME_LABEL]: "Surnom",
    [USERS_HOME_PHONE_LABEL]: "Téléphone (privé)",
    [USERS_WORK_PHONE_LABEL]: "Téléphone (travail)",
    [USERS_MOBILE_PHONE_LABEL]: "Téléphone (mobile)",
    [USERS_HIRE_DATE_LABEL]: "Date d'embauche",
    [USERS_ALLERGIES_LABEL]: "Allergies",
    [USERS_DISEASES_LABEL]: "Maladies",
    [USERS_BLOODTYPE_LABEL]: "Groupe sanguin",
    [USERS_TIMERECORDING_LABEL]: "Saisie des heures",
    [USERS_WEEKHOURS_LABEL]: "Heures par semaine",

    [USERS_VACATION_BUDGET_START_LABEL]: "Debut budget",
    [USERS_VACATION_BUDGET_YEAR_LABEL]: "Année",
    [USERS_VACATION_BUDGET_LABEL]: "Budget",
    [USERS_VACATION_BUDGET_COMMENT_LABEL]: "Commentaire",
    [USERS_VACATION_BUDGET_SAVE_SUCCESS]:
      "Les données du budget ont été mises à jour.",
    [USERS_VACATION_OVER_BUDGET_WARNING]:
      "Vous allez dépasser votre budget de {days} jour(s) avec cette requête. Êtes-vous sûr de vouloir envoyer la requête?",
    [USERS_VACATION_OVER_BUDGET_ADMIN_WARNING]:
      "En confirmant la demande, le budget de congé de l'utilisateur sera dépassé de {days} jour(s). Êtes-vous sûr de vouloir envoyer la requête?",
    [USERS_BUDGET_DELETE_TITLE]: "Effacer budget",
    [USERS_BUDGET_DELETE_MESSAGE]:
      "Êtes-vous sur de vouloir effacer le budget de l'année {year}?",
    [USERS_BUDGET_DELETE_FAIL]: "Le budget n'a pas pu être effacé.",
    [USERS_BUDGET_DELETE_SUCCESS]: "Le budget a été effacé.",

    [USERS_VACATION_BUDGET_UPDATE_BUTTON_LABEL]: "Mise à jour du budget",
    [USERS_VACATION_BUDGET_CREATE_BUTTON_LABEL]: "Ajouter budget",

    [USERS_ADD_EMERGENCY_PERSON_LABEL]: "Ajouter une personne d'urgence",
    [USERS_DELETE_EMERGENCY_PERSON_LABEL]: "Supprimer la personne d'urgence",
    [USERS_EDIT_EMERGENCY_PERSON_LABEL]: "Éditer la personne d'urgence",

    [USERS_EMERGENCY_PERSON_ADD_SUCCESS]:
      "Personne d'urgence ajoutée avec succès",
    [USERS_EMERGENCY_PERSON_ADD_FAIL]:
      "Impossible d'ajouter la personne d'urgence",
    [USERS_EMERGENCY_PERSON_EDIT_FAIL]:
      "Impossible de modifier la personne d'urgence",
    [USERS_EMERGENCY_PERSON_EDIT_SUCCESS]:
      "Personne d'urgence modifiée avec succès",
    [USERS_EMERGENCY_PERSON_DELETE_FAIL]:
      "Impossible de supprimer la personne d'urgence",
    [USERS_EMERGENCY_PERSON_DELETE_SUCCESS]:
      "Personne d'urgence supprimée avec succès",

    [USERS_INFO_REPORT_BUTTON_LABEL]: "Infos",

    [DIALOG_WARNING_TITLE]: "Attention",

    [DIALOG_CONTINUE_OFFLINE_BUTTON_LABEL]: "Continuer sans connection",
    [DIALOG_SAVE_BUTTON_LABEL]: "Sauver",
    [DIALOG_CANCEL_BUTTON_LABEL]: "Annuler",
    [DIALOG_CANCEL_SHORT_BUTTON_LABEL]: "Anl.",
    [DIALOG_CONFIRM_BUTTON_LABEL]: "Oui",
    [DIALOG_DENY_BUTTON_LABEL]: "Non",
    [DIALOG_SEARCH_BUTTON_LABEL]: "Rechercher",
    [DIALOG_CLEAR_FILTER_LABEL]: "Effacer filtres",
    [DIALOG_CONTINUE_BUTTON_LABEL]: "Continuer",
    [DIALOG_BACK_BUTTON_LABEL]: "Retourner",
    [DIALOG_DOWNLOAD_BUTTON_LABEL]: "Télécharger",
    [DIALOG_CLOSE_BUTTON_LABEL]: "Fermer",

    [GERMAN.key]: "Allemand",
    [FRENCH.key]: "Français",

    [ERROR_MISSING_VALUES]: "Tout champs marqué doit être rempli.",
    [ERROR_DUPLICATE]: "L'entrée {value} existe déjà.",
    [ERROR_DATA_FETCH]:
      "Une erreur s'est produite lors de la réception des données.",
    [ERROR_NO_DATA]: "Pas de données disponibles.",
    [ERROR_RENDER]: "Le composant n'a pas pu être chargé.",
    [ERROR_SERVER_NOT_AVAILABLE]:
      "Le serveur n'est pas disponible en ce moment. Voulez-vous continuer sans connection?",
    [ERROR]: "Erreur",
    [ERROR_DATA_SAVE]:
      "Une erreur s'est produite lors de la sauvegarde des données.",
    [ERROR_TOKEN_EXPIRED]: "Votre session a expiré. Veuillez vous reconnecter.",

    [WARNING_CONFIRM_REMOVE]:
      "Êtes-vous sûr de vouloir supprimer l'entrée {value}?",
    [WARNING_UNSAVED_CHANGES]:
      "Si vous continuez, toutes les modifications non enregistrées seront perdues.\nVoulez-vous quand même continuer?",
    [WARNING_VALIDATION_FAILED]:
      "La validation pour les valeurs suivantes est échouée:",
    [WARNING_OFFLINE_MODE]: "L'application est en mode hors ligne!",

    [ROLE_OFFLINE]: "Offline",
    [ROLE_ADMIN]: "Administrateur",
    [ROLE_INVOICE]: "Facturation",
    [ROLE_PLANNING]: "Rendez-vous",
    [ROLE_USER]: "Utilisateur",
    [ROLE_DRIVER]: "Chauffeur",
    [ROLE_FIDU]: "Fiduciaire",
    [ROLE_AUTHOR]: "Auteur Shoutbox",

    [GENDER_NONE]: "Aucon sexe sélectionné",
    [GENDER_FEMALE]: "Féminin",
    [GENDER_MALE]: "Masculin",
    [GENDER_UNDEFINED]: "Non défini",

    [TITLES_CHILD]: "Enfant",
    [TITLES_DOCTOR]: "Docteur",
    [TITLES_GENTLEMEN]: "Messieurs",
    [TITLES_MASTER]: "M. & Mme.",
    [TITLES_MISS]: "Madame",
    [TITLES_MISSES]: "Mademoiselle",
    [TITLES_MISTER]: "Monsieur",
    [TITLES_MYLORD]: "Monseigneur",
    [TITLES_NO]: "Aucun titre",
    [TITLES_NONE]: "Aucun titre sélectionné",
    [TITLES_PROFESSOR]: "Professeur",
    [TITLES_SENIOR]: "Soeur",

    [TRANSPORT_TYPE_NONE]: "Sél. type de véhicule",
    [TRANSPORT_TYPE_AMBULANCE]: "Ambulance",
    [TRANSPORT_TYPE_CADDY]: "Caddy",
    [TRANSPORT_TYPE_TAXI]: "Taxi",
    [TRANSPORT_TYPE_EMPTY]: "Vide",
    [TRANSPORT_TYPE_BUS]: "Bus",
    [TRANSPORT_TYPE_VSL]: "VSL",

    [ADDRESS_TYPE_NONE]: "Aucun type d'adresse sélectionné",
    [ADDRESS_TYPE_INVOICE]: "Adresse de facturation",
    [ADDRESS_TYPE_BASE]: "Adresse de base",
    [ADDRESS_TYPE_ADDITIONAL]: "Adresse supplémentaire",

    [BILL_STATUS_NEW]: "Nouvelle facture",
    [BILL_STATUS_SENT]: "Facture envoyée",
    [BILL_STATUS_PARTIALLY_PAID]: "Paiement partiel",
    [BILL_STATUS_REMINDER_1]: "1. rappel",
    [BILL_STATUS_REMINDER_2]: "2. rappel",
    [BILL_STATUS_DAS]: "SYLVAIN!!",
    [BILL_STATUS_CLOSED]: "Cloturé",
    [BILL_STATUS_OVERPAID]: "Trop paié",
    [BILL_STATUS_DELIVERY_NOTE]: "Bon de livraison",

    [APPOINTMENT_STATUS_NONE]: "Pas de status de trajet",
    [APPOINTMENT_STATUS_RETURN]: "Retour",
    [APPOINTMENT_STATUS_OUTWARD]: "Aller",
    [APPOINTMENT_STATUS_EXECUTED]: "Exécuté",
    [APPOINTMENT_STATUS_WAITING]: "En suspens",
    [APPOINTMENT_STATUS_INVOICED]: "Facturé",
    [APPOINTMENT_STATUS_CANCELLED]: "Annulé",

    [APPOINTMENT_STATUS_PENDING]: "En attente",
    [APPOINTMENT_STATUS_BILLED]: "Facturé",

    [APPOINTMENT_UNRELEASED_ONLY]: "Factures non-validées seulement",
    [APPOINTMENT_ALL_BILLS]: "Toutes les factures",

    [APPOINTMENT_CANCEL_SELECTED_LABEL]: "Annuler les dates choisies",
    [APPOINTMENT_CANCEL_ERROR]: "Les rendez-vous ne pouvaient pas être annulés",
    [APPOINTMENT_CANCEL_SUCCESS]: "Annulation de rendez-vous réussie",
    [APPOINTMENT_CANCEL_NO_SELECTED]: "Aucun rendez-vous sélectionné",

    [SETTINGS_ACCOUNT_NUMBER_LABEL]: "Numéro de compte",
    [SETTINGS_ACCOUNT_BILL_TEXT_LABEL]: "Delai de payement",
    [SETTINGS_SAVE_BUTTON_LABEL]: "Sauvgarder paramètres",
    [SETTINGS_VALIDATION_ACCOUNT_NUMBER_REQUIRED]: "Numéro de compte requis.",
    [SETTINGS_UPDATE_SUCCESS_MESSAGE]:
      "Les paramètres opérationnels ont été mis à jour avec succès.",

    [SHIFTS_CREATE_BUTTON_LABEL]: "Créer nouveau quart de travail",
    [SHIFTS_EDIT_BUTTON_LABEL]: "Éditer quart de travail",
    [SHIFTS_DELETE_BUTTON_LABEL]: "Supprimer quart de travail",
    [SHIFTS_EVENT_SUMMARY_LABEL]: "{members} employés",
    [SHIFTS_ERROR_NO_SHIFT]: "Les types de travail n'ont pas pu être chargés.",
    [SHIFTS_NO_SHIFT_LABEL]: "Aucun quart de travail",
    [SHIFTS_NO_MEMBERS_LABEL]: "Aucun employé sélectionné.",
    [SHIFTS_DUTY_LABEL]: "Garde",
    [SHIFTS_DELETE_SUCCESS]: "Le quart de travail a été supprimé.",
    [SHIFTS_UPDATE_SUCCESS]: "Le quart de travail a été mis à jour.",
    [SHIFTS_UPDATE_FAIL]: "Une erreur s'est produite pendant la mise à jour.",
    [SHIFTS_MEMBERS_PLACEHOLDER]: "Employés",

    [ON_CALLS_REGISTER_BUTTON_LABEL]: "Inscrit",
    [ON_CALLS_UNREGISTER_BUTTON_LABEL]: "Pas inscrit",
    [ON_CALLS_EDIT_BUTTON_LABEL]: "Mise à jour de la permanence",
    [ON_CALLS_NUMBER_SLOTS_LABEL]: "Nombre d'employés",
    [ON_CALLS_SLOTS_OCCUPIED_LABEL]: "{members} de {limit} confirmés",
    [ON_CALLS_SHIFT_FULL_LABEL]: "La permanence est entièrement équipé.",
    [ON_CALLS_SHIFT_EMPTY_LABEL]: "Aucun employé inscrit.",
    [ON_CALLS_DRIVERS_TITLE]: "Employés inscrits",
    [ON_CALLS_NEW_SHIFT_TITLE]: "Créer nouvelle permanence",
    [ON_CALLS_LOW_SLOTS_WARNING]:
      "Il y a moins d'emplacements pour cette permanence que les employés inscrits.",
    [ON_CALLS_TYPES_WARNING]: "Titre non chargé",
    [ON_CALLS_CONFIRMED]: "Confirmé",

    [VACATIONS_REPORT_CURRENT_LABEL]: "Créer rapport pour {year}",
    [VACATIONS_MY_VACATIONS_LABEL]: "Mon congé pour {year}",
    [VACATIONS_START_LABEL]: "Debut congé",
    [VACATIONS_END_LABEL]: "Fin congé",
    [VACATIONS_COMMENT_USER_LABEL]: "Commentaire utilisateur",
    [VACATIONS_COMMENT_ADMIN_LABEL]: "Réponse administration",
    [VACATIONS_STATUS_LABEL]: "État",
    [VACATIONS_CREATE_BUTTON_LABEL]: "Créer demande de congé",
    [VACATIONS_UPDATE_BUTTON_LABEL]: "Mise à jour demande de congé",
    [VACATIONS_CREATE_ADMIN_BUTTON_LABEL]: "Entrer congé/maladie",
    [VACATIONS_CREATE_DIALOG_TITLE]: "Nouvelle demande de congé",
    [VACATIONS_DELETE_BUTTON_LABEL]: "Supprimer demande de congé",
    [VACATIONS_EDIT_DIALOG_TITLE]: "Éditer demande de congé",
    [VACATIONS_NO_VACATIONS_LABEL]: "Aucune demande de congé pour {year}",
    [VACATIONS_AVAILABLE_LABEL]: "Budget restant",
    [VACATIONS_HALF_DAY_LABEL]: "Demi journée",
    [VACATIONS_SICK_DAY_LABEL]: "Malade",
    [VACATIONS_SPECIAL_LABEL]: "Congé spécial",
    [VACATIONS_SHOW_ALL_LABEL]: "Montrer tous les congés",

    [VACATIONS_EVENT_HOURS]: "Congé flex.",
    [VACATIONS_SHOW_HOURS]: "Congé flexible",
    [VACATIONS_SHOW_REGULAR]: "Congé régulier",
    [VACATIONS_AMOUNT_HOURS]: "Nbr. heures",

    [VACATIONS_VACATION_LABEL]: "Congé",

    [VACATION_DATE_VALIDATION_FAIL]: "Date congé invalide",
    [VACATION_USER_VALIDATION_FAIL]: "Utilisateur invalide.",
    [VACATION_HOURS_VALIDATION_FAIL]: "Nbr. heures pas entre 0 et 8.",

    [VACATION_MINUTES_VALIDATION_FAIL]: "Nbr. minutes pas entre 0 et 60.",
    VACATIONS_AMOUNT_MINUTES: "Nbr. minutes",

    [VACATIONS_HOLIDAY_TITLE_LABEL]: "Créer jour férié",
    [VACATIONS_HOLIDAY_UPDATE_SUCCESS_MESSAGE]:
      "Le jour férié a été mis à jour.",
    [VACATIONS_HOLIDAY_UPDATE_FAILURE_MESSAGE]:
      "Le jour férié n'a pas pu être mis à jour.",
    [VACATIONS_HOLIDAY_LABEL]: "Jour férié",
    [VACATIONS_HOLIDAY_DELETE_WARNING]:
      "Voulez-vous vraiment supprimer le jour férié {holiday}?",
    [VACATION_SAVE_HOLIDAY_LABEL]: "Sauver jour férié",

    [VACATIONS_SET_OLD]: "Actualiser congés précédents",
    [VACATIONS_SET_OLD_CONFIRM]:
      "Voulez-vous marquer tous les congés accordés avant aujourd'hui comme pris?",
    [VACATIONS_SET_OLD_FAIL]: "Aucun congé n'a été édité.",

    [VACATION_UPDATE_SUCCESS_MESSAGE]:
      "Les données de la demande de congé ont été mises à jour.",
    [VACATION_UPDATE_FAILURE_MESSAGE]:
      "La demande de congé n'a pas pu être actualisée.",
    [VACATION_DELETE_SUCCESS_MESSAGE]: "La demande de congé a été supprimée.",

    [REGULARS_START]: "Début",
    [REGULARS_END]: "Centre",
    [REGULARS_DATES]: "Date(s)",
    [REGULARS_ID]: "ID",
    [REGULARS_DELETE_CONFIRM]:
      "Voulez-vous vraiment supprimer les données de patients réguliers {regularId}?",
    [REGULARS_DELETE_SUCCESS]:
      "Le données de patient régulier ont été supprimés.",
    [REGULARS_DELETE_FAIL]:
      "Le données de patient régulier n'ont pas pu être supprimés.",

    [DRIVES_HEADER_DAY]: "Jour",
    [DRIVES_HEADER_GARAGE_START]: "Début garage",
    [DRIVES_HEADER_GARAGE_END]: "Fin garage",
    [DRIVES_HEADER_DATE]: "Date",
    [DRIVES_HEADER_NAME]: "Nom",
    [DRIVES_HEADER_PAUSE]: "Pause",

    [DRIVERS_HEADER_CHECKED]: "{controlled} de {total} controlés",
    [DRIVERS_HEADER_NEXT]: "Prochain chauffeur",
    [DRIVERS_HEADER_BLOCKED]: "Temps bloqué",
    [DRIVERS_HEADER_CONTROLLED]: "Controlé",

    [DRIVES_TABLE_RETURN]: "Retour",
    [DRIVES_TABLE_START_TIME]: "Heure départ",
    [DRIVES_TABLE_PATIENT]: "Patient",
    [DRIVES_TABLE_START_ADDRESS]: "Lieu de départ",
    [DRIVES_TABLE_DESTINATION_ADDRESS]: "Destination",
    [DRIVES_TABLE_MEETING_TIME]: "Rendez-vous",
    [DRIVES_TABLE_VEHICLE]: "Véhicule",
    [DRIVES_TABLE_BUTTON_START]: "Start",

    [DRIVES_TIMES_RESPONSIVE]: "Temps",
    [DRIVES_ADDRESS_RESPONSIVE]: "Adresses",
    [DRIVES_TIME_START_RESPONSIVE]: "Debut",
    [DRIVES_TIME_MEETING_RESPONSIVE]: "Rdv.",
    [DRIVES_DEPARTURE_RESPONSIVE]: "Départ",
    [DRIVES_ARRIVAL_RESPONSIVE]: "Arrivé",

    [DRIVES_REFRESH]: "Mise à jour rendezvous",
    [DRIVES_SAVE_TIMES]: "Sauvegarder temps",

    [DRIVES_SAVE_DAILY_REPORT]: "Finir journée",
    [DRIVES_PRINT_DAILY_REPORT]: "Imprimer rapport journalier",
    [DRIVES_PRINT_TRANSPORT_REPORT]: "Imprimer rapport de transport",
    [DRIVES_PRINT_MONTHLY_REPORT]: "imprimer le rapport mensuel",
    [DRIVES_REUPLOAD]: "Re-télécharger",

    [DRIVES_NO_APPOINTMENT]: "Aucun trajet disponible",
    [DRIVES_CITY]: "Ville",
    [DRIVES_PAPER_WAIT]: "Att. Papiers",
    [DRIVES_MINUTES]: "mins.",
    [DRIVES_HOURS_DEPARTURE]: "Hrs. départ",
    [DRIVES_HOURS_ARRIVAL]: "Hrs. arrivé",
    [DRIVES_ADMISSION]: "Adm./Décharge",
    [DRIVES_KILOMETERS]: "kms",

    [DRIVES_DETAILS_TITLE]: "Détails du trajet",
    [DRIVES_TRANSPORT_TITLE]: "Transport",
    [DRIVES_INFO_TITLE]: "Informations",

    [DRIVES_SIGNATURE_HEADER]: "Signature patient",
    [DRIVES_SIGNATURE_CLEAR]: "Effacer",
    [DRIVES_SIGNATURE_SAVE]: "Sauver & télécharger",

    [DRIVES_WS_TITLE]: "Mise à jour rendez-vous",
    [DRIVES_WS_DELETE]: "Status de rdv. changé ou supprimé.",
    [DRIVES_WS_UPDATE]: "Le rdv. suivant a été mis à jour:",

    [DRIVES_DAILY_SAVE_SUCCESS]:
      "Le journal de bord de {name} pour le {date} a été mis à jour.",
    [DRIVES_DAILY_SAVE_FAIL]: "Le journal de bord n'a pas pu être mis à jour.",
    [DRIVES_DAILY_ADDITIONAL_SAVE_SUCCESS]:
      "Une nouvelle entrée a été créée pour demain !",
    [DRIVES_DAILY_ADDITIONAL_SAVE_FAIL]:
      "Une nouvelle entrée pour demain n'a pas pu être créée !",

    [DRIVER_GENERATE_MONTHLY_REPORT_FAIL]:
      "Le rapport mensuel n'a pas pu être établi !",

    [DRIVER_GENERATE_TRANSPORT_REPORT_FAIL]:
      "Le rapport de transport n'a pas pu être établi !s",

    [DRIVES_REPORT_VIEW]: "Rapport journalier",
    [DRIVES_WORK_VIEW]: "Journal de bord",

    [MONTH_REPORT_SEARCH_NAME]: "Chercher par nom",

    [SHOUTBOX_NO_CONTENT]: "Pas de contenu",
    [SHOUTBOX_NO_MESSAGE]: "Pas de messages",
    [SHOUTBOX_SEARCH_PLACEHOLDER]: "Accident A1...",
    [SHOUTBOX_NOTIFICATION_EDIT_TITLE]: "Une Shoutbox a été éditée.",
    [SHOUTBOX_NOTIFICATION_DELETE_TITLE]: "Une Shoutbox a été supprimée.",
    [SHOUTBOX_NOTIFICATION_EDIT_BODY]:
      "{sender} a édité la Shoutbox avec le {title}.",
    [SHOUTBOX_NOTIFICATION_DELETE_BODY]:
      "{sender} a supprimé la Shoutbox avec le {title}.",
    [SHOUTBOX_DELETE_CONFIRM]:
      "Est-ce que voues êtes sûr de vouloir supprimer cette Shoutbox?",
    [SHOUTBOX_DIALOG_TITLE_ADD]: "Créer nouvelle Shoutbox",
    [SHOUTBOX_DIALOG_TITLE_EDIT]: "Éditer Shoutbox - {title} ",
    [SHOUTBOX_DIALOG_TITLE_DELETE]: "Supprimer Shoutbox - {title}",
    [SHOUTBOX_DIALOG_TITLE_CHAT]: "Chat - {title}",
    [SHOUTBOX_NOTIFICATION_EDIT_REACTION_TITLE]: "Tu as reçu une réaction!",
    [SHOUTBOX_NOTIFICATION_EDIT_DEFAULT_BODY]: "Une Shoutbox a été éditée.",
    [SHOUTBOX_NOTIFICATION_EDIT_REACTION_BODY]:
      "{sender} a réagi sur une Shoutbox.",
    [SHOUTBOX_IMAGE_SELECT_BUTTON_LABEL]: "Sélectionner image",
    [SHOUTBOX_SORT_SEVERITY_LABEL]: "Sévérité",
    [SHOUTBOX_SORT_TIME_LABEL]: "Temps",
    [SHOUTBOX_GROUP_TT_MORE]: "et {count} de plus.",
    [SHOUTBOX_GROUP_EMPTY_MESSAGE]: "Cette groupe n'a pas de membres.",
    [SHOUTBOX_EDIT_FAIL_MESSAGE]: "La {type} n'a pas pu être sauvgardée.",
    [SHOUTBOX_DELETE_FAIL_MESSAGE]: "La {type} n'a pas pu être supprimée.",

    [MONTH_REPORT_NAME]: "Nom",
    [MONTH_REPORT_TOTAL]: "Total",

    [MONDAY_LONG]: "Lundi",
    [MONDAY_SHORT]: "Lu",
    [TUESDAY_LONG]: "Mardi",
    [TUESDAY_SHORT]: "Mar",
    [WEDNESDAY_LONG]: "Mercredi",
    [WEDNESDAY_SHORT]: "Mer",
    [THURSDAY_LONG]: "Jeudi",
    [THURSDAY_SHORT]: "Jeu",
    [FRIDAY_LONG]: "Vendredi",
    [FRIDAY_SHORT]: "Ven",
    [SATURDAY_LONG]: "Samedi",
    [SATURDAY_SHORT]: "Sam",
    [SUNDAY_LONG]: "Dimanche",
    [SUNDAY_SHORT]: "Dim",

    [JANUARY_LONG]: "Janvier",
    [JANUARY_SHORT]: "Janv.",
    [FEBRUARY_LONG]: "Février",
    [FEBRUARY_SHORT]: "Févr.",
    [MARCH_LONG]: "Mars",
    [MARCH_SHORT]: "Mars",
    [APRIL_LONG]: "Avril",
    [APRIL_SHORT]: "Avr.",
    [MAY_LONG]: "Mai",
    [MAY_SHORT]: "Mai",
    [JUNE_LONG]: "Juin",
    [JUNE_SHORT]: "Juin",
    [JULY_LONG]: "Juillet",
    [JULY_SHORT]: "Juill.",
    [AUGUST_LONG]: "Août",
    [AUGUST_SHORT]: "Août.",
    [SEPTEMBER_LONG]: "Septembre",
    [SEPTEMBER_SHORT]: "Sept.",
    [OCTOBER_LONG]: "Octobre",
    [OCTOBER_SHORT]: "Oct.",
    [NOVEMBER_LONG]: "Novembre",
    [NOVEMBER_SHORT]: "Nov.",
    [DECEMBER_LONG]: "Décembre",
    [DECEMBER_SHORT]: "Déc.",

    [TODAY]: "Aujourd'hui",

    [VIEWMODE_LIGHT]: "Mode lumière",
    [VIEWMODE_DARK]: "Mode sombre",

    [FUZZY_ON]: "Exact",
    [FUZZY_OFF]: "Approx.",

    [PURGE_BUTTON_LABEL]: "Recharger",

    [RESET_VALUES]: "Réinitialiser valeurs",

    [ACTIVE]: "Actif",
    [INACTIVE]: "Inactif",

    [CREATED_BY]: "Créé par: ",
    [CREATED_ON]: "Créé le: ",

    [EDITED_BY]: "Édité par: ",
    [EDITED_ON]: "Édité le: ",

    [STARTS_WITH]: "Beginnt mit",
    [CONTAINS]: "Enthält",
    [NOT_CONTAINS]: "Enthält nicht",
    [ENDS_WITH]: "Endet mit",
    [EQUALS]: "Gleich",
    [NOT_EQUALS]: "Ungleich",
    [NO_FILTER]: "Kein Filter",

    [DRIVER_STATUS_SAVE_FAILED]:
      "Le statut n'a pas pu être enregistré, veuillez sélectionner un véhicule et un statut.}",
    [DRIVER_STATUS_SAVE_SUCCESS]: "Le statut a été enregistré avec succès.",
    [DRIVER_STATUS_AVAILABLE_LABEL]: "Disponible",
    [DRIVER_STATUS_IN_GARAGE_AND_AVAILABLE_LABEL]: "Au garage et disponible",
    [DRIVER_STATUS_ON_THE_ROAD_TO_PATIENT_LABEL]: "En route vers le patient",
    [DRIVER_STATUS_ON_THE_ROAD_WITH_PATIENT_LABEL]: "En route avec le patient",
    [DRIVER_STATUS_WITH_PATIENT_LABEL]: "Chez le patient",
    [DRIVER_STATUS_BREAK_LABEL]: "à la pause",
    [DRIVER_STATUS_CLOSING_TIME_LABEL]: "Fin de la journée",
    [DRIVER_STATUS_UNLOADING_LABEL]: "Au déchargement",
    [DRIVER_STATUS_SORT_LABEL]: "Trier par",
    [DRIVER_STATUS_SORT_DRIVER_NAME_LABEL]: "Nom",
    [DRIVER_STATUS_SORT_LICENCE_PLATE_LABEL]: "Plaque d'immatriculation ",
    [DRIVER_STATUS_SORT_NEWEST_LABEL]: "Plus récent",
    [DRIVER_STATUS_SORT_NO_RESULT]: "Aucun résultat trouvé.",
    [DRIVER_STATUS_SORT_NO_HISTORICAL_VALUES]: "Aucun statut historique",
    [HISTORICAL_APPOINTMENTS_LABEL]: "Voir entrées historiques",
    [HISTORICAL_APPOINTMENTS_NO_RESULT]: "Aucune entrée historique trouvée",
    [HISTORICAL_APPOINTMENTS_INFO]: "Entrées historiques pour rendez-vous N°",
  },
};

export default messages;
