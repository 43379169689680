import React from "react";
// Responsive
import { isDesktop } from "react-device-detect";
// React redux
import { connect } from "react-redux";
// PrimeReact components
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { ToggleButton } from "primereact/togglebutton";
import { confirmDialog } from "primereact/confirmdialog";
import { InputNumber } from "primereact/inputnumber";
import { FloatLabel } from "primereact/floatlabel";
// Custom components
import { FloatingTextInput } from "components/common";
// Helper functions
import {
  dateToQueryString,
  dateToString,
  initLogger,
  isAdmin,
  isCurrentUserAdmin,
  sendQuery,
  splitDuration
} from "common/Helpers";
// Locaization
import { injectIntl } from "react-intl";
import {
  DATE_FORMAT,
  LOCALES,
  MESSAGE_KEYS,
  MESSAGE_SEVERITY,
  QUERIES,
} from "assets/staticData/enums";
// Styling
import "../Style.scss";

const logger = initLogger("new_vacation_dialog");
const REQUESTED_ID = 1;
// Static data
const EMPTY_STATE = {
  inputDateRange: [],

  inputCommentAdmin: "",
  inputCommentUser: "",
  inputState: { appointmentStateId: null },
  inputIsHalfDay: false,
  inputIsSickDay: false,

  inputUser: null,

  valiDates: null,

  validUser: null,

  validHours: null,

  validMinutes: null,

  isAdmin: false,
  isCurrentUser: false,
  activeUsers: [],

  editable: false,

  available: 0,
  budget: 0,

  showHourInputs: false,
  numberHours: 1,
  numberMinutes: 0,
};

class VacationEventPopup extends React.Component {
  state = {
    ...EMPTY_STATE,
  };

  componentDidMount = () => {
    this.initInputs();
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.value !== this.props.value) {
      this.initInputs();
    }
  };

  initEditable = (stateId) => {
    if (stateId === null || isCurrentUserAdmin()) {
      return true;
    } else {
      return stateId === REQUESTED_ID;
    }
  };

  initInputs = () => {
    const { value, currentUser, vacationStates, drivers, available, budget} =
      this.props;
    let hasAdminRights = isAdmin(currentUser.userRoles);
    let isCurrentUser = currentUser.personId === value.user;    
    let newState;
    if (value) {
      const {
        value: {
          startDate,
          endDate,
          state,
          remark,
          remark2,
          halfday,
          appointmentId,
          user,
          allDay,
          duration 
        },
      } = this.props;
      let inputStartDate, inputEndDate, inputDateRange;
      if (appointmentId) {
        inputStartDate = new Date(startDate.getTime());
        inputStartDate.setHours(2, 0, 0);
        inputEndDate = new Date(endDate.getTime());

        inputEndDate.setDate(inputEndDate.getDate());
        inputEndDate.setHours(2, 0, 0);
        if (allDay === true) {
          inputEndDate.setDate(inputEndDate.getDate() - 1);
        }
      } else {
        let newDate = new Date(startDate.getTime());
        newDate.setHours(16, 0, 0);
        inputStartDate = inputEndDate = newDate;
      }
      inputDateRange = [inputStartDate, inputEndDate];

      let inputState = {
        appointmentStateId: null,
      };
      if (state) {
        if (typeof state === "number") {
          if (vacationStates) {
            inputState = vacationStates.find((searchState) => {
              return searchState.appointmentStateId === state;
            });
          }
        } else {
          inputState = state;
        }
      } else if (vacationStates) {
        inputState = vacationStates[0];
      }

      let inputUser = { personId: user };
      let activeUsers = [];
      if (drivers && drivers.length > 0) {
        activeUsers = drivers.filter((person) => person.active);
        inputUser =
          this.props.filterUser.personId !== -1
            ? this.props.filterUser
            : drivers.find((searchUser) => user === searchUser.personId);
      }

      const {inputHours, inputMinutes} = splitDuration(duration);      
      newState = {
        inputCommentAdmin: remark2 ?? "",
        inputCommentUser: remark ?? "",
        inputState,
        inputIsHalfDay: halfday,
        isCurrentUser,
        isAdmin: hasAdminRights,
        inputUser,
        inputDateRange,
        activeUsers,
        editable: this.initEditable(inputState.appointmentStateId),
        available,
        budget,
        showHourInputs: !isNaN(duration) && duration > 0,
        numberHours: inputHours,
        numberMinutes: inputMinutes
      };
    } else {
      newState = {
        ...EMPTY_STATE,
        isAdmin: hasAdminRights,
        isCurrentUser,
        inputUser: currentUser,
        editable: true,
      };
    }
    this.setState({
      ...newState,
    });
  };

  /**
   * @returns {Promise}
   */
  validateInputs = () => {
    return new Promise((resolve, reject) => {
      try {        
        let valiDate;

        const {inputDateRange, showHourInputs, numberHours, numberMinutes} = this.state;

        if (inputDateRange?.length === 2) {
          const [inputEndDate, inputStartDate] = inputDateRange;
          valiDate =
            inputStartDate &&
            !isNaN(inputStartDate.getTime()) &&
            inputEndDate &&
            !isNaN(inputEndDate.getTime());
        } else {
          valiDate = false;
        }        
        let validUser = true;
        validUser = !!this.state.inputUser?.personId;

        let validHours = true;
        let  validMinutes = true;
        if(showHourInputs) {
          validHours = (numberHours + numberMinutes) > 0 && numberHours <= 7;
          validMinutes = (numberHours + numberMinutes) > 0 && numberMinutes <= 45;
        }
        

        this.setState({
          valiDate,
          validUser,
          validHours,
          validMinutes,
        });        
        valiDate && validUser && validHours && validMinutes ? resolve() : reject({ valiDate, validUser, validHours, validMinutes });
      } catch (validateException) {
        logger.error(validateException);
        reject();
      }
    });
  };

  mapInputsToDTO = () => {
    try {
      const {
        inputCommentUser,
        inputCommentAdmin,
        inputUser,
        inputState,
        inputIsHalfDay,
        inputDateRange,
        numberHours, numberMinutes, showHourInputs
      } = this.state;
      return {
        startDate: inputDateRange[0],
        endDate: inputDateRange[1],
        remark: inputCommentUser,
        remark2: inputCommentAdmin,
        user: inputUser,
        state: inputState?.appointmentStateId
          ? inputState.appointmentStateId
          : null,
        halfday: inputIsHalfDay,
        countedFullDays: 0, // TODO CALCULATE DIS
        duration: showHourInputs ? (numberHours * 60) + numberMinutes : 0
      };
    } catch (mapException) {
      return null;
    }
  };

  dateDifferenceInDays(date1, date2, halfDay) {
    // Remove time information
    const truncatedDate1 = new Date(date1.toDateString());
    const truncatedDate2 = new Date(date2.toDateString());

    // Calculate the difference in days
    const timeDiff = truncatedDate2 - truncatedDate1;
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    // Check the flag value
    if (halfDay) {
      return 0.5;
    } else if (daysDiff === 0) {
      return 1;
    } else {
      return daysDiff + 1; // Include starting day.
    }
  }

  displayWarning = (remainingDays) => {
    const { isAdmin, inputState } = this.state;
    const CONFIRM_STATE_ID = 2;
    const REQUEST_STATE_ID = 1;
    return (
      remainingDays < 0 &&
      (isAdmin
        ? [CONFIRM_STATE_ID, REQUESTED_ID].includes(
            inputState?.appointmentStateId
          )
        : inputState?.appointmentStateId === REQUEST_STATE_ID)
    );
  };

  handleConfirm = () => {
    const { onConfirm, intl } = this.props;
    const {
      USERS_VACATION_OVER_BUDGET_ADMIN_WARNING,
      USERS_VACATION_OVER_BUDGET_WARNING,
      DIALOG_CONFIRM_BUTTON_LABEL,
      DIALOG_CANCEL_BUTTON_LABEL,
    } = MESSAGE_KEYS;
    const { inputDateRange, isAdmin, available } = this.state;

    this.validateInputs().then(
      () => {
        let data = this.mapInputsToDTO();
        const daysDiff = this.dateDifferenceInDays(
          inputDateRange[0],
          inputDateRange[1]
        );
        if (data) {
          const remainingBudget = (available ?? 0) - daysDiff;
          if (this.displayWarning(remainingBudget)) {
            const label = isAdmin
              ? USERS_VACATION_OVER_BUDGET_ADMIN_WARNING
              : USERS_VACATION_OVER_BUDGET_WARNING;
            confirmDialog({
              message: intl.formatMessage(
                { id: label },
                { days: Math.abs(remainingBudget) }
              ),
              header: "Confirmation",
              icon: "pi pi-exclamation-triangle",
              accept: () => onConfirm(data),
              acceptLabel: intl.formatMessage({
                id: DIALOG_CONFIRM_BUTTON_LABEL,
              }),
              rejectLabel: intl.formatMessage({
                id: DIALOG_CANCEL_BUTTON_LABEL,
              }),
            });
          } else {
            onConfirm(data);
          }
        } else {
          this.toast.show({
            severity: MESSAGE_SEVERITY.ERROR,
            summary: this.props.intl.formatMessage({
              id: MESSAGE_KEYS.ERROR_DATA_SAVE,
            }),
          });
        }
      },
      ({ valiDate, validUser, validHours, validMinutes }) => {
        logger.warn("Vacation validation failed");
        this.toast.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: this.generateValidationWarning(valiDate, validUser, validHours, validMinutes),
        });
      }
    );
  };

  generateValidationWarning = (valiDate, validUser, validHours, validMinutes) => {
    const {
      VACATION_DATE_VALIDATION_FAIL,
      VACATION_USER_VALIDATION_FAIL,
      VACATION_HOURS_VALIDATION_FAIL,
      VACATION_MINUTES_VALIDATION_FAIL,
      WARNING_VALIDATION_FAILED,
    } = MESSAGE_KEYS;
    const { intl } = this.props;    
      return (
        <div>
          <div>{intl.formatMessage({ id: WARNING_VALIDATION_FAILED })}</div>
          <ul>
            {!valiDate && <li>{intl.formatMessage({ id: VACATION_DATE_VALIDATION_FAIL })}</li>}
            {!validUser && <li>{intl.formatMessage({ id: VACATION_USER_VALIDATION_FAIL })}</li>}
            {!validHours && <li>{intl.formatMessage({ id: VACATION_HOURS_VALIDATION_FAIL })}</li>}
            {!validMinutes && <li>{intl.formatMessage({ id: VACATION_MINUTES_VALIDATION_FAIL })}</li>}
          </ul>
        </div>
      );    
  };

  renderVacationStateButton = (vState, label) => {
    const { inputState, inputIsSickDay } = this.state;
    return (
      <div
        key={`v_state_${vState.appointmentStateId}`}
        className="mr-2 flex flex-row justify-content-between align-items-center mb-2"
      >
        <RadioButton
          inputId={`rdb_${vState.appointmentStateId}`}
          value={vState}
          name="v_state"
          onChange={(e) => {
            this.setState({ inputState: e.value });
          }}
          checked={inputState === vState}
          className="mr-1"
          style={isDesktop ? {} : { flexGrow: 1 }}
          disabled={inputIsSickDay}
        />
        <label
          style={{ color: inputIsSickDay ? "lightgray" : "#495057" }}
          htmlFor={`rdb_${vState.appointmentStateId}`}
        >
          {label}
        </label>
      </div>
    );
  };

  renderVacationState = () => {
    const { currentLocale, intl, vacationStates } = this.props;
    const { ERROR_RENDER } = MESSAGE_KEYS;
    const { isAdmin, inputState, editable } = this.state;
    let content;
    try {
      if (isAdmin && editable) {
        content = [];
        vacationStates.forEach((vState) => {
          if (vState.appointmentStateId) {
            let label =
              currentLocale && currentLocale === LOCALES.FRENCH.key
                ? vState.nameFr
                : vState.nameDe;
            content.push(this.renderVacationStateButton(vState, label));
          }
        });
        return content;
      } else {
        return [
          <strong key="non_admin_states">
            {currentLocale && currentLocale === LOCALES.FRENCH.key
              ? inputState.nameFr
              : inputState.nameDe}
          </strong>,
        ];
      }
    } catch (renderException) {
      logger.warn(renderException);
      return [
        <div key="states_render_error">
          {intl.formatMessage({ id: ERROR_RENDER })}
        </div>,
      ];
    }
  };

  renderButtonRow = () => {
    const { intl, onHide, isNew, vacationStates, onDelete, value } = this.props;
    const {
      ERROR_RENDER,
      VACATIONS_CREATE_BUTTON_LABEL,
      VACATIONS_UPDATE_BUTTON_LABEL,
      DIALOG_CANCEL_BUTTON_LABEL,
      ON_CALLS_EDIT_BUTTON_LABEL,
      DIALOG_BACK_BUTTON_LABEL,
      VACATIONS_CREATE_ADMIN_BUTTON_LABEL,
      VACATIONS_DELETE_BUTTON_LABEL,
    } = MESSAGE_KEYS;
    const { isAdmin, inputState, editable } = this.state;
    try {
      let saveLabel = isAdmin
        ? VACATIONS_CREATE_ADMIN_BUTTON_LABEL
        : VACATIONS_CREATE_BUTTON_LABEL;
      if (!isNew) {
        saveLabel = isDesktop
          ? VACATIONS_UPDATE_BUTTON_LABEL
          : ON_CALLS_EDIT_BUTTON_LABEL;
      }

      let showDelete =
        editable &&
        !isNew &&
        (value.state === vacationStates[1].appointmentStateId ||
          !inputState.appointmentStateId);

      return (
        <div className="flex justify-content-between mt-4">
          {editable && (
            <Button
              label={intl.formatMessage({ id: saveLabel })}
              onClick={this.handleConfirm}
              className={isDesktop ? "mr-2" : ""}
            />
          )}
          {showDelete && (
            <Button
              label={intl.formatMessage({ id: VACATIONS_DELETE_BUTTON_LABEL })}
              onClick={onDelete}
              className={isDesktop ? "mr-2" : ""}
            />
          )}
          <Button
            label={intl.formatMessage({
              id: editable
                ? DIALOG_CANCEL_BUTTON_LABEL
                : DIALOG_BACK_BUTTON_LABEL,
            })}
            onClick={onHide}
          />
        </div>
      );
    } catch (renderException) {
      logger.warn(renderException);
      return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
    }
  };

  renderHourInputs = () => {
    const { intl } = this.props;
    const { VACATIONS_START_LABEL, VACATIONS_AMOUNT_HOURS, VACATIONS_AMOUNT_MINUTES, ERROR_RENDER } =
      MESSAGE_KEYS;
    const { numberHours, inputDateRange, editable, validHours, numberMinutes, validMinutes } = this.state;

    try {
      return (
        <div className="flex justify-content-between align-items-center gap-3">        
          <span className="p-float-label">
            <Calendar
              id="vacation_hours"
              selectionMode="single"
              value={inputDateRange[0]}
              onChange={(e) => {
                this.setState({ inputDateRange: [e.value, e.value] });
              }}
              disabled={!editable}
              dateFormat={DATE_FORMAT}
              selectOtherMonths={true}
            />
            <label htmlFor="vacation_hours">
              {intl.formatMessage({
                id: VACATIONS_START_LABEL,
              })}
            </label>
          </span>
          {this.renderBudget()}
          <FloatLabel>
            <InputNumber
              id="hours-input"
              value={numberHours}
              onValueChange={(e) => this.setState({ numberHours: e.value })}
              min={0}
              max={7}
              className={`${validHours === false ? "p-invalid" : ""} flex-1`}
              showButtons buttonLayout="horizontal" step={1}              
            />
            <label htmlFor="hours-input">
              {intl.formatMessage({ id: VACATIONS_AMOUNT_HOURS })}
            </label>
          </FloatLabel>
          <FloatLabel>
            <InputNumber
              id="minutes-input"
              value={numberMinutes}
              onValueChange={(e) => this.setState({ numberMinutes: e.value })}
              min={0}
              max={45}
              className={`${validMinutes === false ? "p-invalid" : ""} flex-1`}
              showButtons buttonLayout="horizontal" step={15}              
            />
            <label htmlFor="minutes-input">
              {intl.formatMessage({ id: VACATIONS_AMOUNT_MINUTES })}
            </label>
          </FloatLabel>
        </div>
      );
    } catch (renderException) {
      logger.warn(renderException);
      return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
    }
  };

  renderAdminComment = () => {
    const { intl } = this.props;
    const { ERROR_RENDER, VACATIONS_COMMENT_ADMIN_LABEL } = MESSAGE_KEYS;
    const { isAdmin, inputCommentAdmin } = this.state;
    try {
      let label = intl.formatMessage({ id: VACATIONS_COMMENT_ADMIN_LABEL });
      if (isAdmin) {
        return (
          <FloatingTextInput
            id="inp_cmt_adm"
            value={inputCommentAdmin}
            onChange={(e) => {
              this.setState({
                inputCommentAdmin: e.target.value,
              });
            }}
            label={label}
          />
        );
      } else {
        return inputCommentAdmin ? (
          <div>
            <strong>{label}</strong>
            <div>{inputCommentAdmin}</div>
          </div>
        ) : (
          <></>
        );
      }
    } catch (renderException) {
      logger.warn(renderException);
      return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
    }
  };

  renderUserComment = () => {
    const { intl, value } = this.props;
    const { ERROR_RENDER, VACATIONS_COMMENT_USER_LABEL } = MESSAGE_KEYS;
    const { inputCommentUser, isCurrentUser, isNew } = this.state;
    try {
      let label = intl.formatMessage({ id: VACATIONS_COMMENT_USER_LABEL });
      if (
        isCurrentUser &&
        (value.state.appointmentStateId === null ||
          value.state.appointmentStateId === 1 ||
          isNew)
      ) {
        return (
          <FloatingTextInput
            id="inp_cmt_usr"
            value={inputCommentUser}
            onChange={(e) => {
              this.setState({
                inputCommentUser: e.target.value,
              });
            }}
            label={label}
          />
        );
      } else {
        return inputCommentUser ? (
          <div>
            <strong>{label}</strong>
            <div>{inputCommentUser}</div>
          </div>
        ) : (
          <></>
        );
      }
    } catch (renderException) {
      logger.warn(renderException);
      return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
    }
  };

  getColor = (value) => {
    if (value === 0) {
      return "text-orange-700";
    } else if (value < 0) {
      return "text-red-700";
    } else {
      return "text-black-700";
    }
  };

  renderBudget = () => {
    const { intl } = this.props;
    const { available, budget } = this.state;
    const { USERS_VACATION_BUDGET_LABEL, VACATIONS_AVAILABLE_LABEL } =
      MESSAGE_KEYS;
    return (
      <div>
        <div
          key="lbl_budget_bugdet"
          className="mr-2 flex justify-content-between"
        >
          {`${intl.formatMessage({
            id: USERS_VACATION_BUDGET_LABEL,
          })}:`}
          <span className={`font-bold ml-1 ${this.getColor(budget)}`}>
            {budget.toFixed(2)}
          </span>
        </div>
        <div
          key="lbl_budget_available"
          className="mr-2 flex justify-content-between"
        >
          {`${intl.formatMessage({
            id: VACATIONS_AVAILABLE_LABEL,
          })}:`}
          <span className={`font-bold ml-1 ${this.getColor(available)}`}>
            {available.toFixed(2)}
          </span>
        </div>
      </div>
    );
  };

  renderHalfDay = () => {
    const { inputIsHalfDay, isAdmin, inputDateRange, inputIsSickDay } =
      this.state;
    const { isNew, intl, value } = this.props;
    const { VACATIONS_HALF_DAY_LABEL, VACATIONS_SICK_DAY_LABEL } = MESSAGE_KEYS;
    if (isNew && isAdmin) {
      return (
        <div>
          <div>
            <Checkbox
              inputId={VACATIONS_HALF_DAY_LABEL}
              checked={inputIsHalfDay}
              onChange={(e) => {
                const startDate = inputDateRange?.[0]
                  ? inputDateRange[0]
                  : value.startDate;
                this.setState({
                  inputIsHalfDay: e.checked,
                  inputDateRange: [startDate, startDate],
                });
              }}
            />
            <label className="ml-2" htmlFor={VACATIONS_HALF_DAY_LABEL}>
              {intl.formatMessage({ id: VACATIONS_HALF_DAY_LABEL })}
            </label>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {inputIsHalfDay && (
            <strong>
              {intl.formatMessage({ id: VACATIONS_HALF_DAY_LABEL })}
            </strong>
          )}
          {inputIsSickDay && (
            <strong>
              {intl.formatMessage({ id: VACATIONS_SICK_DAY_LABEL })}
            </strong>
          )}
        </div>
      );
    }
  };

  renderCalendar = () => {
    const {
      inputDateRange,
      isAdmin,
      isCurrentUser,
      inputIsHalfDay,
      valiDates,
      editable,
    } = this.state;
    const { intl, isNew, value } = this.props;
    const { VACATIONS_START_LABEL, VACATIONS_END_LABEL } = MESSAGE_KEYS;
    if (
      isAdmin ||
      isNew ||
      (isCurrentUser && (value.state === null || value.state === 1))
    ) {
      const selectionMode = inputIsHalfDay ? "single" : "range";
      return (
        <span className="p-float-label">
          <Calendar
            id="vacation_cal_range"
            selectionMode={selectionMode}
            value={inputIsHalfDay ? inputDateRange[0] : inputDateRange}
            onChange={(e) => {
              if (inputIsHalfDay) {
                this.setState({ inputDateRange: [e.value, e.value] });
              } else {
                this.setState({ inputDateRange: e.value });
              }
            }}
            disabled={!editable}
            dateFormat={DATE_FORMAT}
            onHide={() => {
              this.checkForSingle();
            }}
            className={valiDates === false ? "p-invalid" : ""}
            selectOtherMonths={true}
          />
          <label htmlFor="vacation_cal_range">{`${intl.formatMessage({
            id: VACATIONS_START_LABEL,
          })} - ${intl.formatMessage({ id: VACATIONS_END_LABEL })}`}</label>
        </span>
      );
    } else {
      return (
        <div>{`${dateToString(value.startDate.toString())} - ${dateToString(
          value.endDate.toString()
        )}`}</div>
      );
    }
  };

  checkForSingle = () => {
    try {
      const { inputDateRange } = this.state;
      let validRange = [...inputDateRange];
      if (inputDateRange === null) {
        return [];
      } else if (inputDateRange[0] === null && inputDateRange[1] !== null) {
        validRange = [inputDateRange[1], inputDateRange[1]];
      } else if (inputDateRange[1] === null && inputDateRange[0] !== null) {
        validRange = [inputDateRange[0], inputDateRange[0]];
      }
      validRange.forEach((dumDate) => dumDate.setHours(16));
      this.setState({ inputDateRange: validRange });
    } catch (error) {
      logger.error(error);
      return [];
    }
  };

  updateBudget = (userId) => {
    if (userId) {
      let displayedDate = new Date();
      const { inputDateRange } = this.state;
      if (inputDateRange[0]) {
        displayedDate = inputDateRange[0];
      }
      let startDate = new Date(displayedDate.getTime());
      startDate.setMonth(0, 1);
      let endDate = new Date(displayedDate.getTime());
      endDate.setMonth(11, 31);

      sendQuery(
        `${QUERIES.GET_VACATION_REPORT_BY_DATE_AND_USER}${dateToQueryString(
          startDate
        )}&toDate=${dateToQueryString(endDate)}&userId=${userId}`,
        "get"
      )
        .then((response) => {
          this.setState({
            available: response[0]?.available ?? 0,
            budget: response[0]?.budget ?? 0,
          });
        })
        .catch(() => {
          this.setState({
            available: 0,
            budget: 0,
          });
        });
    } else {
      this.setState({
        available: 0,
        budget: 0,
      });
    }
  };

  render = () => {
    const {
      intl,
      currentUser,
      value: { title },
      drivers,
      isNew,
    } = this.props;
    const { ERROR_RENDER, LOGIN_USERNAME_LABEL, VACATIONS_SHOW_HOURS, VACATIONS_SHOW_REGULAR } = MESSAGE_KEYS;
    const { inputUser, isAdmin, validUser, activeUsers, showHourInputs } =
      this.state;
    try {
      return (
        <div className="mt-4">
          <Toast ref={(el) => (this.toast = el)} />
          <div className="flex justify-content-between my-4 gap-2">
            {title && <strong>{title}</strong>}
            {isAdmin && isNew && (
              <Dropdown
                placeholder={intl.formatMessage({
                  id: LOGIN_USERNAME_LABEL,
                })}
                optionLabel="alias"
                value={inputUser}
                options={activeUsers}
                onChange={(e) => {
                  const newId = e.value?.personId ? e.value.personId : null;
                  this.setState(
                    {
                      inputUser: e.value,
                      isCurrentUser: newId === currentUser.personId,
                    },
                    () => this.updateBudget(newId)
                  );
                }}                
                disabled={!drivers || drivers.length < 1}
                appendTo={document.body}
                filter
                showClear
                filterBy="alias"
                className={`${validUser === false ? "p-invalid" : ""} flex-1`}
                filterInputAutoFocus={true}
              />
            )}
            {isNew && <ToggleButton
              onLabel={intl.formatMessage({id: VACATIONS_SHOW_HOURS})}
              offLabel={intl.formatMessage({id: VACATIONS_SHOW_REGULAR})}
              checked={showHourInputs}
              onChange={(e) => this.setState({ showHourInputs: e.value })}
            />}
          </div>
          <div className="mt-4">
            {!showHourInputs && (
              <div className="flex justify-content-between align-items-center">
                {this.renderCalendar()}
                {this.renderBudget()}
                {this.renderHalfDay()}
              </div>
            )}
            {showHourInputs && this.renderHourInputs()}
            <div className="mt-2">{this.renderUserComment()}</div>
            <div className="mt-2">{this.renderAdminComment()}</div>
          </div>
          <div
            className={`flex flex-row justify-content-between mt-3 ${
              !isDesktop && isAdmin ? "flex-wrap" : ""
            }`}
          >
            {this.renderVacationState()}
          </div>
          {this.renderButtonRow()}
        </div>
      );
    } catch (renderException) {
      logger.warn(renderException);
      return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
    }
  };
}

const mapStateToProps = (state) => {
  try {
    const {
      authentication: { currentUser },
    } = state;

    return { currentUser };
  } catch (mapException) {
    return { currentUser: null };
  }
};

export default connect(mapStateToProps, {})(injectIntl(VacationEventPopup));
