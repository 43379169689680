import React, { useState } from "react";
// Styling
import "./Style.scss";
// PrimeReact components
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Divider } from "primereact/divider";
import { ToggleButton } from "primereact/togglebutton";
// Localization
import { useIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";

const AppointmentSearchHeader = ({
  pendingSearch,
  handleFilter,
  handleCancelAppointments,
}) => {
  const [filterSearch, setFilterSearch] = useState("");
  const [checkedHistory, setCheckedHistory] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [unreleasedOnly, setUnreleasedOnly] = useState(false);

  const intl = useIntl();
  const {
    DIALOG_SEARCH_BUTTON_LABEL,
    APPOINTMENT_CANCEL_SELECTED_LABEL,
    APPOINTMENT_UNRELEASED_ONLY,
    APPOINTMENT_ALL_BILLS,
    BILLS_FILTER_TO_DATE,
    BILLS_FILTER_FROM_DATE,
  } = MESSAGE_KEYS;

  return (
    <div className="appointment_search_toolbar">
      <div className="search-container">
        <span className="input-search">
          <form
            onSubmit={(e) => {
              e.preventDefault(); // Prevent the default form submission behavior
              handleFilter(
                filterSearch,
                checkedHistory,
                fromDate,
                toDate,
                unreleasedOnly
              );
            }}
          >
            <InputText
              placeholder={`${intl.formatMessage({
                id: DIALOG_SEARCH_BUTTON_LABEL,
              })}`}
              value={filterSearch}
              onChange={(e) => {
                setFilterSearch(e.target.value);
              }}
              disabled={pendingSearch}
            />
            <Button
              icon="pi pi-search"
              loading={pendingSearch}
              onClick={() => {
                handleFilter(
                  filterSearch,
                  checkedHistory,
                  fromDate,
                  toDate,
                  unreleasedOnly
                );
              }}
              disabled={pendingSearch}
            />
          </form>
        </span>
      </div>
      <div>
        <ToggleButton
          onLabel={intl.formatMessage({ id: APPOINTMENT_UNRELEASED_ONLY })}
          offLabel={intl.formatMessage({ id: APPOINTMENT_ALL_BILLS})}
          checked={unreleasedOnly}
          onChange={(e) => setUnreleasedOnly(e.value)}
        />
      </div>
      <div>
        <label htmlFor="fromDate">
          {intl.formatMessage({ id: BILLS_FILTER_FROM_DATE })}
        </label>
        <Calendar
          value={fromDate}
          dateFormat="dd/mm/yy"
          onChange={(e) => setFromDate(e.value)}
          id="fromDate"
          selectOtherMonths={true}
          disabled={pendingSearch}
        />
      </div>
      <div>
        <label htmlFor="toDate">
          {intl.formatMessage({ id: BILLS_FILTER_TO_DATE })}
        </label>
        <Calendar
          value={toDate}
          dateFormat="dd/mm/yy"
          onChange={(e) => setToDate(e.value)}
          id="toDate"
          selectOtherMonths={true}
          disabled={pendingSearch}
        />
      </div>
      <Divider layout="vertical" />
      <div>
        <label htmlFor="historyCheck">
          <i className="pi pi-history" />
        </label>
        <Checkbox
          inputId="historyCheck"
          name="historyCheck"
          value="historyCheck"
          checked={checkedHistory}
          onChange={(e) => {
            setCheckedHistory(e.checked);
            handleFilter(
              filterSearch,
              e.checked,
              fromDate,
              toDate,
              unreleasedOnly
            );
          }}
          disabled={pendingSearch}
        />
        <Button
          label={`${intl.formatMessage({
            id: APPOINTMENT_CANCEL_SELECTED_LABEL,
          })}`}
          loading={pendingSearch}
          onClick={() => {
            handleCancelAppointments();
          }}
          className="p-button-danger"
        />
      </div>
    </div>
  );
};

export default AppointmentSearchHeader;
