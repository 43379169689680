import React from "react";
// React redux
import { connect } from "react-redux";
import { setBaseValue } from "actions/wizardActions";
// PrimeReact components
import { Checkbox } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";
// Helper functions
import { initLogger } from "common/Helpers";
// Localization
import { injectIntl } from "react-intl";
// Static data
import { LOCALES, MESSAGE_KEYS } from "assets/staticData/enums";
import { FloatingTextInput } from "components/common";
import { SplitDateTimeInput } from "components/common/index";
// Logging
const logger = initLogger("appointment_drive_checklistitems");
const MAX_SQL_INTEGER = 2147483648;
/**
 *
 * @param {Array<String>} groups
 * @param {Number} numberColumns
 */
class ChecklistItems extends React.Component {
  /**
   * Fetches all checklist items corresponding to the supplied groups and orders the result by the checklist item's sorting order parameter.
   *
   * @param {Array<Object>} allItems
   * @param {Array<String>} groups
   */
  filterAndSortChecklist = (allItems, groups) => {
    let result = [];
    result = allItems.filter((entry) => {
      return groups.includes(entry.checklistItem.group.toLowerCase());
    });
    result.sort((a, b) => {
      if (a.checklistItem.sortingOrder > b.checklistItem.sortingOrder) {
        return 1;
      }
      if (a.checklistItem.sortingOrder < b.checklistItem.sortingOrder) {
        return -1;
      }
      return 0;
    });
    return result;
  };

  renderItemColumnInput = (index, filteredList, isGerman = true) => {
    const { disabled } = this.props;
    let entry = filteredList[index];
    try {
      const {
        txt_value,
        nbr_value,
        checklistItem: { nameDe, nameFr },
      } = entry;

      let hasTxt,
        hasNbr,
        hasDate = false;

      hasTxt = entry.checklistItem.hasTxtValue;
      hasNbr = entry.checklistItem.hasNbrValue;

      hasDate = entry.checklistItem.hasDateVal;

      if (hasTxt) {
        if (hasDate) {
          return (
            <SplitDateTimeInput
              value={txt_value}
              onChange={(e) => {
                let newChecklistState = [...filteredList];
                newChecklistState[index].txt_value = e;
                if (e) {
                  newChecklistState[index].itemState = true;
                }
                this.setState({
                  filteredList: [...newChecklistState],
                });
              }}
              showTime
              label={isGerman ? nameDe : nameFr}
              className="in_checklist ml-2"
              disabled={disabled}
            />
          );
        } else {
          return (
            <FloatingTextInput
              id={nameDe}
              value={txt_value}
              onChange={(e) => {
                let newChecklistState = [...filteredList];
                newChecklistState[index].txt_value = e.target.value;
                if (e) {
                  newChecklistState[index].itemState = true;
                }
                this.setState({
                  filteredList: [...newChecklistState],
                });
              }}
              label={isGerman ? nameDe : nameFr}
              className="p-inputtext-sm ml-2 checkbox_input"
              disabled={disabled}
            />
          );
        }
      } else if (hasNbr) {
        return (
          <InputNumber
            value={nbr_value}
            onChange={(e) => {
              let newChecklistState = [...filteredList];
              newChecklistState[index].nbr_value = e.value;
              if (e) {
                newChecklistState[index].itemState = true;
              }
              this.setState({
                filteredList: [...newChecklistState],
              });
            }}
            prefix={`${isGerman ? nameDe : nameFr}: `}
            className="p-inputtext-sm ml-2 checkbox_input"
            onFocus={(e) => {
              setTimeout(() => {
                e.target.select();
              }, 10);
            }}
            disabled={disabled}
            max={MAX_SQL_INTEGER}
          />
        );
      }
    } catch (renderException) {
      logger.warn(renderException, entry);
      return <></>;
    }
  };

  /**
   *
   * @param {Number} start
   * @param {Number} end
   * @param {Array<Object>} filteredList
   */
  renderItemColumn = (start, end, filteredList) => {
    const { currentUser, intl, disabled } = this.props;
    // Check the language of the current user. Defaults to german.
    let isGerman = true;
    if (currentUser && currentUser.languageId) {
      isGerman = currentUser.languageId === LOCALES.GERMAN.languageId;
    }
    let content = [];
    try {
      for (let c = start; c <= end; c++) {
        try {
          const {
            itemState,
            checklistItem: { nameDe, nameFr, checklistItemId },
          } = filteredList[c];
          let hasTxt,
            hasNbr,
            hasDate = false;

          hasTxt = filteredList[c].checklistItem.hasTxtValue;
          hasNbr = filteredList[c].checklistItem.hasNbrValue;
          hasDate = filteredList[c].checklistItem.hasDateVal;
          let row;
          if (!hasTxt && !hasNbr) {
            row = (
              <div
                key={`checklist_cb_${checklistItemId}`}
                className="flex align-items-center"
              >
                <Checkbox
                  inputId={`checklist_cb_${checklistItemId}`}
                  onChange={(e) => {
                    let newChecklistState = [...filteredList];
                    newChecklistState[c].itemState = e.checked;
                    this.setState({ filteredList: [...newChecklistState] });
                  }}
                  checked={itemState}
                  disabled={disabled}
                />

                <label
                  htmlFor={`checklist_cb_${checklistItemId}`}
                  className="p-checkbox-label ml-2"
                >
                  {isGerman ? nameDe : nameFr}
                </label>
              </div>
            );
          } else {
            row = (
              <div key={`checklist_cb_${checklistItemId}`}>
                <div
                  className={`flex align-items-${
                    this.props.end || hasTxt ? "end" : "center"
                  }`}
                >
                  {!hasDate && (
                    <Checkbox
                      inputId={`checklist_cb_${checklistItemId}`}
                      onChange={(e) => {
                        let newChecklistState = [...filteredList];
                        newChecklistState[c].itemState = e.checked;
                        this.setState({
                          filteredList: [...newChecklistState],
                        });
                      }}
                      checked={itemState}
                      disabled={disabled}
                    />
                  )}

                  {this.renderItemColumnInput(c, filteredList, isGerman)}
                </div>
              </div>
            );
          }
          content.push(row);
        } catch (itemException) {
          logger.warn("Could not render item", itemException, filteredList, c);
        }
      }
      return content;
    } catch (renderException) {
      logger.warn(
        "Exception on render checklist column",
        renderException,
        start,
        end
      );
      return <div>{intl.formatMessage({ id: MESSAGE_KEYS.ERROR_RENDER })}</div>;
    }
  };

  render = () => {
    const { intl, groups, numberColumns, allItems } = this.props;
    try {
      if (allItems && allItems.length > 0) {
        let filteredGroups = this.filterAndSortChecklist(allItems, groups);

        let columns = [];
        let maxRows = Math.ceil(filteredGroups.length / numberColumns);
        for (let c = 0; c < filteredGroups.length; c = c + maxRows) {
          let end = c + maxRows - 1;
          columns.push(
            <div key={`item_row_${c}`} className="flex  flex-column">
              {this.renderItemColumn(
                c,
                end <= filteredGroups.length - 1
                  ? end
                  : filteredGroups.length - 1,
                filteredGroups
              )}
            </div>
          );
        }
        return <div className="flex justify-content-between">{columns}</div>;
      } else {
        logger.info("Checklist is empty.");
        return <></>;
      }
    } catch (renderException) {
      logger.warn(
        "Exception on renderChecklistItems",
        renderException,
        allItems
      );
      return <div>{intl.formatMessage({ id: MESSAGE_KEYS.ERROR_RENDER })}</div>;
    }
  };
}

const mapStateToProps = (state) => {
  try {
    const {
      authentication: { currentUser },
      persist: { appointmentTypes },
      application: { backendAvailable },
      session: { appointmentSession },
    } = state;
    return {
      currentUser,
      appointmentSession,
      backendAvailable: backendAvailable,
      appointmentTypes,
    };
  } catch (mapException) {
    return {
      currentUser: null,
      appointmentSession: null,
      backendAvailable: null,
      appointmentTypes: null,
    };
  }
};

ChecklistItems.defaultProps = {
  disabled: false,
};

export default connect(mapStateToProps, {
  setBaseValue,
})(injectIntl(ChecklistItems));
